




















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'CheckList',
  props: {
    list: {
      type: Array,
      required: true
    },
    dense: {
      type: Boolean,
      default: false
    },
    paddingY: {
      type: String,
      default: 'md'
    },
    icon: {
      type: String,
      default: 'mdi-check-bold'
    },
    iconColor: {
      type: String,
      default: 'primary'
    },
    iconSize: {
      type: String,
      default: '12px'
    },
    cssClass: {
      type: String,
      default: 'font-16 text-grey-7 w-400'
    }
  }
});
