import { Dictionary } from './../../../../libs/shared/utils/src/lib/tools/types';
import { boot } from 'quasar/wrappers';

declare module 'vue/types/vue' {
  interface Vue {
    $calct: (key: string) => string | Dictionary;
    $calcte: (key: string) => boolean;
    $prefixt: (key: string) => string | Dictionary;
    $prefixte: (key: string) => boolean;
  }
}

// more info on params: https://quasar.dev/quasar-cli/boot-files
export default boot(({ Vue }) => {
  // Calculator Specific Methods
  Vue.prototype.$calct = Vue['Store'].getters[
    'pageInformation/accessPageSpecific'
  ]('calculator');
  Vue.prototype.$calcte = Vue['Store'].getters[
    'pageInformation/existsPageSpecific'
  ]('calculator');

  // Methods that depend on a localePrefix to be set beforehand
  Vue.prototype.$prefixt =
    Vue['Store'].getters['pageInformation/accessWithPrefix'];
  Vue.prototype.$prefixte =
    Vue['Store'].getters['pageInformation/existsWithPrefix'];
});
