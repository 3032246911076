


























































































































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { socialBtns, socialAuth } from './auth';
import { BasicFieldType } from '@ligo/shared/mvc';
import { UserRegistrarionProps } from 'libs/bv-flow/store/src/lib/models/UserInfo';
import { RESOURCES } from '@ligo/bv-flow/store';
import NextButton from '../../components/base/NextButton.vue';
import BackButton from '../../components/base/BackButton.vue';
import Vue from 'vue';
const TERMS_AND_CONDITION = RESOURCES.TERMS_AND_CONDITION;
const CHECKBOX_SIZE = '45px';

export default defineComponent({
  name: 'RegisterForm',
  components: {
    BackButton,
    NextButton
  },
  props: {
    firstRow: {
      type: (Array as unknown) as PropType<Array<BasicFieldType>>
    },
    lowerFields: {
      type: (Array as unknown) as PropType<Array<BasicFieldType>>
    }
  },
  setup(_, { emit }) {
    const formRef = ref(null as any);
    const checkParams = ref<UserRegistrarionProps>({
      terms_of_service: false,
      keep_me_posted: false
    });

    const showGoBack = () => {
      return Vue['Router'].currentRoute.query['redirect_url'];
    };

    function onRegister() {
      formRef.value.validate().then((ok) => {
        if (ok) {
          emit('perform-register', checkParams);
        }
      });
    }

    return {
      formRef,
      socialBtns,
      socialAuth,
      onRegister,
      showGoBack,
      checkParams,
      TERMS_AND_CONDITION,
      CHECKBOX_SIZE
    };
  }
});
