export default {
  almost_ready: 'Bijna klaar, kies je notaris',
  text1:
    'Verspreid over Nederland zijn onafhankelijke notarissen aangesloten bij Ligo. <br/> Zit er bij jou geen Ligo notaris in de buurt? Geen probleem. Je kunt naar een notaris bij jou in de buurt om je te identificeren en de volmacht te ondertekenen. Deze notaris zal meestal tussen de €25 en €50 rekenen voor deze identificatie. Een Ligo notaris zal de notariële akte daarna passeren. Nadat de betaling is afgerond, zal een Ligo notaris contact met je opnemen om de stappen te doorlopen.',
  text2:
    'Is there no Ligo notary in your area? No problem. You can go to a notary in your area to identify yourself and sign the power of attorney. This notary will usually charge between €25 and €50 for the identification. A Ligo notary will then execute the notarial deed. After the payment is completed, a Ligo notary will contact you to go through the steps.',
  notary_question:
    'Which notary would you like to visit for identification and to sign your power of attorney (legalization)?',
  aclaration:
    'If you visit a Ligo notary, no extra costs are involved. Would you prefer to visit a notary in your area? Keep in mind that it costs between €25 and €50 for the legalization.',
  choose:
    'Choose the Ligo notary where you want the notarial deed to be executed:',
  label1: 'At a Ligo notary',
  label2: 'At another notary in my area',
  label3: 'I already know which notary I want to visit in the area',
  label4: 'I would like help from Ligo in finding a notary in the area',
  tooltip: '100% Online Incorporation',
  disclaimer:
    'Zie hieronder bij welke notaris 100% online oprichting mogelijk is. Je hoeft dan niet bij de notaris langs te gaan. Let op: dit is niet mogelijk voor buitenlandse oprichters. Daarnaast kan de notaris altijd verlangen dat je onder bepaalde omstandigheden alsnog langskomt op kantoor.',
  subtitle:
    'Je kan een notaris kiezen die de dienst 100% online verricht of je kan op locatie bij een notaris terecht.',
  info:
    'Let op: voor 100% online diensten kan een notaris in zeer beperkt aantal gevallen verlangen dat je toch langskomt op kantoor. Heb je te maken met buitenlandse oprichters? In dat geval is het mogelijk dat de notaris aangeeft dat 100% online dienst niet mogelijk is. Neem contact op met ons Support team voor meer informatie.'
};
