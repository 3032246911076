export default {
  labels: {
    name: '(Bedrijfs)naam',
    street: 'Straat',
    house_number: 'Huisnummer',
    zip: 'Postcode',
    city: 'Woonplaats',
    phone_number: 'Telefoonnummer',
    vat_number: 'BTW-nummer',
    extra: 'Aanvullende informatie'
  },
  placeholders: {
    name: 'Naam of Bedrijfsnaam',
    street: 'eg Stadhouderskade',
    house_number: 'e.g. 60',
    zip: 'eg 1072 AC',
    city: 'eg Amsterdam',
    phone_number: 'e.g. 020-3031043',
    vat_number: 'Optioneel',
    extra: 'Optioneel'
  },
  tooltips: {}
};
