












import { defineComponent, toRefs } from '@vue/composition-api';
import { useStoryblok } from './storyblok.hook';

export default defineComponent({
  name: 'RoutedPage',
  props: {
    slug: { type: String, required: true }
  },
  setup(props, { root, emit }) {
    const { slug } = toRefs(props);
    const { story, key } = useStoryblok(slug, root, emit);
    return { story, key };
  }
});
