import { GetterTree } from 'vuex';
import { StateInterface } from '../../index';
import { PropsInterface } from './state';

const getters: GetterTree<PropsInterface, StateInterface> = {
  loggedIn: (state) => {
    return state.accessToken ? true : false;
  },
  authenticationError: (state) => {
    return state.authenticationError;
  },
  authenticating: (state) => {
    return state.authenticating;
  },
  username: (state) => {
    if (state.accessToken) {
      return state.accessToken.firstname + state.accessToken.lastname;
    }
  },
  token_as_query: (state) => {
    const token = state.accessToken;
    return `?access_token=${token.access_token}&expiry=${token.expiry}&client=${token.client}&uid=${token.uid}`;
  }
};

export default getters;
