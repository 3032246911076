import { store } from 'quasar/wrappers';
import Vuex from 'vuex';
import Vue from 'vue';
import { StateInterface, Authentication, RESOURCES } from '@ligo/bv-flow/store';
import { pageModuleFactory } from '@ligo/shared/utils';
import { i18n } from '../boot/i18n';

Vue.use(Vuex);

const pageInformationStore = pageModuleFactory(RESOURCES, i18n);

export const Store = new Vuex.Store<StateInterface>({
  modules: {
    pageInformation: pageInformationStore,
    authentication: Authentication
  },

  // enable strict mode (adds overhead!)
  // for dev mode only
  strict: !!process.env.DEBUGGING
});

Vue['Store'] = Store;

export default store(function ({ Vue }) {
  Vue.use(Vuex);
  return Store;
});
