import { Module } from 'vuex';
import { StateInterface } from '../../index';
import state, { PropsInterface } from './state';
import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const Authentication: Module<PropsInterface, StateInterface> = {
  namespaced: true,
  actions,
  getters,
  mutations,
  state
};

export default Authentication;
