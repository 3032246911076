



















import { defineComponent, ref } from '@vue/composition-api';
import Memberships from '../../base-components/memberships.vue';
import InstructionBanner from '../../base-components/instruction-banner.vue';
import { useDataFromAPI, useData } from './upsell-packages.hooks';
import { ProductForm, NotaryProduct, productLocale } from '@ligo/bv-flow/store';
import { analyticSelectPackage } from '../../../../analytics';

export default defineComponent({
  name: 'UpsellPackages',
  components: { Memberships, InstructionBanner },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    },
    simple: {
      type: Boolean,
      default: false
    },
    mostPopular: {
      type: Number,
      default: 2
    }
  },
  setup(props, { emit, root }) {
    const { membershipData } = useDataFromAPI(props.notary_product.slug);
    const { title, subtitle } = useData(props.notary_product);
    const loading = ref(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    const select = async (id: number) => {
      loading.value = true;
      const form = new ProductForm(
        'main',
        [['upsell_package_type_id']],
        props.notary_product
      );
      form.fields['upsell_package_type_id'].value = id;
      await form.save();
      loading.value = false;

      analyticSelectPackage(
        props.notary_product.locale,
        root.$store.state.authentication.accessToken,
        membershipData.value,
        id
      );

      emit('endedStep');
    };
    return { membershipData, title, subtitle, select, loading, productLocale };
  }
});
