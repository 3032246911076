









import { defineComponent, computed } from '@vue/composition-api';
import { extractInitials } from '@ligo/shared/utils';

export default defineComponent({
  name: 'InitialsAvatar',
  components: {},
  props: {
    username: {
      type: String,
      default: ''
    },
    avatarClass: {
      type: String,
      default: 'bg-gray-300'
    },
    textClass: {
      type: String,
      default: 'text-subdued font-caption'
    }
  },
  setup(props) {
    const initials = computed(() => extractInitials(props.username.split(' ')));
    return { initials };
  }
});
