export default {
  labels: {
    prefix: 'Aanhef',
    firstname: 'Voornamen zoals in paspoort',
    lastname: 'Achternaam',
    birthdate: 'Geboortedatum',
    birthplace: 'Geboorteplaats',
    country_of_birth: 'Geboorteland',
    residence_country: 'Land',
    email: 'E-mailadres',
    address: 'Woonadres',
    company_name: 'Bedrijfsnaam',
    founder_address: 'What is the shareholder’s address?',
    zip_code: 'Postcode',
    location: 'Woonplaats',
    chamber: 'KvK-nummer',
    director_authorized:
      'Is de vertegenwoordigingsbevoegde bestuurder van deze verkoper een onderneming?',
    representation_type:
      'Deze persoon mag de onderneming vertegenwoordigen als:',
    document_type: 'Soort document',
    nationality: 'Nationaliteit',
    document_number: 'Documentnummer',
    issue_date: 'Datum van uitgifte',
    expiration_date: 'Geldig tot',
    place_of_issue: 'Plaats van uitgifte',
    bsn: 'BSN nummer',
    natural_person_lives_in_netherlands: ' Woont de verkoper in Nederland?',
    natural_person_english_proficient:
      'Is de verkoper de Nederlandse taal machtig?',
    lives_in_netherlands: 'Is de verkoper gevestigd in Nederland?',
    english_proficient:
      ' Is de persoon die namens de verkoper tekent de Nederlandse taal machtig?',
    become_director: 'Wordt de holding de bestuurder van de op te richten BV?',
    marital_status: 'Wat is de burgerlijke staat van deze persoon?',
    has_proprietorship:
      'Heeft deze aandeelhouder een actieve eenmanszaak / VOF?',
    kvk_proprietorship:
      'Wat is het KVK-nummer van de eenmanszaak / VOF van deze aandeelhouder?',
    function: 'Wat is de functie van deze persoon?',
    company_location: '',
    company_address: 'Adres',
    company_zipcode: '',
    representative_birthdate: 'Geboortedatum',
    representative_birthplace:
      'Vul de geboorteplaats in van de persoon die namens de onderneming mag optreden',
    natural_person_director:
      'Is de verkoper ook bestuurder van de onderneming?',
    incorporated_in_netherlands: 'Is de verkoper opgericht in Nederland?',
    sole_shareholder:
      'Is de verkoper de enige aandeelhouder van deze onderneming?',
    seller_resign:
      'Neemt de verkoper bij overdracht van de aandelen ontslag als bestuurder?',
    decharge: 'Wil de koper het gevoerde bestuur van de verkoper goedkeuren?',
    internal: 'Is de koper al een aandeelhouder van de onderneming?',
    koper_natural_person_lives_in_netherlands: 'Woont de koper in Nederland?',
    buyer_sole_shareholder:
      'Wordt koper bestuurder van de onderneming en zal koper de enige aandeelhouder van de onderneming worden?',
    koper_natural_person_english_proficient:
      'Is de koper de Nederlandse taal machtig?',
    koper_natural_person_director:
      'Is koper al een bestuurder van de onderneming?',
    koper_live_in_netherlands: 'Is de koper gevestigd in Nederland?',
    koper_english_proficent: 'Is deze koper de Nederlandse taal machtig?',
    company_main_person:
      'Wat is de naam van de persoon die tekent namens de onderneming?'
  },
  checkboxes: {
    bsn_not_present: 'Vink aan als aandeelhouder geen Nederlands BSN heeft',
    address_equals_company_address: 'Gebruik hetzelfde adres als hierboven'
  },
  placeholders: {
    prefix: 'Titel',
    firstname: 'Officiële voornamen',
    lastname: 'Achternaam',
    email: 'Vul het e-mailadres in',
    birthdate: 'YYYY-MM-DD',
    date_of_balance: 'YYYY-MM-DD',
    birthplace: 'Geboorteplaats',
    address: 'Vul straat en huisnummer in',
    marital_status: '',
    has_proprietorship: '',
    kvk_proprietorship: '',
    company_name: 'Vul de naam van de onderneming in',
    zip_code: 'Vul de postcode in',
    location: 'Vul de plaatsnaam in',
    chamber: 'Vul het KvK-nummer in',
    holding_name: 'Naam van de holding',
    nationality: 'Kies uit de lijst',
    document_number: 'Documentnummer',
    place_of_issue: 'Plaats van uitgifte',
    bsn: 'BSN nummer',
    issue_date: 'YYYY-MM-DD',
    expiration_date: 'YYYY-MM-DD',
    shares: 'Vul een aantal in',
    company_location:
      'Vul de plaatsnaam waar het fysieke kantoor is gevestigd in',
    company_address: 'Vul de straatnaam en het huisnummer in',
    company_zipcode: 'Vul de postcode in'
  },
  options: {
    document_type: {
      passport: 'Paspoort',
      driver_licence: 'Rijbewijs',
      identity_card: 'Identiteitskaart'
    },
    marital_status: {
      unmarried: 'Ongehuwd en geen geregistreerd partnerschap',
      registered_partner: 'Geregistreerd partnerschap',
      married: 'Gehuwd'
    },
    representation_type: {
      independently_authorized_director: 'Zelfstandig bevoegd bestuurder',
      jointly_authorized_director: 'Gezamenlijk bevoegd bestuurder'
    },
    nationality: {
      dutch: 'Nederlands',
      belgian: 'Belgisch',
      french: 'Frans',
      english: 'Brits'
    },
    prefix: {
      Mr: 'Dhr',
      Mrs: 'Mevr'
    },
    function: {
      authorized_director: 'Schriftelijk gevolmachtigde',
      authorized_representative: 'Vertegenwoordigingsbevoegde bestuurder'
    }
  },
  label_tooltip: {
    firstname:
      'Namen in akten moeten identiek zijn aan de gegevens op je paspoort of ID kaart. Vul bijvoorbeeld ook je tweede en derde voornaam in, indien van toepassing.',
    birthdate:
      'Ben jij jonger dan 18 jaar en wil jij een aandelenoverdracht doen? Neem dan eerst contact op met Ligo om de aanvullende voorwaarden te bespreken.',
    natural_person_english_proficient:
      'In case the seller cannot speak, read and write in Dutch, the transfer of shares needs to be done in English. In that case, contact Ligo for any additional requirements and costs that may apply. Voor de aandelenoverdracht in het Nederlands moet de verkoper Nederlands kunnen spreken, lezen en verstaan. De notaris zal dit ook controleren.',
    natural_person_lives_in_netherlands:
      'Als je in het buitenland woont, is het mogelijk om je aandelenoverdracht via Ligo te doen. De notaris heeft dan een bankafschrift of energierekening met jouw naam en adres erop nodig. Hiermee kan de notaris jouw adres controleren.',
    koper_natural_person_lives_in_netherlands:
      'Als je in het buitenland woont, is het mogelijk om je aandelenoverdracht via Ligo te doen. De notaris heeft dan een bankafschrift of energierekening met jouw naam en adres erop nodig. Hiermee kan de notaris jouw adres controleren.',
    koper_natural_person_english_proficient:
      'In case the buyer cannot speak, read and write in Dutch, the transfer of shares needs to be done in English. In that case, contact Ligo for any additional requirements and costs that may apply. Voor de aandelenoverdracht in het Nederlands moet de koper Nederlands kunnen spreken, lezen en verstaan. De notaris zal dit ook controleren.',
    decharge:
      "De koper kan ervoor kiezen om het bestuur dat de verkoper heeft gevoerd goed te keuren. Dit wordt 'décharge verlenen' genoemd. Let wel op:  als décharge wordt verleend aan de verkopende bestuurder, kan de onderneming deze bestuurder niet  meer aansprakelijk houden voor zijn bestuur.",
    sole_shareholder:
      'Als de koper nog geen aandeelhouder van het bedrijf is, wordt de overdracht beschouwd als een externe overdracht van aandelen. Let op: een externe overdracht van aandelen brengt extra kosten met zich mee. Dit proces kost €50,- extra bij Ligo. De notaris kan extra kosten in rekening brengen afhankelijk van het aantal en type kopers. Neem gerust contact op met ons team als je een exacte offerte wil hebben via 020 303 1043',
    buyer_sole_shareholder:
      "Kies je 'ja'? Dan geef je aan dat de koper direct na de aandelenoverdracht benoemd zal worden als bestuurder. Zijn er meerdere aandeelhouders of wordt de koper geen bestuurder? Kies hier dan 'nee'. In dat geval kan na de aandelenoverdracht de benoeming van het bestuur worden geregeld.",
    english_proficient:
      'In case the person acting on behalf of the seller cannot speak, read and write in Dutch, the transfer of shares needs to be done in English. In that case, contact Ligo for any additional requirements and costs that may apply. Voor de aandelenoverdracht in het Nederlands moet de persoon die namens de verkoper tekent Nederlands kunnen spreken, lezen en verstaan. De notaris zal dit ook controleren.'
  }
};
