

























































































































import { computed, defineComponent, ref } from '@vue/composition-api';
import CheckList from '../../base-components/check-list.vue';
import { useShareholders } from '../tools';
import { customers } from './custumer_service';
import { analyticSelectDga } from '../../../../analytics';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';

import { getDGASalary } from './dga.hook';

import NewContactCard from '../../../base/NewContactCard.vue';
import BackButton from '../../../base/BackButton.vue';
import NextButton from '../../../base/NextButton.vue';
import BvBadge from '../../../base/BvBadge.vue';
import { DGAPageInformation } from '../../../../hooks/useBvPageInformation.hooks';
import BaseLoading from '../../../base/BaseLoading.vue';
import { analyticGTMSelectDga } from '../../../../hooks/useBvFlowGTM.hooks';
import BvAlertBanner from '../../../base/BvAlertBanner.vue';
import { BvBannerDiscountProps } from '../../../base/bvAlertBanner.hooks';

const DISCOUNT = 0.3;
const DGA_IMG = '/questionnaire/images/dga_logo.svg';
const ICON_START = 'img:/questionnaire/images/bq1.svg';
const ICON_END = 'img:/questionnaire/images/bq2.svg';

const AVATAR_SIZE = '100px';
const ICON_SIZE = '22px';
const BADGE_PLAN =
  'bg-surface-green border-border-green font-12 w-500 text-default ';
const BADGE_NO_PLAN =
  'bg-surface-warning border-orange-1100 font-12 w-500 text-default';

interface DgaPageInfoPrice {
  discount_relative: number;
  discount_fixed: number;
  discount_badge: string;
}

export default defineComponent({
  name: 'Dga',
  components: {
    CheckList,
    NewContactCard,
    BackButton,
    NextButton,
    BvBadge,
    BaseLoading,
    BvAlertBanner
  },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    }
  },
  setup(props, { emit, root }) {
    const { oldMonthlyDGA, monthlyDGA, dgaSalary, oldDGASalary } = getDGASalary(
      props.notary_product,
      DISCOUNT
    );

    const { load, getNested } = DGAPageInformation;

    load().then(() => {
      readyDGAPage.value = true;
    });

    const readyDGAPage = ref<boolean>(false);

    const defaultTInfo = getNested('default');
    const productTInfo = getNested(props.notary_product.slug);

    const t = (key: string) => {
      if (productTInfo.te(key)) return productTInfo.t(key);
      if (defaultTInfo.te(key)) return defaultTInfo.t(key);
      return '';
    };

    const getPriceObj = () => {
      const defaultObject: DgaPageInfoPrice = {
        discount_relative: 0.3,
        discount_fixed: 0,
        discount_badge: '30% off'
      };
      if (productTInfo.te('price'))
        return productTInfo.get('price', defaultObject);
      return defaultTInfo.get('price', defaultObject);
    };

    const loadingSubmit = ref(false);
    const { shareholders, save } = useShareholders(props.notary_product);

    const companyName = props.notary_product.resources.main.values.company_name;

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    const next = async () => {
      loadingSubmit.value = true;
      await save();

      analyticSelectDga(
        props.notary_product.locale,
        root.$store.state.authentication.accessToken,
        shareholders.value,
        dgaSalary.value
      );

      loadingSubmit.value = false;
      emit('endedStep');
    };

    const slide = 'style0';

    const iconsList = computed<string[]>(() => {
      if (productTInfo.te('list')) return productTInfo.get('list', []);
      return defaultTInfo.get('list', []);
    });

    const checkList = computed<string[]>(() => {
      if (productTInfo.te('important'))
        return productTInfo.get('important', []);
      return defaultTInfo.get('important', []);
    });

    const dgaPrices = computed(() => {
      const price = getPriceObj();
      //Calculate the oldPrice using this function: price = oldPrice * (1 - discount_relative) - discount_fixed
      return {
        ...price,
        oldPrice: Math.floor(
          (1 * dgaSalary.value + price.discount_fixed) /
            (1 - price.discount_relative)
        )
      };
    });

    return {
      BvBannerDiscountProps,
      iconsList,
      checkList,
      shareholders,
      slide,
      customers,
      oldDGASalary,
      dgaSalary,
      loadingSubmit,
      companyName,
      oldMonthlyDGA,
      monthlyDGA,
      DISCOUNT,
      DGA_IMG,
      ICON_SIZE,
      ICON_START,
      ICON_END,
      AVATAR_SIZE,
      BADGE_PLAN,
      BADGE_NO_PLAN,
      readyDGAPage,
      dgaPrices,
      t,
      next,
      productLocale,
      onSelectShareHolder(value) {
        if (value) {
          const checkedDgaShareholders = shareholders.value.filter(
            (sh) => sh.form.fields.dga_plan.value
          ).length;

          if (checkedDgaShareholders) {
            analyticGTMSelectDga(checkedDgaShareholders, dgaSalary.value);
          }
        }
      }
    };
  }
});
