

































import {
  CalculatorBasicField,
  CalculatorResource,
  getI18n
} from '@ligo/bv-flow/store';
import { Dictionary } from '@ligo/shared/utils';
import { defineComponent, ref } from '@vue/composition-api';
import { PropType } from 'vue';
import CalculatorQuestionsCard from '../CalculatorQuestionsCard.vue';
import ConversionDialog from './ConversionDialog.vue';

export default defineComponent({
  name: 'QuestionCardForConverting',
  components: { CalculatorQuestionsCard, ConversionDialog },
  props: {
    active: {
      type: Boolean,
      default: true
    },
    fieldKey: String,
    field: {
      type: Object as PropType<CalculatorBasicField<any>>,
      required: true
    },
    calculator: {
      type: Object as PropType<CalculatorResource>,
      required: true
    },
    loading: Boolean
  },
  setup(props, { emit }) {
    const captureArgs = ref<Dictionary>({});
    const captureKey = ref<string>(props.fieldKey);

    const footerNote = getI18n(props.field, 'footer_note');
    const footerLink = getI18n(props.field, 'footer_link');

    const { converting, type_of_conversion } = props.calculator.values;
    captureArgs.value = { converting, type_of_conversion };

    const showFooter = ref(captureArgs.value.converting);

    function onAnswerField(args: Dictionary, key: string) {
      captureArgs.value = args;
      captureKey.value = key;
      if (args.converting) showFooter.value = true;
      else showFooter.value = false;
    }

    function onAnswer(args: Dictionary, key: string) {
      onAnswerField(args, key);
      emit('on-answer', args, key);
    }

    const conversionDialog = ref(false);

    function onChoose(answer: string) {
      emit(
        'on-answer',
        {
          ...captureArgs.value,
          type_of_conversion: answer
        },
        captureKey.value
      );
      conversionDialog.value = false;
    }

    return {
      onAnswer,
      onAnswerField,
      footerLink,
      footerNote,
      showFooter,
      conversionDialog,
      onChoose
    };
  }
});
