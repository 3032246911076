


































import {
  computed,
  defineComponent,
  PropType,
  ref,
  watch
} from '@vue/composition-api';
import { CalculatorTextField } from '@ligo/bv-flow/store';
import { getCountryList } from 'libs/bv-flow/store/src/lib/services/country.service';
import TrieSearch from 'trie-search';
import Vue from 'vue';

const SEPARATOR = '---';

export default defineComponent({
  name: 'CalculatorCountrySelect',
  props: {
    multiple: Boolean,
    field: {
      type: Object as PropType<CalculatorTextField>,
      require: true
    }
  },
  setup(props, { emit }) {
    const options = ref([]);
    const loading = ref(true);

    let optionsTrie;

    const filterTerm = ref('');

    const getFilteredOptions = computed(() => {
      if (filterTerm.value) {
        return optionsTrie.get(filterTerm.value);
      } else {
        return options.value;
      }
    });

    function filterOptions(val, update) {
      update(() => {
        filterTerm.value = val;
      });
      return;
    }

    function emitValue(value) {
      emit('on-answer', value);
    }

    watch(
      () => Vue['i18n'].locale,
      () => {
        getCountryList(true)
          .then((v) => {
            options.value = v;
            optionsTrie = new TrieSearch('label', { min: 1 });
            optionsTrie.addAll(options.value);
          })
          .finally(() => {
            loading.value = false;
          });
      },
      { immediate: true }
    );

    return {
      SEPARATOR,
      loading,
      options,
      getFilteredOptions,
      emitValue,
      filterOptions
    };
  }
});
