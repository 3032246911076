










































import { defineComponent, PropType } from '@vue/composition-api';
import {
  useShareHolders,
  addShareHolder,
  deleteShareHolder,
  selectShareHolder,
  addCheck
} from './shareholder-group.hooks';
export default defineComponent({
  name: 'ShareholderGroup',
  components: {},
  props: {
    active: (Array as unknown) as PropType<Array<number>>,
    singleCardTag: {
      type: Boolean,
      default: true
    },
    cardTagsList: {
      type: (Array as unknown) as PropType<Array<string>>
    },
    cardTag: {
      type: String,
      default: 'shareholder'
    },
    addTag: {
      type: String,
      default: 'add_shareholder'
    },
    enableAdd: {
      type: Boolean,
      default: true
    },
    enableDeletable: {
      type: Boolean,
      default: true
    },
    minimumShareholdersAmount: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const { listReference } = useShareHolders(
      props.active,
      props.minimumShareholdersAmount
    );
    const add = () => {
      addShareHolder(listReference);
      emit(
        'createdShareholder',
        listReference.value[listReference.value.length - 1].id
      );
    };
    const deleteTrigger = (index: number, e: any) => {
      e.stopPropagation();
      const id = listReference.value[index].id;
      deleteShareHolder(listReference, index);
      emit('deletedShareholder', id);
    };
    const select = (index: number) => {
      selectShareHolder(listReference, index);
      emit('selectedShareholder', listReference.value[index].id);
    };
    const externalSelection = (id: number) => {
      const index = listReference.value.indexOf(
        listReference.value.filter((x) => x.id == id)[0]
      );
      select(index);
    };
    const check = () => {
      return addCheck(listReference);
    };
    const getShareHolders = () => {
      return listReference.value;
    };
    return {
      listReference,
      add,
      select,
      externalSelection,
      check,
      deleteTrigger,
      getShareHolders
    };
  }
});
