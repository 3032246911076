import { productLocale, NotaryProduct } from '@ligo/bv-flow/store';
import { buildRule } from '@ligo/shared/mvc';
import { address } from '@ligo/bv-flow/store';

import {
  companyOtherQUestions,
  detailsOfExistingCompany,
  detailsOfNaturalPerson,
  detailsOfNewCompany,
  identification,
  otherQuestions,
  ShareholderForms,
  addressCheckboxConfiguration
} from '@ligo/bv-flow/store';

export const conversion_type = [
  ['converted_from'],
  ['type_of_conversion'],
  ['holding_structure'],
  ['accountant']
];

const company_sections = [address, conversion_type];

const customNewCompanyFields = (notary_product: NotaryProduct) => ({
  company_name: {
    filter: (value: string) => {
      if (value) return value.replace(/(BV|B.V.)$/g, '').trim();
    },
    rules: [
      buildRule('bv_suffix', 'validations.bv_suffix'),
      buildRule('required', 'validations.required_generic')
    ],
    suffix: ' B.V.'
  },
  birthdate: {
    label: productLocale(
      notary_product,
      'shareholder.labels.representative_birthdate'
    )
  },
  birthplace: {
    label: productLocale(
      notary_product,
      'shareholder.labels.representative_birthplace'
    )
  },
  ...addressCheckboxConfiguration
});

const customCompanyFields = (notary_product: NotaryProduct) => ({
  birthdate: {
    label: productLocale(
      notary_product,
      'shareholder.labels.representative_birthdate'
    )
  },
  birthplace: {
    label: productLocale(
      notary_product,
      'shareholder.labels.representative_birthplace'
    )
  },
  ...addressCheckboxConfiguration
});

const customNaturalPersonQuestions = (notary_product: NotaryProduct) => ({
  become_director: {
    label: productLocale(
      notary_product,
      'shareholder.labels.natural_person_director'
    )
  },
  lives_in_netherlands: {
    label: productLocale(
      notary_product,
      'shareholder.labels.natural_person_lives_in_netherlands'
    )
  },
  english_proficient: {
    label: productLocale(
      notary_product,
      'shareholder.labels.natural_person_english_proficient'
    )
  }
});
export const useSteps = (notary_product: NotaryProduct) => {
  const shareholderForms: ShareholderForms = [
    [
      {
        layout: detailsOfNaturalPerson,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      },
      {
        layout: detailsOfExistingCompany,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: {
          7: 'main_person'
        },
        custom_fields: customCompanyFields(notary_product)
      },
      {
        layout: detailsOfNewCompany,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: {
          5: 'new_main_person'
        },
        custom_fields: customNewCompanyFields(notary_product)
      }
    ],
    [
      {
        layout: identification,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      },
      {
        layout: identification,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      },
      {
        layout: identification,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      }
    ],
    [
      {
        layout: otherQuestions,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: customNaturalPersonQuestions(notary_product)
      },
      {
        layout: companyOtherQUestions,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      },
      {
        layout: otherQuestions,
        sendStep: false,
        label: undefined,
        after_submit: undefined,
        sections: undefined,
        custom_fields: undefined
      }
    ]
  ];
  const steps = [
    {
      name: 'generic-sections',
      props: {
        locale: 'company_name',
        sections: company_sections,
        resource: 'main'
      }
    },
    {
      name: 'shareholder-information',
      props: {
        shareholderForms: shareholderForms
      }
    },
    { name: 'holding-upsell' },
    { name: 'pie-chart' },
    { name: 'contract-upsell' },
    { name: 'dga' },
    { name: 'extra-services' },
    { name: 'preview-documents' }
  ];
  return { steps };
};
