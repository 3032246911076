



















import { defineComponent, PropType } from '@vue/composition-api';
import { useOptions, nextOption } from './side-menu-hooks';
import SideMenuDesktop from './side-menu-desktop.vue';
import SideMenuMobile from './side-menu-mobile.vue';

export default defineComponent({
  name: '',
  components: { SideMenuDesktop, SideMenuMobile },
  props: {
    color: {
      type: String,
      default: 'blue-6'
    },
    backgroundColor: {
      type: String,
      default: 'grey-4'
    },
    options: {
      type: (Array as unknown) as PropType<Array<string>>,
      default: () => []
    },
    index: {
      type: Number,
      default: 0
    }
  },
  setup(props, { emit }) {
    const { optionsRef } = useOptions(props.options, props.index);
    const next = () => {
      return nextOption(optionsRef, props.index);
    };

    const changeOption = (i: number) => {
      emit('change-option', i);
    };

    return { props, optionsRef, next, changeOption };
  }
});
