import {
  CalculatorDcl,
  CalculatorField,
  CalculatorFieldClassMap,
  TransitionCondition
} from './CalculatorField';
import { BasicForm, FormConfig, GConstructor } from '@ligo/shared/mvc';
import { accessWithDot, Dictionary, nully } from '@ligo/shared/utils';
import { assert, CalculatorResource } from './CalculatorResource';

export class CalculatorForm extends BasicForm<CalculatorField> {
  perShareholder: boolean;
  shareholderCount: number;
  resource: CalculatorResource;

  constructor(
    layout: Array<Array<string>>,
    resource: CalculatorResource,
    shareholderCount: number,
    config: FormConfig = { sendStep: true }
  ) {
    super('', layout, resource, config);
    this.shareholderCount = shareholderCount;
  }

  getFieldClassMap(): Dictionary<GConstructor<CalculatorField>> {
    return CalculatorFieldClassMap as Dictionary<GConstructor<CalculatorField>>;
  }

  toDict() {
    const resp: { [id: string]: any } = {};
    this.keys().forEach((key) => {
      resp[this.fields[key].key] = this.fields[key].value;
    });
    return resp;
  }

  updateValue(key: string, value: any) {
    this.fields[key].value = value;
  }

  validateRequired() {
    let valid = true;
    Object.values(this.fields).forEach((field) => {
      if (field.required) {
        if (field.perShareholder) {
          if (parseInt(field.key[1]) <= this.shareholderCount) {
            valid = valid && !nully(field.value);
          }
        } else {
          valid = valid && !nully(field.value);
        }
      }
    });
    return valid;
  }

  // If the condition is true for at least one shareholder, it will be considered true
  determineCondition(condition: TransitionCondition): boolean {
    let targets = [this.getSingleField(condition.fieldKey)];
    if (!nully(targets[0]) && targets[0].perShareholder) {
      targets = this.getShareholderFields(condition.fieldKey);
    }
    const values = targets.map((field) => field.value);
    return values.reduce((result, value) => {
      return result || assert(value, condition.transitionType, condition.value);
    }, false);
  }

  getSingleField(fieldKey) {
    return this.fields[fieldKey] || this.getShareholderFields(fieldKey)[0];
  }

  getShareholderFields(fieldKey: string) {
    const pattern = `^p[1,2,3,4].${fieldKey}`;
    const regex = new RegExp(pattern);
    const result = Object.values(this.fields).filter(
      (value: CalculatorField) => {
        if (parseInt(value.key[1]) <= this.shareholderCount)
          return value.key.match(regex);
      }
    );
    return result;
  }

  instanceFields() {
    const fieldDcls = this.resource.getFields(this.id);
    const values = this.resource.getValues();
    this.layout.forEach((row) => {
      row.forEach((field) => {
        const fieldDcl = fieldDcls[field] as CalculatorDcl<any>;
        if (fieldDcl) {
          if (fieldDcl.perShareholder) {
            this.resource.getShareholdersFor(field).forEach((key) => {
              const shareholderKey = `${key}.${field}`;
              const value = accessWithDot(values, shareholderKey);
              this.fields[shareholderKey] = this.instanceField(
                fieldDcl,
                shareholderKey,
                value
              );
            });
          } else {
            const value = values[field];
            this.fields[field] = this.instanceField(fieldDcl, field, value);
          }
        }
      });
    });
  }
}
