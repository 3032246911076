import { Notify } from 'quasar';

export const SendNegativeToaster = (text: string) => {
  Notify.create({
    message: text,
    color: 'surface-critical',
    position: 'top',
    icon: 'mdi-alert',
    classes: 'bv-notify'
  });
};

export const SendPositiveToaster = (text: string) => {
  Notify.create({
    message: text,
    color: 'surface-dark',
    position: 'top',
    icon: 'img:/questionnaire/icons/check-circle.svg',
    textColor: 'white',
    classes: 'bv-notify'
  });
};
