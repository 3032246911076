export default {
  name: 'Shareholders',
  title: 'Who are the shareholders of',
  description:
    'Fill in the details of the shareholders. Check whether you have filled in the details completely and correctly. This way we can serve you as quickly and as well as possible.',
  opt1: 'Basic details',
  opt2: 'Details of the shareholders',
  opt3: 'Identification (ID)',
  opt4: 'Other questions',
  shareholder: 'Shareholder',
  type_title:
    'Will this shareholder establish on behalf of himself (as a natural person), with an existing holding or with a new holding?',
  complete_shareholder:
    'You should complete all shareholders information before continue',
  cant_incorporate:
    'In this case, it is unfortunately not possible as an shareholder to set up a BV with Ligo.',
  other_services: 'Check other services',
  natural_person_tooltip:
    'The shareholder of your BV can be a person, an existing holding company or a holding company that is yet to be established. If it is a person, choose natural person. Are you setting up with an existing holding or are you going to set up a new holding together with your BV? In that case, choose an existing holding company or a holding company that is yet to be established.'
};
