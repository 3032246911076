




















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BvAlertBanner',
  props: {
    text: {
      type: String
    },
    iconSrc: {
      type: String
    },
    iconSize: {
      type: String,
      default: 'xs'
    },
    iconColor: {
      type: String,
      default: 'ligo-orange'
    },
    cardClass: {
      type: String,
      default: 'border-green-1100 bg-surface-green'
    }
  }
});
