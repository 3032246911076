











































import { Dictionary } from '@ligo/shared/utils';
import { defineComponent, PropType, ref, computed } from '@vue/composition-api';
const MAP_IMAGE: Dictionary = {
  en: 'img:/questionnaire/icons/flag_en.svg',
  nl: 'img:/questionnaire/icons/flag.svg',
  check: 'img:/questionnaire/icons/check.svg'
};
export default defineComponent({
  name: 'I18nSwitch',
  components: {},
  props: {
    i18nSwitch: {
      type: Object as PropType<{
        [id: string]: { active: boolean; language: string };
      }>
    },
    color: {
      type: String,
      default: 'grey-14'
    },
    disable: {
      type: Boolean,
      default: false
    },
    label: {
      type: Boolean,
      default: false
    }
  },
  setup(props) {
    const menu = ref(false);
    const activeLangKey = computed(() => {
      return props.i18nSwitch.en.active ? 'en' : 'nl';
    });
    return { menu, activeLangKey, MAP_IMAGE };
  }
});
