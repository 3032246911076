





































import { computed, defineComponent, PropType } from '@vue/composition-api';
import CalculatorInputField from './calculatorfields/CalculatorInputField.vue';
import calculatorOptionField from './calculatorfields/CalculatorOptionField.vue';
import CalculatorSelectField from './calculatorfields/CalculatorSelectField.vue';
import { CalculatorField } from '@ligo/bv-flow/store';
import { getI18n } from '@ligo/bv-flow/store';
import { buildRule, hasBaseRule } from '@ligo/shared/mvc';

export default defineComponent({
  name: 'CalculatorGenericField',
  components: {
    calculatorOptionField,
    CalculatorSelectField,
    CalculatorInputField
  },
  props: {
    field: {
      type: Object as PropType<CalculatorField>,
      required: true
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    disable: Boolean
  },
  setup(props, { emit }) {
    const emitValue = (value) => {
      emit('on-answer', props.field.key, value);
    };

    const fieldLabel = getI18n(props.field, 'label');

    const customRules = computed(() => {
      return props.field.rules
        .filter((rule) => hasBaseRule(rule))
        .map((rule) => buildRule(rule, `validations.${rule}`));
    });

    return {
      emitValue,
      fieldLabel,
      customRules,
      getI18n
    };
  }
});
