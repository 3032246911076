









































































import {
  computed,
  ComputedRef,
  defineComponent,
  PropType,
  reactive,
  ref
} from '@vue/composition-api';
import CalculatorGenericField from './CalculatorGenericField.vue';
import {
  CalculatorForm,
  CalculatorResource,
  CalculatorBasicFieldDcl
} from '@ligo/bv-flow/store';
import CalculatorFieldPerShareholder from './CalculatorFieldPerShareholder.vue';
import { Dictionary, nully } from '@ligo/shared/utils';
import { getI18n } from '@ligo/bv-flow/store';

const TRANSITION_DURATION = 500;

export default defineComponent({
  name: 'CalculatorQuestionsCard',
  components: {
    CalculatorGenericField,
    CalculatorFieldPerShareholder
  },
  props: {
    cardClass: {
      type: String,
      default: 'col-12'
    },
    contentClass: {
      type: String,
      default: 'full-width row q-pa-md'
    },
    flat: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'white'
    },
    active: {
      type: Boolean,
      default: true
    },
    fieldKey: String,
    field: {
      type: Object as PropType<CalculatorBasicFieldDcl<any>>,
      required: true
    },
    calculator: {
      type: Object as PropType<CalculatorResource>
    },
    loading: Boolean
  },

  setup(props, { emit }) {
    const tailKey = props.field.tail?.fieldKey;
    const shareholderCount = ref(props.calculator.getShareholdersCount());

    const cardForm = reactive(
      new CalculatorForm(
        [[props.fieldKey, tailKey]],
        props.calculator,
        shareholderCount.value
      )
    );

    const showTail: ComputedRef<boolean> = computed(() => {
      return (
        props.field.tail &&
        cardForm.determineCondition({
          ...props.field.tail,
          fieldKey: props.fieldKey
        })
      );
    });

    async function onAnswer(key: string, value: any, active = true) {
      cardForm.updateValue(key, value);
      await cardForm.save(false);
      const data = cardForm.toDict();
      let event = 'on-partial-answer';
      if (
        (!showTail.value || !nully(cardForm.fields[tailKey].value)) &&
        cardForm.validateRequired()
      )
        event = 'on-answer';
      emit(event, data, props.fieldKey, active, key);
    }

    const fields: ComputedRef<string[]> = computed(() => {
      const result = [props.fieldKey];
      if (showTail.value) result.push(tailKey);
      return result;
    });

    function isPerShareholder() {
      return props.field.perShareholder && shareholderCount.value > 1;
    }

    const cardText: ComputedRef<Dictionary<string>> = computed(() => {
      const field = cardForm.getSingleField(props.fieldKey);
      const multiple = shareholderCount.value > 1;
      return {
        label:
          (multiple && getI18n(field, 'label_plural').value) ||
          getI18n(field, 'label').value,
        subtitle:
          (multiple && getI18n(field, 'subtitle_plural').value) ||
          getI18n(field, 'subtitle').value,
        tooltip: getI18n(field, 'tooltip').value
      };
    });

    return {
      onAnswer,
      showTail,
      tailKey,
      cardForm,
      fields,
      isPerShareholder,
      shareholderCount,
      cardText,
      getI18n,
      TRANSITION_DURATION
    };
  }
});
