export default {
  name: 'Essentials',
  title:
    'Wij adviseren 90% van onze klanten om naast de BV een persoonlijke holding op te richten',
  description:
    'Vrijwel elke ondernemer heeft er een: een persoonlijke holding om geld te besparen. Je richt de holding en werk-BV meteen bij de start op. Doe je dit later? Dan kunnen de kosten voor de benodigde aandelenoverdracht hoog uitvallen.',
  list_title:
    'Met een persoonlijke holding geniet je van de volgende voordelen:',
  holding_for: 'Holding voor ',
  save: 'BESPAAR',
  checklist1: 'Bespaar geld door vele fiscale voordelen',
  checklist2:
    'Bescherm waardevolle bezittingen tegen aansprakelijkheden of faillissement',
  checklist3: 'Verstrek een hypotheeklening aan jezelf vanuit je holding',
  checklist4:
    'Voorkom hoge kosten door nu, en niet later, je holding op te richten',
  saves_tooltip: 'Start nu en krijg ',
  saves_tooltip_highlighted: '€30 korting op je persoonlijke holdingstructuur!'
};
