

















































import { computed, defineComponent, PropType } from '@vue/composition-api';
import {
  CalculatorBasicFieldDcl,
  CalculatorResource
} from '@ligo/bv-flow/store';
import {
  useGetUpsellPackageList,
  useUpsellPackageData
} from '../../handlers/shared-steps/ContractUpsell/contract_upsell.hooks';
import UpsellPackageCard from '../../handlers/shared-steps/ContractUpsell/UpsellPackageCard.vue';
import BvAlertBanner from '../../base/BvAlertBanner.vue';
import CalculatorQuestionsCard from '../CalculatorQuestionsCard.vue';
import { nully } from '@ligo/shared/utils';

export default defineComponent({
  name: 'QuestionCardForMembership',
  components: { UpsellPackageCard, BvAlertBanner, CalculatorQuestionsCard },
  props: {
    active: {
      type: Boolean,
      default: true
    },
    fieldKey: String,
    field: {
      type: Object as PropType<CalculatorBasicFieldDcl<any>>,
      required: true
    },
    calculator: {
      type: Object as PropType<CalculatorResource>,
      required: true
    },
    loading: Boolean
  },
  setup(props, { emit }) {
    const {
      upsell_package_type_product_ids: initial
    } = props.calculator.values;
    const {
      packagePlans,
      selectedPackagePlan,
      userPackage
    } = useGetUpsellPackageList(
      props.calculator.getNotaryProductTypeSlug(),
      initial || [],
      false,
      false
    );

    if (!nully(initial) && initial?.length == 0) {
      selectedPackagePlan.value = 0;
    }

    const { packageTypesInfo, t } = useUpsellPackageData();

    const getActivePackages = computed(() =>
      packagePlans.value.filter(
        (x) => !!packageTypesInfo.value[x.product_type.slug]
      )
    );

    function onNext() {
      const value = selectedPackagePlan.value
        ? [selectedPackagePlan.value]
        : [];
      emit(
        'on-answer',
        {
          [props.fieldKey]: value
        },
        props.fieldKey
      );
    }

    return {
      packagePlans,
      packageTypesInfo,
      selectedPackagePlan,
      getActivePackages,
      userPackage,
      onNext,
      t
    };
  }
});
