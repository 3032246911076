import { Dictionary } from '../types';
import { defaultSchema } from './json-schema';

export interface Meta {
  title: string;
  description: string;
  keywords?: string;
  url?: string;
  canonical?: string;
  noIndex?: boolean;
}

export const setMeta = (meta: Meta, ldJson = defaultSchema) => {
  const locale = toMeta(meta);
  const script = {
    ldJson
  };
  return { ...locale, script };
};

export const toMeta = (data: Meta) => {
  const metadata: Dictionary = {
    title: data.title,
    meta: {
      description: {
        name: 'description',
        content: data.description
      },
      equiv: {
        'http-equiv': 'Content-Type',
        content: 'text/html; charset=UTF-8'
      }
    },
    link: []
  };
  if (data.keywords)
    metadata.meta['keywords'] = {
      name: 'keywords',
      content: data.keywords
    };
  if (data.url)
    metadata.meta['ogUrl'] = {
      property: 'og:url',
      content: data.url,
      vmid: 'og:url'
    };
  if (data.noIndex)
    metadata.meta['noIndex'] = {
      name: 'robots',
      content: 'noindex'
    };
  if (data.canonical)
    metadata.link.push({ rel: 'canonical', href: data.canonical });
  return metadata;
};
