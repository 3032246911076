


























import { defineComponent, ref } from '@vue/composition-api';
import { postBillingInformation, useData } from './billing-information.hooks';
import { productLocale } from '@ligo/shared/mvc';
import { useNotaryProduct } from '@ligo/bv-flow/store';
import { analyticSelectCheckout } from '@ligo/bv-flow/analytics';

import { InstructionBanner } from '@ligo/bv-flow/components';
import { useLocaleTools } from '../components/layout/locale-hooks';
import NewContactCard from '../components/base/NewContactCard.vue';
import { analyticGTMBilling } from '../hooks/useBvFlowGTM.hooks';

export default defineComponent({
  name: 'BillingInformation',
  components: { InstructionBanner, NewContactCard },
  props: {
    slug: {
      type: String,
      required: true,
      default: 'dutch_bv'
    },
    uid: {
      type: String,
      required: true
    }
  },
  setup(props, { root, emit }) {
    const loading = ref(true);
    emit('clear');
    const notaryProduct = ref(useNotaryProduct(props.slug, props.uid));
    const loggedIn = root.$store.state.authentication.accessToken
      ? true
      : false;
    notaryProduct.value.load(undefined, loggedIn).then(() => {
      loading.value = false;
      void notaryProduct.value.save(true, { sendStep: false });
      analyticGTMBilling(
        notaryProduct.value.locale,
        notaryProduct.value.temporalStep,
        notaryProduct.value.slug
      );
    });
    const { changeLanguage } = useLocaleTools(root);
    changeLanguage(notaryProduct.value.language);
    const {
      formRef,
      title,
      subtitle,
      bankRef,
      updateVals,
      form,
      bankruptcy_opts,
      settled_opts,
      go_back
    } = useData(notaryProduct.value, root, props.uid, props.slug);
    updateVals(root);
    const next = async () => {
      loading.value = true;
      await bankRef.value.save();
      analyticSelectCheckout(
        notaryProduct.value.locale,
        root.$store.state.authentication.accessToken
      );
      const payment = notaryProduct.value.resources['payment'];
      await postBillingInformation(payment);
      const response = await notaryProduct.value.complete();
      notaryProduct.value.updateFromJSON(response.data);
      notaryProduct.value.temporalStep = notaryProduct.value.finalStep + 2;
      notaryProduct.value.step = notaryProduct.value.finalStep + 2;
      notaryProduct.value.saveToStore();
      await notaryProduct.value.save(true, { sendStep: false });
      loading.value = false;
      root.$router.push({
        name: 'Checkout',
        params: {
          slug: notaryProduct.value.path,
          uid: notaryProduct.value.uuid
        }
      });
    };
    const updateBoolValue = (val: any) => {
      val.value = val.value == 'YES';
    };

    const redirectToShareholderStep = () => {
      notaryProduct.value.step = 1;
      notaryProduct.value.temporalStep = 1;
      notaryProduct.value.saveToStore();
      root.$store.commit('stepper/setStep', 1);
      root.$router.push(`/${props.slug}/${notaryProduct.value.uuid}`);
    };
    return {
      formRef,
      next,
      title,
      subtitle,
      bankRef,
      updateBoolValue,
      form,
      bankruptcy_opts,
      settled_opts,
      redirectToShareholderStep,
      loading,
      productLocale,
      notaryProduct,
      go_back
    };
  }
});
