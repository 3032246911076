import {
  defaultDetailsOfNaturalPersonBase,
  identification,
  otherQuestionsStichting,
  ShareholderForms
} from '@ligo/bv-flow/store';

export const address = [
  ['company_name'],
  ['email'],
  ['phone'],
  ['website'],
  ['company_email'],
  ['industry'],
  ['realizing_activities']
];

const management = [
  ['president'],
  ['secretary'],
  ['treasurer'],
  ['extra_shareholders'],
  ['remaining_roles'],
  ['term_managing'],
  ['reappointed']
];

const company_sections = [address, management];

export const useSteps = () => {
  const shareholderForms: ShareholderForms = [
    [
      {
        layout: defaultDetailsOfNaturalPersonBase,
        sendStep: false
      }
    ],
    [
      {
        layout: identification,
        sendStep: false
      }
    ],
    [
      {
        layout: otherQuestionsStichting,
        sendStep: false
      }
    ]
  ];
  const steps = [
    {
      name: 'generic-sections',
      props: {
        locale: 'company_name',
        sections: company_sections,
        resource: 'main'
      }
    },
    {
      name: 'shareholder-information',
      props: {
        shareholderForms: shareholderForms,
        addTag: 'add_founders',
        cardTag: 'founders',
        showTypes: false,
        minimumActives: 1
      }
    },
    { name: 'contract-upsell' },
    {
      name: 'preview-documents',
      props: {
        documentsSubset: [0, 1, 3],
        description: false
      }
    }
  ];
  return { steps };
};
