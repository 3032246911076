import { NotaryProductSlug } from '@ligo/bv-flow/store';
import { Dictionary } from '@ligo/shared/utils';

export enum NotarialGTMEvent {
  // GENERAL
  SELECT_SERVICE_DETAILS_GENERAL = 'company details - general details',
  SELECT_SERVICE_DETAILS_COMPANY = 'company details - company details',
  SELECT_SERVICE_DETAILS_OTHER = 'company details - other questions',
  SELECT_SERVICE_DETAILS_GOAL = 'company details - goal',
  SELECT_SERVICE_DETAILS_GOAL_ANBI = 'company details - goal ANBI',
  SELECT_SERVICE_DETAILS_INFORMATION = 'company details - information transfer',
  SELECT_SERVICE_DETAILS_TRANSFER_SHARE = 'buy and sell - share distribution',

  // SHAREHOLDERS
  SELECT_SHAREHOLDERS = 'shareholders - data shareholders',
  SELECT_SHAREHOLDERS_IDENTIFICATION = 'shareholders - identification',
  SELECT_SHAREHOLDERS_OTHER = 'shareholders - other questions',
  SELECT_SHAREHOLDERS_COMPANY = 'shareholders - company information',
  SELECT_SHAREHOLDERS_SHARE = 'shareholders - share distribution',

  // ECOMMERCE
  SELECT_ECOMMERCE_VIEW_NAME = 'view_promotion',
  SELECT_ECOMMERCE_SELECT_NAME = 'select_promotion',

  // UPSELL
  SELECT_UPSELL_PACKAGE = 'essentials - upsell tax',
  SELECT_UPSELL_ECOMMERCE_NAME = 'upsell tax',

  // PARTNER OFFERS
  SELECT_PARTNER_OFFER = 'essentials - partner offer',
  SELECT_PARTNER_OFFER_ECOMMERCE_NAME = 'partner offer',

  // HOLDING UPSELL
  SELECT_HOLDING_UPSELL = 'essentials - upsell personal holding',
  SELECT_HOLDING_UPSELL_ECOMMERCE = 'upsell personal holding',

  // DGA
  SELECT_DGA = 'essentials - upsell payroll',
  SELECT_DGA_ECOMMERCE = 'upsell payroll',

  // PREVIEW DOC
  SELECT_PREVIEW_DOCUMENT = 'view_document',

  // LOGIN
  SELECT_CREATE_ACCOUNT = 'view document - create account',
  SELECT_CREATE_SIGNUP = 'sign_up',
  SELECT_CREATE_LOGIN = 'login',

  // BILLING
  SELECT_BILLING = 'billing_details',

  // CHECKOUT
  SELECT_CHECKOUT_ECOMMERCE = 'view_cart'
}

export const EVENT_NAME_FOR_GTM_SERVICE: Dictionary<NotarialGTMEvent[]> = {
  [NotaryProductSlug.BV_OPRICHTEN]: [
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GENERAL
  ],
  [NotaryProductSlug.INCORPORATE_DUTCH_BV]: [
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GENERAL
  ],
  [NotaryProductSlug.HOLDING_OPRICHTEN]: [
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GENERAL
  ],
  [NotaryProductSlug.INCORPORATE_HOLDING]: [
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GENERAL
  ],
  [NotaryProductSlug.OMZETTING]: [
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_COMPANY
  ],
  [NotaryProductSlug.STICHTING_OPRICHTEN]: [
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GOAL
  ],
  [NotaryProductSlug.ANBI_STICHTING]: [
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GOAL_ANBI
  ],
  [NotaryProductSlug.TRANSFER_OF_SHARES]: [
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_GENERAL,
    NotarialGTMEvent.SELECT_SERVICE_DETAILS_INFORMATION
  ]
};

export const EVENT_NAME_FOR_GTM_SHAREHOLDERS: Dictionary<NotarialGTMEvent[]> = {
  [NotaryProductSlug.BV_OPRICHTEN]: [
    NotarialGTMEvent.SELECT_SHAREHOLDERS,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_IDENTIFICATION,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_OTHER
  ],
  [NotaryProductSlug.INCORPORATE_DUTCH_BV]: [
    NotarialGTMEvent.SELECT_SHAREHOLDERS,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_IDENTIFICATION,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_OTHER
  ],
  [NotaryProductSlug.HOLDING_OPRICHTEN]: [
    NotarialGTMEvent.SELECT_SHAREHOLDERS,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_IDENTIFICATION,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_OTHER
  ],
  [NotaryProductSlug.INCORPORATE_HOLDING]: [
    NotarialGTMEvent.SELECT_SHAREHOLDERS,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_IDENTIFICATION,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_OTHER
  ],
  [NotaryProductSlug.OMZETTING]: [
    NotarialGTMEvent.SELECT_SHAREHOLDERS,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_IDENTIFICATION,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_OTHER
  ],
  [NotaryProductSlug.STICHTING_OPRICHTEN]: [
    NotarialGTMEvent.SELECT_SHAREHOLDERS,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_IDENTIFICATION,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_OTHER
  ],
  [NotaryProductSlug.ANBI_STICHTING]: [
    NotarialGTMEvent.SELECT_SHAREHOLDERS,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_IDENTIFICATION,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_OTHER
  ],
  [NotaryProductSlug.TRANSFER_OF_SHARES]: [
    NotarialGTMEvent.SELECT_SHAREHOLDERS,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_IDENTIFICATION,
    NotarialGTMEvent.SELECT_SHAREHOLDERS_OTHER
  ]
};
