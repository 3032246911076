/* eslint-disable @nrwl/nx/enforce-module-boundaries */
import { boot } from 'quasar/wrappers';
import { RESOURCES } from '@ligo/bv-flow/store';
import { ApiService } from '@ligo/shared/utils';

// more info on params: https://quasar.dev/quasar-cli/boot-files
export default boot(({ Vue }) => {
  console.log('______ Init API Service ____');
  ApiService.init(RESOURCES.BASE);
  Vue.prototype.$api = ApiService;
});
