export default {
  name: 'Gegevens BV',
  title: 'Vul de algemene gegevens in van',
  highlight: 'jouw BV',
  description:
    'Controleer of je de gegevens compleet en juist hebt ingevuld. Zo kunnen wij jou zo snel en goed mogelijk van dienst zijn.',
  opt1: 'Dienst geselecteerd',
  opt2: 'Algemene gegevens',
  opt3: 'Bedrijfsgegevens',
  opt4: 'Overige vragen'
};
