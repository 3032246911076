import { computed, ref } from '@vue/composition-api';
import { ProductForm, NotaryProduct, productLocale } from '@ligo/bv-flow/store';
import Vue from 'vue';
import { ShareStepPageInformation } from '../../../../hooks/useBvPageInformation.hooks';

const transferOfSharesFields = [
  ['total_shares'],
  ['share_type'],
  ['shares_from', 'transfer_of_shares.from_to', 'shares_to'],
  ['value_of_shares'],
  ['number_of_shares'],
  ['share_price_details'],
  ['accountant']
];

const sections = {
  2: 'transfer_of_shares.main.labels.number_shares'
};

export const useChart = (notary_product: NotaryProduct) => {
  const { isReady, t, te, load } = ShareStepPageInformation;

  load();

  const computedTitle = computed(() => {
    return { dark: t(productLocale(notary_product, 'title')) };
  });

  const transferOfSharesForm = ref(
    new ProductForm('main', transferOfSharesFields, notary_product, {
      sections: sections
    })
  );

  const total_shares = computed(() =>
    Number(transferOfSharesForm.value.fields.total_shares.value)
  );
  const maximumVerify = (v: number) => v > total_shares.value;

  transferOfSharesForm.value.fields.number_of_shares.external_validation = maximumVerify;
  transferOfSharesForm.value.fields.number_of_shares.external_validation_message = Vue[
    'i18n'
  ].t(productLocale(notary_product, 'main.labels.total_shares_validation'));

  return {
    isReady,
    t,
    te,
    computedTitle,
    transferOfSharesForm
  };
};
