





















































































































import { defineComponent, PropType, computed } from '@vue/composition-api';
import { CheckoutLocal, Itemable } from '@ligo/bv-flow/store';
import BillingCard from './billing-card.vue';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';
import { useFreeLineItems, useStatutoryFees } from './Checkout.hooks';
const taxAmount = 21;

export default defineComponent({
  name: 'OrderList',
  components: { BillingCard },
  props: {
    data: {
      type: Object as PropType<CheckoutLocal>,
      required: true
    },
    order: {
      type: Array as PropType<Array<string>>,
      required: true
    },
    notaryProduct: {
      type: Object as PropType<NotaryProduct>,
      required: true
    }
  },
  setup(props, { root }) {
    const checkoutCategories = props.data.line_items;

    const getValidKeys = computed(() => {
      return props.order.filter((x) => x in props.data.line_items);
    });

    const { freeItems, load } = useFreeLineItems(root);
    const { statutoryFeesObj, statutoryFeesTotal } = useStatutoryFees(
      checkoutCategories
    );
    load();

    function sortByFreeItems(line_items: Array<Itemable>) {
      const regularItems = [];
      const leadItems = [];
      line_items.forEach((item) => {
        if (item.itemable_type == 'ExtraService' && item.price == 0) {
          item.free = true;
          leadItems.push(item);
        } else {
          regularItems.push(item);
        }
      });
      return [...regularItems, ...leadItems];
    }

    return {
      productLocale,
      sortByFreeItems,
      taxAmount,
      checkoutCategories,
      getValidKeys,
      freeItems,
      statutoryFeesObj,
      statutoryFeesTotal
    };
  }
});
