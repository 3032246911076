export default {
  labels: {
    firstname: 'First Name',
    lastname: 'Last Name',
    phone: 'Phone Number',
    email: 'Email Address',
    password: 'Password',
    password_confirmation: 'Confirm your password'
  },
  placeholders: {
    firstname: 'First Name',
    lastname: 'Last Name',
    phone: 'Phone Number',
    email: 'Enter the email address',
    password: 'Enter your password',
    password_confirmation: 'Confirm your password'
  },
  tooltips: {
    email:
      'No time to complete the questionnaire? We will send a link to your email address. You can continue at any time via this link.',
    phone:
      'The notary will contact you via this number to discuss the details of your incorporation.'
  }
};
