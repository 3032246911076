export default {
  name: 'BV Details',
  title: 'Fill in the details of',
  highlight: 'your BV',
  description:
    'Check whether you have filled in the details completely and correctly. This way we can serve you as quickly and as well as possible.',
  opt1: 'Service selected',
  opt2: 'General details',
  opt3: 'Company details',
  opt4: 'Other questions'
};
