




























import { defineComponent } from '@vue/composition-api';
import { BaseResetPassword } from '@ligo/shared/auth';
import { RESOURCES } from '@ligo/dashboard/customer/store';
import NewContactCard from '../../components/base/NewContactCard.vue';
import NextButton from '../../components/base/NextButton.vue';

export default defineComponent({
  name: 'ResetPassword',
  components: { BaseResetPassword, NewContactCard, NextButton },
  setup() {
    return {
      base: RESOURCES.AUTH
    };
  }
});
