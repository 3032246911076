export default {
  name: 'Essentials',
  title: 'We raden je aan om ook een holding op te zetten',
  description:
    'Het maakt niet uit of je klein of groot wil beginnen, met een holding structuur geniet je van de volgende voordelen:',
  holding_for: 'Holding voor ',
  save: 'BESPAAR',
  checklist1: 'Aanzienlijke verlaging van belastingdruk',
  checklist2: 'Beperk aansprakelijkheidsrisico’s',
  checklist3:
    'Multifunctioneel, kan worden gebruikt voor al jouw bestaande en nieuwe ondernemingen',
  saves_tooltip: 'Start nu en krijg ',
  saves_tooltip_highlighted: '€30 korting op je persoonlijke holdingstructuur!'
};
