import { getLegalCheckupTypes } from 'libs/bv-flow/store/src/lib/services';
import {
  LegalCheckup,
  LegalCheckupResource
} from 'libs/bv-flow/store/src/lib/models/legal-checkups';
import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/bv-flow/store';

export async function initializeLegalCheckup(slug: string, uuid?: string) {
  let legalCheckup: LegalCheckup;
  const typeResponse = await getLegalCheckupTypes(slug);
  const configuration = typeResponse.data.configuration.fieldDescriptions;
  Object.keys(configuration).forEach((key) => {
    configuration[key]['key'] = configuration[key]['id'];
  });

  const legalCheckupType = new LegalCheckupResource(
    {
      fieldsDescriptions: configuration,
      loadOnCreate: true,
      url: RESOURCES.LEGAL_CHECKUP,
      slug: slug
    },
    uuid
  );
  if (!uuid) legalCheckupType.loadAnswers();
  if (uuid) {
    const response = await ApiService.get<LegalCheckup>(
      `${RESOURCES.LEGAL_CHECKUP}/${uuid}`
    );
    legalCheckup = response.data;
  } else {
    legalCheckup = {
      legal_checkup_type: typeResponse.data,
      form_data: {},
      status: 'open'
    };
  }

  return { legalCheckupType, legalCheckup };
}
