import { ref, Ref } from '@vue/composition-api';
import { FounderType } from '@ligo/bv-flow/store';

const types: Array<FounderType> = [
  {
    id: 1,
    label: 'natural_person',
    selected: true
  },
  {
    id: 2,
    label: 'existing_company',
    selected: false
  },
  {
    id: 3,
    label: 'new_company',
    selected: false
  }
];

export const selectType = (list: Ref<Array<FounderType>>, index: number) => {
  for (let i = 0; i < list.value.length; ++i) {
    list.value[i].selected = i == index;
  }
};

export const useTypes = (
  typesSubset?: Array<number>,
  typesCustomLabels?: Array<string>
) => {
  if (typesCustomLabels)
    typesCustomLabels.forEach((label, i) => {
      types[i].label = label;
    });
  const typesSet = !typesSubset
    ? types
    : types.filter((x) => typesSubset.includes(x.id));
  const typesRef = ref(typesSet);
  return { typesRef };
};
