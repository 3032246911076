import { route } from 'quasar/wrappers';
import VueRouter from 'vue-router';
import { Store } from 'vuex';
import { StateInterface } from '@ligo/bv-flow/store';
import routes from './routes';
import Vue from 'vue';

/*
 * If not building with SSR mode, you can
 * directly export the Router instantiation
 */

Vue.use(VueRouter);

const Router = new VueRouter({
  scrollBehavior: () => ({ x: 0, y: 0 }),
  routes,
  mode: 'history'
  // Leave these as is and change from quasar.conf.js instead!
  // quasar.conf.js -> build -> vueRouterMode
  // quasar.conf.js -> build -> publicPath
});

Vue['Router'] = Router;

export default route<Store<StateInterface>>(function () {
  return Router;
});

export { Router };

Router.beforeEach((to, from, next) => {
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (localStorage.getItem('jwt') == null) {
      let redirect = 'login';
      to.matched.map((record) => {
        if (record.meta.redirect) redirect = record.meta.redirect;
      });
      return next({
        name: redirect,
        query: { redirect_url: to.path }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});
