import { MutationTree } from 'vuex';
import { PropsInterface } from './state';

const mutation: MutationTree<PropsInterface> = {
  authRequest(state) {
    state.authenticating = true;
    state.authenticationError = null;
  },

  authSuccess(state, accessToken) {
    state.accessToken = accessToken;
    state.authenticating = false;
  },

  confirmSuccess(state) {
    state.authenticating = false;
  },

  authError(state, { errorCode, errorMessage, errorData }) {
    state.authenticating = false;
    state.authenticationError = null
    state.authenticationError = {
      code: errorCode,
      message: errorMessage,
      data: errorData
    }
    localStorage.removeItem('user');
  },
  openLogin(state) {
    state.authMethod = false;
    state.openDialog = true;
  },
  openRegister(state) {
    state.authMethod = true;
    state.openDialog = true;
  },
  closeDialog(state) {
    state.openDialog = false;
  },
  logoutSuccess(state) {
    state.accessToken = null;
  },
  cleanErrors(state) {
    (state.authenticating = false),
      (state.authenticationError = null)
  }
};

export default mutation;
