export default {
  criminal_record_label: 'Ik verklaar dat de alle aandeelhouder(s) en bestuurder(s) geen criminele antecedenten hebben; niet in de media in verband worden gebracht met criminele activiteiten; en de afgelopen 4 jaar niet betrokken zijn geweest bij een faillissement, of onder bewind of curatele hebben gestaan.',
  terms_and_conditions_label:
    'Ik heb de algemene voorwaarden gelezen en ga daarmee akkoord',
  have_read: 'Ik accepteer Ligo’s ',
  terms_and_conditions: 'Algemene Voorwaarden',
  criminal_record_alert:
    'U moet de optie Strafregister aanvinken voordat u verder kunt gaan',
  terms_and_conditions_alert:
    'Om verder te gaan, dien je eerst de algemene voorwaarden te accepteren.',
  order_overview: 'Bestellingsoverzicht',
  total: 'Totaal',
  subtotal: 'Subtotaal',
  satisfied: 'TEVREDEN KLANTEN',
  ratings: 'Beoordelingen',
  our_specialists: 'Onze consultants zijn er voor jou en helpen je graag.',
  questions: 'Heb je vragen?',
  call_us: 'Bel ons op',
  chat: 'Chat direct met een',
  support_agent: 'Legal Consultant',
  secure: 'Betalingen zijn veilig en gecodeerd',
  stablishment: 'Oprichting',
  additional: 'Essentiële diensten',
  notaryproduct: 'Notaris Product',
  contract: 'Contracten',
  membership: 'Lidmaatschap',
  notaryproductextra: 'Essentiele diesten',
  discount: 'Korting',
  name: 'Checkout',
  order_sumary: 'Bestellingsoverzicht',
  accept: 'Ja, ik ontvang graag 3 gratis offertes',
  reject: 'Nee, ik ben niet geïnteresseerd'
};
