export default {
  titleA: 'Richt je op de oprichten van ',
  titleC: ' en laat je DGA salarisadministratie aan ons over.',
  more_info: 'More Info',
  aclaration1: 'DGA’s moeten maandelijks hun salaris administreren',
  aclaration2: 'Dit regelen we voor je:',
  dga_for: 'DGA salarisadministratie voor',
  discount: 'korting op jouw DGA salarisadministratie ',
  saving_money: 'besparen tijd en geld',
  our: 'Onze',
  happy: ' tevreden',
  customers: 'klanten',

  list1: 'We helpen je bij het vaststellen van het minimum DGA salaris',
  list2:
    'We zorgen ervoor dat je tijd bespaart door je maandelijkse salarisadministratie én je belastingaangifte te verzorgen',
  list3: 'We sturen je maandelijks een samenvatting en loonstroken',
  saves_tooltip: 'Selecteer nu en krijg ',
  saves_tooltip_highlighted: '€50 korting op je DGA salaris administratie!',
  selection_header:
    'Welke aandeelhouders willen salarisadministratie met korting? Selecteer hier.',
  dga_explanation:
    'Volgens de Nederlandse wet, word je aangewezen als directeur-grootaandeelhouder, zodra je BV of persoonlijke Holding is opgericht. Dit betekent dat je onderworpen bent aan de regels over het DGA salaris. Dat houdt in dat je rekening moet houden met een aantal dingen, zoals je belastingaangifte en je maandelijkse salaris.\
    \n\nGeen zorgen, wij kunnen dit voor je regelen. Voor een vast bedrag, zorgen wij dat alles rondom het DGA salaris voor de komende 12 maanden is geregeld. \
    \n\nWat gaan we deze 12 maanden voor je doen?',
  why_dga: 'Waarom is het DGA-salaris zo belangrijk?',
  important1: 'Voorkom claims van de Belastingdienst',
  important2: 'Houd je belastingdruk zo laag mogelijk',
  important3: 'Regels gelden al in eerste maand na oprichting',
  happy_customer: 'Onze tevreden klanten besparen tijd en geld'
};
