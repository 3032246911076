


























































import { computed, defineComponent } from '@vue/composition-api';
import BvBadge from '../../../base/BvBadge.vue';

const CHECK_ICON = 'img:/questionnaire/icons/radio_check.svg';
const UNCHECK_ICON = 'img:/questionnaire/icons/radio_uncheck.svg';
const IMAGE_WIDTH = 42;
const ICON_WIDTH = 16;
export default defineComponent({
  name: 'ExtraServiceCard',
  components: { BvBadge },
  props: {
    title: String,
    subtitle: String,
    badge: String,
    image: String,
    val: {
      type: Number
    },
    selectedValues: {
      type: Array
    }
  },
  setup(props) {
    const active = computed(() => {
      return props.selectedValues.includes(props.val);
    });
    return {
      CHECK_ICON,
      UNCHECK_ICON,
      IMAGE_WIDTH,
      ICON_WIDTH,
      active
    };
  }
});
