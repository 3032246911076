









































































































import { computed, defineComponent } from '@vue/composition-api';
import InitialsAvatar from '../base/InitialsAvatar.vue';
import { useAuthTools } from './auth-hooks';
const AUTH_ROUTES = ['login', 'register'];
const BUILDING_ICON = 'img:/questionnaire/icons/building.svg';
const ARROW_RIGHT_ICON = 'img:/questionnaire/icons/arrow-right-from-line.svg';

export default defineComponent({
  name: 'AuthButton',
  components: { InitialsAvatar },
  props: {
    screen: {
      type: Number,
      default: 4
    }
  },
  setup(_, { root }) {
    const {
      loggedIn,
      username,
      profileImage,
      logout,
      attach_company_name,
      goToDashboard,
      goTo
    } = useAuthTools(root);
    const authButtonsVisible = computed(() => {
      return AUTH_ROUTES.includes(root.$route.name);
    });
    return {
      loggedIn,
      username,
      profileImage,
      logout,
      attach_company_name,
      goToDashboard,
      goTo,
      authButtonsVisible,
      BUILDING_ICON,
      ARROW_RIGHT_ICON
    };
  }
});
