

































import { defineComponent, PropType } from '@vue/composition-api';
import { SideMenuOption } from '@ligo/bv-flow/store';
import { getClass } from './side-menu-hooks';

export default defineComponent({
  name: '',
  components: {},
  props: {
    optionsRef: {
      type: (Array as unknown) as PropType<Array<SideMenuOption>>,
      required: true
    },
    backgroundColor: {
      type: String
    },
    index: {
      type: Number
    }
  },
  setup(props, { emit }) {
    const changeOption = (i: number) => {
      emit('change-option', i);
    };

    return { props, changeOption, getClass };
  }
});
