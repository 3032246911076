var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('q-card',{staticClass:"q-mt-xs no-border-radius full-width bg-transparent row",attrs:{"flat":"","bordered":_vm.bordered}},[_c('div',{class:{
      'row justify-start items-center ': true,
      'col-12 q-pb-md': !_vm.$q.screen.gt.sm,
      'col q-pr-md': _vm.$q.screen.gt.sm
    }},[_c('rating',{attrs:{"svg":_vm.GOOGLE_IMG,"rating":_vm.googleRating.rating,"displayed":_vm.googleRating.text,"size-img":_vm.SIZE_IMG,"text":_vm.$t('google_reviews')}})],1),(_vm.$q.screen.gt.sm)?_c('q-separator',{attrs:{"vertical":""}}):_vm._e(),_c('div',{class:{
      'row justify-start items-center ': true,
      'col-12 q-pb-md': !_vm.$q.screen.gt.sm,
      'col q-pl-lg': _vm.$q.screen.gt.sm
    }},[_c('rating',{attrs:{"svg":_vm.LIKE_IMG,"rating":_vm.kyiohRating.rating,"displayed":_vm.kyiohRating.text,"size-img":_vm.SIZE_IMG,"text":_vm.$t('kiyoh_reviews')}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }