import { NotaryProduct } from '@ligo/bv-flow/store';
import { RootContext } from '@ligo/shared/utils';
import { Ref } from '@vue/composition-api';

export const paymentRedirect = (
  currentStep: Ref<number>,
  notary_product: NotaryProduct,
  root: RootContext,
  forceRedirection = false
) => {
  if (currentStep.value == notary_product.finalStep + 1 || forceRedirection) {
    root.$router.push(
      {
        name: 'BillingInformation',
        params: {
          slug: notary_product.path,
          uid: notary_product.uuid
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  } else if (currentStep.value >= notary_product.finalStep + 2) {
    root.$router.push(
      {
        name: 'Checkout',
        params: {
          slug: notary_product.path,
          uid: notary_product.uuid
        }
      },
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      () => {}
    );
  }
};
