export default {
  almost_ready: 'Almost ready, choose your notary.',
  text1:
    'Verspreid over Nederland zijn onafhankelijke notarissen aangesloten bij Ligo. <br/> Zit er bij jou geen Ligo notaris in de buurt? Geen probleem. Je kunt naar een notaris bij jou in de buurt om je te identificeren en de volmacht te ondertekenen. Deze notaris zal meestal tussen de €25 en €50 rekenen voor deze identificatie. Een Ligo notaris zal de notariële akte daarna passeren. Nadat de betaling is afgerond, zal een Ligo notaris contact met je opnemen om de stappen te doorlopen.',
  text2:
    'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Dictum lorem sapien, molestie aliquam bibendum amet, lectus blandit. Leo ut sollicitudin augue faucibus adipiscing purus ultrices eget',
  notary_question:
    'Which notary do you want to visit for identification and to sign a power of attorney (legalization)?',
  aclaration:
    'If you visit a Ligo noptary, there are no extra cost involded, Would you rather vivit another notary in your area? Then take into account costs between 25 and 50 for the legalization.',
  choose:
    'Choose the ligo notary with which you want to have the deed executed:',
  label1: 'At a notary of Ligo',
  label2: 'At another notary in the area',
  label3: 'I already know which notary I want to visit in the area',
  label4: 'I would like help from Ligo in finding a notary in the area',
  tooltip: '100% Online Incorporation',
  disclaimer:
    'Find below which notary offers 100% online incorporation. You won’t have to visit the notary in person in that case. Please note: this is not an option for foreign incorporators. In addition, the notary can still ask you to visit the notary’s office in specific circumstances.'
};
