import omzetting_bv from './models/omzetting';
import billling_information from './models/billing_information';
import shareholder from './models/shareholder';
import dga from './steps/dga';
import choose_notary from './steps/choose_notary';
import billing_info from './steps/billing_info';
import preview_order from './steps/preview_order';
import company_name from './steps/company_name';
import shareholder_information from './steps/shareholder_information';
import pie_chart from './steps/pie_chart';
import holding_upsell from './steps/holding_upsell';
import upsell_packages from './steps/upsell_packages';
import preview_documents from './steps/preview_documents';

const omzetting = {
  main: omzetting_bv,
  shareholder: shareholder,
  billing: billling_information,
  enter_name: 'Vul de naam in van je BV',
  company_name: company_name,
  shareholder_information: shareholder_information,
  pie_chart: pie_chart,
  holding_upsell: holding_upsell,
  upsell_packages: upsell_packages,
  preview_documents: preview_documents,
  dga: dga,
  choose_notary: choose_notary,
  billing_info: billing_info,
  preview_order: preview_order,
  company_dialog: 'Je kan niet je BV omzetten bij Ligo zonder accountant.',
  company_dialog_btn: 'Bekijk andere services'
};

export { omzetting };
