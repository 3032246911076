
































import { defineComponent, PropType } from '@vue/composition-api';
import { ServiceCard } from '@ligo/bv-flow/store';
import Service from './ServiceCard.vue';

export default defineComponent({
  name: 'ServiceCardGroup',
  components: { Service },
  props: {
    title: {
      type: String
    },
    services: {
      type: Array as PropType<ServiceCard[]>
    }
  }
});
