





















import {
  CalculatorBasicFieldDcl,
  CalculatorResource
} from '@ligo/bv-flow/store';
import { Dictionary } from '@ligo/shared/utils';
import { defineComponent, ref } from '@vue/composition-api';
import { PropType } from 'vue';
import CalculatorQuestionsCard from '../CalculatorQuestionsCard.vue';
import CalculatorCountrySelect from '../calculatorfields/CalculatorCountrySelect.vue';

export default defineComponent({
  name: 'QuestionCardForCountries',
  components: { CalculatorQuestionsCard, CalculatorCountrySelect },
  props: {
    cardClass: {
      type: String,
      default: 'col-12'
    },
    contentClass: {
      type: String,
      default: 'full-width row q-pa-md'
    },
    flat: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'white'
    },
    active: {
      type: Boolean,
      default: true
    },
    fieldKey: String,
    field: {
      type: Object as PropType<CalculatorBasicFieldDcl<any>>,
      required: true
    },
    calculator: {
      type: Object as PropType<CalculatorResource>
    },
    loading: Boolean
  },
  setup(props, { emit }) {
    const questionsCard = ref(null as any);

    function onAnswer(args: Dictionary, key: string) {
      emit('on-answer', args, key);
    }

    return {
      onAnswer,
      questionsCard
    };
  }
});
