











































import { defineComponent, PropType, ref } from '@vue/composition-api';
import { CalculatorTextField } from '@ligo/bv-flow/store';
import { getI18n } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'CalculatorInputField',
  props: {
    field: {
      type: Object as PropType<CalculatorTextField>,
      require: true
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const localValue = ref(props.field.value || '');

    const fieldRef = ref(null as any);

    const emitValue = (value = '') => {
      emit('on-answer', value);
    };

    const placeholder = getI18n(props.field, 'placeholder');

    return {
      emitValue,
      fieldRef,
      placeholder,
      localValue
    };
  }
});
