export default {
  labels: {
    prefix: 'Full name',
    firstname: 'Firstname',
    lastname: 'Lastname',
    birthdate: 'Date of birth',
    birthplace: 'Birth place',
    email: 'Email address',
    address: 'Physical address',
    company_name: 'What is the shareholder’s company name?',
    founder_address: 'What is the shareholder’s address?',
    zip_code: 'Zip code',
    location: 'Location',
    chamber: 'What is the shareholder’s chamber of commerce number?',
    director_authorized:
      'Is the director authorized to represent this shareholder a company?',
    representation_type:
      'The person who will act on behalf of  the shareholder may represent the company as..',
    document_type: 'Type of document',
    nationality: 'Nationality',
    document_number: 'Document number',
    issue_date: 'Issue date',
    expiration_date: 'Expiration date',
    place_of_issue: 'Place of issue',
    bsn: 'BSN number',
    natural_person_lives_in_netherlands:
      'Does this shareholder live in the Netherlands?',
    natural_person_english_proficient:
      'Is this shareholder proficient in English?',
    lives_in_netherlands:
      'Does the person acting on behalf of the holding live in the Netherlands?',
    english_proficient:
      'Does the person acting on behalf of the holding company speak the Dutch language?',
    become_director:
      'Will the holding company become the director of the BV to be established?',
    marital_status: 'Marital Status',
    has_proprietorship:
      'Do you currently have an active Dutch sole proprietorship company?',
    kvk_proprietorship:
      'What is the Chamber of Commerce number of your eenmanszaak (sole proprietorship)?',
    function: 'What is the function of this person?',
    representative_birthdate:
      'Enter the date of birth of the person who may act on behalf of the holding company',
    representative_birthplace:
      'Enter the place of birth of the person who may act on behalf of the holding company',
    natural_person_director:
      'Will this shareholder become a director of the company to be established?',
    incorporated_in_netherlands:
      'Is the shareholder incorporated in the Netherlands?'
  },
  placeholders: {
    prefix: 'Title',
    firstname: 'First name',
    lastname: 'Last name',
    email: 'Email Address',
    birthdate: 'YYYY-MM-DD',
    birthplace: 'Enter the birth place',
    address: 'Enter the full address',
    marital_status: '',
    has_proprietorship: '',
    kvk_proprietorship: '',
    company_name: 'Enter company name',
    zip_code: 'Enter the zip code',
    location: 'Enter the location',
    chamber: 'Enter the COC number',
    nationality: '',
    document_number: 'Identification Number',
    place_of_issue: 'Enter the place',
    holding_name: 'Enter name of the Holding',
    bsn: 'BSN Number',
    issue_date: 'YYYY-MM-DD',
    expiration_date: 'YYYY-MM-DD',
    shares: 'Enter number',
    company_location: 'Location',
    company_address: 'Address',
    company_zipcode: 'Postcode'
  },
  options: {
    document_type: {
      passport: 'Passport',
      driver_licence: 'Driver Licence',
      identity_card: 'Identity Card'
    },
    marital_status: {
      unmarried: 'Unmarried or not registred partner',
      registered_partner: 'Registered partner',
      married: 'Married'
    },
    representation_type: {
      independently_authorized_director: 'Independently authorized director',
      jointly_authorized_director: 'Jointly authorised director'
    },
    nationality: {
      dutch: 'Dutch',
      belgian: 'Belgian',
      french: 'French',
      english: 'English'
    },
    prefix: {
      Mr: 'Mr',
      Mrs: 'Mrs'
    }
  }
};
