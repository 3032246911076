const date = new Date();
const this_year = '31-12-' + date.getFullYear().toString();
const next_year = '31-12-' + (date.getFullYear() + 1).toString();

export default {
  labels: {
    company_name: 'Company Name',
    email: 'Emailadres contactpersoon',
    headquarters: 'Statutaire zetel',
    office_address: 'Adres',
    place_of_business: '',
    postal_code: '',
    activities: 'Beschrijf de activiteiten van de onderneming',
    sell_products:
      'Verkoopt de onderneming aan consumenten of aan ondernemingen?',
    where_are_sold:
      'Waar worden de producten verkocht? (selecteer the opties die van toepassing zijn)',
    export_products: 'Exporteert de onderneming producten en/of diensten?',
    import_products: 'Importeert de onderneming producten en/of diensten?',
    number_of_shares: 'Totaal aantal aandelen',
    value_of_shares: 'Wat wordt de waarde van een aandeel?',
    financial_year_end:
      'Wanneer eindigt het eerste boekjaar van de op te richten onderneming?',
    full_time_work:
      'Hoeveel mensen werken full-time (15 uur of meer per week) bij de op te richten BV?',
    part_time_work:
      'Hoeveel mensen werken part-time (minder dan 15 uur per week) bij de op te richten BV?',
    employees_loan: 'Is er sprake van het incidenteel uitlenen van werknemers?',
    representation:
      'Op welke manier kan de bestuurder de BV vertegenwoordigen?',
    custom_notary: 'Notaris die de legalisatie zal verzorgen',
    custom_notary_city: 'Jouw gebied',
    lead_approval:
      'Wil je gratis en vrijblijvend 3 offertes te ontvangen van door ons geselecteerde boekhouders uit uw regio?'
  },
  placeholders: {
    email: 'Emailadres contactpersoon',
    headquarters: 'Vul de plaats in waar het hoofdkantoor is gevestigd',
    office_address: 'Vul de straatnaam en het huisnummer in',
    place_of_business:
      'Vul de plaats in waar het fysieke kantoor is gevestigd ',
    company_name: 'Vul de naam van jouw BV in',
    holding_name: 'Type here your holding name',
    postal_code: 'Vul de postcode in',
    activities: 'Beschrijf de activiteiten van de onderneming....',
    sell_products: 'Kies uit de lijst',
    where_are_sold: 'Kies uit de lijst',
    value_of_shares: 'Vul een waarde in',
    enter_number: 'Voer nummer in',
    financial_year_end: '',
    full_time_work: 'Voer hier het aantal in',
    part_time_work: 'Voer hier het aantal in',
    number_of_shares: 'Totaal aantal aandelen',
    shares: 'Voer nummer in',
    custom_notary:
      'Vermeld hier de naam, plaats en telefoonnummer van de notaris die de legalisatie zal uitvoeren:',
    custom_notary_city:
      'Wat is de plaats waar je graag bij een notaris langs wil gaan?'
  },
  options: {
    sell_products: {
      consumers: 'Aan consumenten',
      companies: 'Aan ondernemingen',
      none: 'Niet van toepassing',
      consumers_and_businesses: 'Aan zowel consumenten als ondernemingen'
    },
    where_are_sold: {
      store_or_kiosk: 'In een winkel of kiosk',
      market: 'Op de markt',
      street_trade: 'Via straathandel',
      internet: 'Via internet',
      from_home: 'Vanuit huis',
      postorder: 'Per postorder',
      not_applicable: 'Niet van toepassing'
    },
    representation: {
      one_signature:
        'De bestuurder is zelfstandig (alleen) vertegenwoordigingsbevoegd',
      two_signatures:
        'De bestuurder is samen met een mede-bestuurder vertegenwoordigingsbevoegd'
    },
    financial_year_end: {
      this_year: this_year,
      next_year: next_year
    }
  }
};
