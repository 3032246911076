export const defaultSchema = {
  type: 'application/ld+json',
  innerHTML: `{"@context":"https://schema.org/",
  "@type":"Organization",
  "address":{
     "@type":"PostalAddress",
     "addressCountry":"Nederland",
     "postalCode":"1072 AC",
     "streetAddress":"Stadhouderskade 60",
     "email":"support@ligo.nl",
     "telephone":"020 303 1043",
     "alternateName":"Ligo",
     "mainEntityOfPage":"https://www.ligo.nl",
     "name":"Ligo"
  },
  "aggregateRating":{
     "@type":"AggregateRating",
     "reviewCount":"113",
     "bestRating":"10",
     "ratingValue":"8.8",
     "worstRating":"1",
     "mainEntityOfPage":"https://www.kiyoh.com/reviews/1042858/ligo_bv",
     "name":"Ligo"
  },
  "name":"Ligo",
  "description":"Meer dan 35.000 BV’s opgericht. 7 dagen per week juridische hulp van onze juristen. 113 klanten waarderen Ligo met een gemiddelde van 8.8",
  "email":"support@ligo.nl",
  "legalName":"Ligo BV",
  "logo":{
     "@type":"ImageObject",
     "caption":"Ligo",
     "contentUrl":"https://www.ligo.nl/assets/logo-b7c186a1419adea7879ee46a98e201783207bf5014d90c35c46b050b70a8a006.png",
     "url":"https://www.ligo.nl/assets/logo-b7c186a1419adea7879ee46a98e201783207bf5014d90c35c46b050b70a8a006.png",
     "name":"Ligo"
  },
  "telephone":"020 303 1043",
  "url":"https://www.ligo.nl"}`
};
