












































import { defineComponent, PropType } from '@vue/composition-api';
import { Benefit } from '@ligo/bv-flow/store';

const IMAGE_WIDTH = 34;
const ICON_WIDTH = 16;
const ICON_STYLE = `margin-top: -${ICON_WIDTH / 3}px; margin-right: -${
  ICON_WIDTH / 3
}px`;

export default defineComponent({
  name: 'BenefitCard',
  props: {
    benefitData: {
      type: Object as PropType<Benefit>
    },
    image: {
      type: String
    },
    icon: {
      type: String
    }
  },
  setup() {
    return {
      IMAGE_WIDTH,
      ICON_WIDTH,
      ICON_STYLE
    };
  }
});
