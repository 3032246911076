export default {
  labels: {
    name: '(Bedrijfs)naam',
    street: 'Street',
    house_number: 'House Number',
    zip: 'Zip Code',
    city: 'City',
    phone_number: 'Phone Number',
    vat_number: 'VAT-number',
    extra: 'Extra Information'
  },
  placeholders: {
    name: '',
    street: '',
    house_number: '',
    zip: '',
    city: '',
    phone_number: '',
    vat_number: 'Optioneel',
    extra: 'Optioneel'
  },
  tooltips: {}
};
