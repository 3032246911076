





























































import { scrollToHtmlElement, Dictionary } from '@ligo/shared/utils';
import { LegalCheckup } from 'libs/bv-flow/store/src/lib/models/legal-checkups';
import {
  defineComponent,
  reactive,
  Ref,
  ref,
  computed,
  ComputedRef
} from '@vue/composition-api';
import { initializeLegalCheckup } from './legal-checkup.hooks';
import NewContactCard from '../../components/base/NewContactCard.vue';
import CalculatorQuestionsCard from '../../components/calculator/CalculatorQuestionsCard.vue';
import { LegalCheckupResource } from 'libs/bv-flow/store/src/lib/models/legal-checkups';
import QuestionCardForConverting from '../../components/calculator/calculatorQuestions/QuestionCardForConverting.vue';
import QuestionCardForParticipate from '../../components/calculator/calculatorQuestions/QuestionCardForRepresentation.vue';
import { RESOURCES } from '@ligo/bv-flow/store';

const SPINNER_SIZE = '200px';
const FOCUS_SCROLL_DURATION = 800;

export default defineComponent({
  name: 'LegalCheckup',
  components: {
    NewContactCard,
    CalculatorQuestionsCard,
    QuestionCardForParticipate,
    QuestionCardForConverting
  },
  props: {
    uuid: {
      type: String,
      required: false
    },
    slug: {
      type: String,
      required: true
    }
  },
  setup(props, { root }) {
    const loading = ref(true);
    const legalCheckupType: Ref<LegalCheckupResource> = ref(null);
    const legalCheckup: Ref<LegalCheckup> = ref(null);

    initializeLegalCheckup(props.slug, props.uuid).then((response) => {
      legalCheckupType.value = reactive(response.legalCheckupType);
      legalCheckup.value = reactive(response.legalCheckup);
      loading.value = false;
    });

    const questions: ComputedRef<string[]> = computed(() => {
      return legalCheckupType.value.answered.concat(
        legalCheckupType.value.currentQuestions?.[0] ?? []
      );
    });

    const currentKey = computed(
      () => legalCheckupType.value.currentQuestions?.[0]
    );

    function onScroll() {
      scrollToHtmlElement(
        `#question-card-${currentKey.value}`,
        FOCUS_SCROLL_DURATION
      );
    }

    function onAnswer(value: Dictionary, key: string) {
      loading.value = true;
      legalCheckupType.value
        .onAnswer(key, value)
        .then(() => {
          if (legalCheckupType.value.uuid != props.uuid) {
            root.$router.push({
              name: 'LegalCheckup',
              params: { slug: props.slug, uuid: legalCheckupType.value.uuid }
            });
          }
          loading.value = false;
          onScroll();
        })
        .catch(() => {
          loading.value = false;
        });
    }

    function getCustomCard(key) {
      return legalCheckupType.value.fields[key]?.customQuestionComponent;
    }

    function getTitle() {
      return legalCheckup.value?.legal_checkup_type?.name;
    }

    function goToDashboard() {
      window.open(
        `${RESOURCES.CUSTOMER_DASHBOARD}login?legal_checkup_uuid=${legalCheckupType.value.uuid}&redirect_url=legal-scan`,
        '_blank'
      );
    }

    return {
      SPINNER_SIZE,
      loading,
      getCustomCard,
      getTitle,
      legalCheckup,
      legalCheckupType,
      questions,
      goToDashboard,
      onAnswer,
      currentKey
    };
  }
});
