import {
  detailsOfNaturalPersonHolding,
  identification
} from '@ligo/bv-flow/store';

const address = [['company_name'], ['email']];

export const otherQuestions = [
  ['lives_in_netherlands'],
  ['english_proficient']
];

const company_sections = [address];
const shareholder_sections = [
  detailsOfNaturalPersonHolding,
  identification,
  otherQuestions
];

export const useSteps = () => {
  const steps = [
    {
      name: 'generic-sections',
      props: {
        locale: 'company_name',
        sections: company_sections,
        resource: 'main'
      }
    },
    {
      name: 'generic-sections',
      props: {
        locale: 'shareholder_information',
        sections: shareholder_sections,
        resource: 'p1'
      }
    },
    { name: 'pie-chart' },
    { name: 'contract-upsell' },
    { name: 'dga' },
    { name: 'extra-services' },
    { name: 'preview-documents' }
  ];
  return { steps };
};
