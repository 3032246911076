

















import {
  defineComponent,
  ComputedRef,
  computed,
  ref
} from '@vue/composition-api';

import {
  Dga,
  PieChart,
  PreviewDocuments,
  UpsellPackages,
  ShareholderInformation,
  GenericSections,
  HoldingUpsell,
  ContractUpsell,
  ExtraServices
} from '../shared-steps';
import { useSteps, getEnglishProduct } from './handler.hooks';
import { NotaryProduct } from '@ligo/bv-flow/store';
import { Intercom } from '@ligo/shared/utils';
import { paymentRedirect } from '../tools/payment_redirection';
import { reloadPage, endStep } from '../tools/utils';
import Vue from 'vue';
export default defineComponent({
  name: 'DutchBVHandler',
  components: {
    GenericSections,
    ShareholderInformation,
    PieChart,
    HoldingUpsell,
    UpsellPackages,
    ContractUpsell,
    Dga,
    PreviewDocuments,
    ExtraServices
  },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    }
  },
  setup(props, { root }) {
    const router = Vue['Router'];
    const loading = ref(false);
    const currentStep: ComputedRef<number> = computed(() => {
      return props.notary_product.temporalStep;
    });
    props.notary_product.setStepBounds();

    const { steps } = useSteps(props.notary_product);
    const stepToShow: ComputedRef = computed(() => {
      paymentRedirect(currentStep, props.notary_product, root);
      return steps[currentStep.value];
    });

    const endedStep = () => {
      endStep(currentStep, props.notary_product, root);
      reload();
    };

    const reload = () => {
      Intercom.update();
      reloadPage(loading, root, props.notary_product);
    };

    const transferToEnglish = async () => {
      loading.value = true;
      const response = await getEnglishProduct(props.notary_product);
      const route = root.$router.resolve({
        name: 'EnglishBV',
        params: { uid: response.data.uuid }
      });
      window.location.replace(route.route.fullPath);
    };

    const transferToOmzetting = () => {
      router
        .push({
          name: 'Omzetting'
        })
        .then(() => {
          window.location.reload();
        });
    };

    return {
      endedStep,
      stepToShow,
      loading,
      reload,
      transferToEnglish,
      transferToOmzetting
    };
  }
});
