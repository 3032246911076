import { ref } from '@vue/composition-api';

export function responsiveCheck() {
  const screen = ref(0);
  const onResize = () => {
    if (window.innerWidth >= 2000) screen.value = 5;
    else if (window.innerWidth >= 1400) screen.value = 4;
    else if (window.innerWidth >= 1024) screen.value = 3;
    else if (window.innerWidth > 760 && window.innerWidth < 1024)
      screen.value = 2;
    else screen.value = 1;
  };
  onResize();
  window.addEventListener('resize', onResize, { passive: true });
  return { screen };
}

export function getWidth() {
  const width = ref(0);
  const onResize = () => {
    width.value = window.innerWidth;
  };
  onResize();
  window.addEventListener('resize', onResize, { passive: true });
  return { width };
}
