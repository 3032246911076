























import {
  CalculatorBasicFieldDcl,
  CalculatorResource
} from '@ligo/bv-flow/store';
import { Dictionary } from '@ligo/shared/utils';
import { defineComponent, ref } from '@vue/composition-api';
import { PropType } from 'vue';
import CalculatorQuestionsCard from '../CalculatorQuestionsCard.vue';
import ProsConsDialog from '../ProsConsDialog.vue';

export default defineComponent({
  name: 'QuestionCardForParticipate',
  components: { CalculatorQuestionsCard, ProsConsDialog },
  props: {
    active: {
      type: Boolean,
      default: true
    },
    fieldKey: String,
    field: {
      type: Object as PropType<CalculatorBasicFieldDcl<any>>,
      required: true
    },
    calculator: {
      type: Object as PropType<CalculatorResource>
    },
    loading: Boolean
  },
  setup(props, { emit }) {
    const showDialog = ref(false);
    const captureArgs = ref<Dictionary>({});
    const captureKey = ref<string>('');
    const capturePKey = ref<string>('');
    const send = ref<boolean>(false);

    const calculatorQuestion = ref(null as any);
    function onAnswer(
      args: Dictionary,
      fkey: string,
      _active: boolean,
      key: string
    ) {
      send.value = true;
      if (!!args[key] && args[key] == 'natural_person') {
        captureArgs.value = args;
        captureKey.value = fkey;
        capturePKey.value = key;
        showDialog.value = true;
      } else emit('on-answer', args, fkey);
    }

    function onPartialAnswer(
      args: Dictionary,
      fkey: string,
      _active: boolean,
      key: string
    ) {
      if (!!args[key] && args[key] == 'natural_person') {
        captureArgs.value = args;
        captureKey.value = fkey;
        capturePKey.value = key;
        showDialog.value = true;
      }
      send.value = false;
    }

    function onClickWorst() {
      if (send.value) emit('on-answer', captureArgs.value, captureKey.value);
      showDialog.value = false;
    }

    async function onClickBest() {
      const args = { ...captureArgs.value };

      args[capturePKey.value] = 'new_company';
      if (!send.value) {
        calculatorQuestion.value.cardForm.updateValue(
          capturePKey.value,
          'new_company'
        );
        await calculatorQuestion.value.cardForm.save(false);
      } else emit('on-answer', args, captureKey.value);
      showDialog.value = false;
    }

    return {
      onAnswer,
      onPartialAnswer,
      showDialog,
      calculatorQuestion,
      onClickWorst,
      onClickBest
    };
  }
});
