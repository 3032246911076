import { ref, Ref } from '@vue/composition-api';
import { NotaryProduct, Step } from '@ligo/bv-flow/store';

let steps: Array<Step> = [];

export const next = (
  stepsRef: Ref<any>,
  substep: Ref<number>,
  index: Ref<number>
) => {
  substep.value = 0;
  if (stepsRef.value[index.value].parts) {
    stepsRef.value[index.value].currentPart++;
    if (
      stepsRef.value[index.value].currentPart ==
      stepsRef.value[index.value].parts
    ) {
      stepsRef.value[index.value].completed = true;
      index.value++;
    } else {
      cooldown(substep);
    }
  } else {
    stepsRef.value[index.value].completed = true;
    index.value++;
  }
};

const cooldown = (substep: Ref<number>) => {
  substep.value = 0;
  // this time is to be able to see the animation
  setTimeout(() => {
    substep.value = 1;
  }, 200);
};

export const calcSize = (pos: number, total: number, part: number) => {
  const size: number = Math.floor((part / total) * 12) - 1;
  if (pos) return ' col-' + (10 - size).toString();
  return ' col-' + size.toString();
};

export const getGlobalStep = (
  stepRef: Ref<any>,
  substep: number,
  index: number
) => {
  let count = 0;
  for (let i = 0; i <= index; ++i) {
    if (i != index && stepRef.value[i].completed) {
      if (stepRef.value[i].parts) {
        count += stepRef.value[i].parts;
      } else {
        count++;
      }
    } else {
      count += substep;
    }
  }
  return count;
};

const cleanSteps = () => {
  for (let i = 0; i < steps.length; ++i) {
    steps[i].completed = false;
    if (steps[i].parts != undefined) {
      steps[i].currentPart = 0;
    }
  }
};

export const useStepper = (notary_product: NotaryProduct) => {
  steps = [];
  notary_product.stepperDefinition.forEach((x) => {
    steps.push(x);
  });
  const stepsRef = ref(steps);
  return { stepsRef };
};

export const initStepper = (
  stepsRef: Ref<any>,
  substep: Ref<number>,
  index: Ref<number>,
  StoreStep: number
) => {
  cleanSteps();
  index.value = 0;
  substep.value = 0;
  for (let i = 0; i < StoreStep; ++i) {
    next(stepsRef, substep, index);
  }
};
