export default {
  title: 'Je conceptakte wacht op je in jouw dashboard!',
  note: 'Let op :',
  note_text: ' Dit is slechts een voorbeeld van de akte',
  list: 'Lijst van documenten',
  d1: 'Concept akte van oprichting',
  d2: 'Toelichting op de akte van oprichting',
  d3: 'Aandeelhoudersregister',
  d4: 'Persoonlijke Datacard voor de Kamer van Koophandel',
  btn_download: 'Download',
  btn_subtitle: 'Je kunt de documenten downloaden na betaling',
  next_step_title: '',
  next_step_description: '',
  previous: 'Vorige',
  next: 'Volgende',
  name: 'Bekijk je documenten'
};
