
















import {
  defineComponent,
  ComputedRef,
  computed,
  ref
} from '@vue/composition-api';

import {
  Dga,
  PieChart,
  PreviewDocuments,
  ContractUpsell,
  ShareholderInformation,
  GenericSections
} from '../shared-steps';
import { useSteps } from './handler.hooks';
import { NotaryProduct } from '@ligo/bv-flow/store';
import { Intercom } from '@ligo/shared/utils';
import { paymentRedirect } from '../tools/payment_redirection';
import { reloadPage, endStep } from '../tools/utils';

export default defineComponent({
  name: 'AnbiStichtingHandler',
  components: {
    GenericSections,
    ShareholderInformation,
    PieChart,
    ContractUpsell,
    Dga,
    PreviewDocuments
  },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    }
  },
  setup(props, { root }) {
    const loading = ref(false);
    const currentStep: ComputedRef<number> = computed(() => {
      return props.notary_product.temporalStep;
    });
    props.notary_product.setStepBounds();
    const { steps } = useSteps();
    const stepToShow: ComputedRef = computed(() => {
      paymentRedirect(currentStep, props.notary_product, root);
      return steps[currentStep.value];
    });

    const endedStep = () => {
      endStep(currentStep, props.notary_product, root);
      reload();
    };

    const reload = () => {
      Intercom.update();
      reloadPage(loading, root, props.notary_product);
    };

    return { endedStep, stepToShow, loading, reload };
  }
});
