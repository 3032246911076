




















































import { defineComponent, ref } from '@vue/composition-api';
import { useConfirmationFields } from './user_info';
import { sendConfirmationEmail } from './auth';
import {
  SendPositiveToaster,
  SendNegativeToaster
} from '../../hooks/useBvNotification.hooks';
import NextButton from '../../components/base/NextButton.vue';
import BackButton from '../../components/base/BackButton.vue';
import NewContactCard from '../../components/base/NewContactCard.vue';
import Vue from 'vue';

export default defineComponent({
  name: 'EmailConfirmation',
  components: { NextButton, BackButton, NewContactCard },
  props: {
    redirectUrl: {
      type: String
    },
    productType: {
      type: String
    }
  },
  setup(props, { root }) {
    const form = ref(null);
    const goBack = () => {
      void root.$router.push({
        name: 'login',
        query: { redirect_url: props.redirectUrl }
      });
    };

    const { confirmationField } = useConfirmationFields();

    return {
      confirmationField,
      form,
      goBack,
      sendConfirmationEmail,
      onNext() {
        form.value.validate().then((valid) => {
          if (valid) {
            sendConfirmationEmail(confirmationField.value.value)
              .then(() => {
                SendPositiveToaster(
                  Vue['i18n'].t('auth.confirmation_header.message_success')
                );
              })
              .catch(() => {
                SendNegativeToaster(
                  Vue['i18n'].t('auth.confirmation_header.message_error')
                );
              });
          }
        });
      }
    };
  }
});
