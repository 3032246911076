export default {
  labels: {
    prefix: 'Prefix',
    firstname: 'First and middle name as in passport',
    lastname: 'Last Name',
    birthdate: 'Date of birth',
    birthplace: 'Place of birth',
    country_of_birth: 'Country of birth',
    residence_country: 'Country',
    email: 'E-mail address',
    phone_number: 'Phone Number',
    address: 'Address',
    company_name: 'Company name',
    company_location: '',
    company_address: 'Address',
    company_country: 'Country',
    founder_address: 'What is the shareholder’s address?',
    zip_code: '',
    location: '',
    chamber: 'What is the shareholder’s chamber of commerce number?',
    director_authorized:
      'Is the director authorized to represent this shareholder a company?',
    representation_type: 'This person may act on behalf of the new holding as:',
    document_type: 'Type of document',
    nationality: 'Nationality',
    document_number: 'Document number',
    issue_date: 'Date of issuance',
    expiration_date: 'Valid until',
    place_of_issue: 'Place of issuance',
    bsn: 'BSN Number',
    natural_person_lives_in_netherlands:
      'Does this shareholder officially live in the Netherlands? ',
    natural_person_english_proficient:
      'Is this shareholder able to understand, read and speak the English language?',
    lives_in_netherlands:
      'Does the person acting on behalf of the holding live in the Netherlands?',
    english_proficient: 'Does this shareholder speak fluent English?',
    become_director: 'Will the holding become the director of the new BV?',
    marital_status: 'Marital status',
    has_proprietorship:
      'Does this shareholder have an active sole proprietorship (eenmanszaak) or VOF?',
    kvk_proprietorship:
      'What is the KVK-number of the sole proprietorship or VOF?',
    function: 'What is the function of this person?',
    representative_birthdate: 'Date of birth',
    representative_birthplace: 'Place of birth',
    natural_person_director:
      'Will this shareholder become a director of the company?',
    incorporated_in_netherlands:
      'Is this shareholder located in the Netherlands?',
    new_company_director:
      'Will the shareholder become the director of the new BV?',
    new_company_lives_in_netherlands:
      'Will this shareholder be located in The Netherlands?',
    new_company_english_proficient:
      'Is the person who is allowed to represent the shareholder able to understand, speak and read the English language?',
    ubos: 'How many UBO’s does this company have?',
    ubo_kvk_registered:
      'Have you already submitted the UBO registration with the KVK?',
    proprietorship_action:
      'What does the shareholder want to do with this sole-proprietorship or VOF?'
  },
  checkboxes: {
    bsn_not_present:
      'Check the box if this shareholder does not have a Dutch BSN',
    address_equals_company_address: 'Use same address as above',
    ubo_kvk_registered_check:
      'I understand that the notary may not set up the BV before the UBO’s have been reported to the KVK.'
  },
  placeholders: {
    prefix: 'Title',
    firstname: 'First name',
    lastname: 'Last Name',
    email: 'Fill in the e-mailaddress',
    birthdate: 'YYYY-MM-DD',
    birthplace: 'Fill in the place of birth',
    address: 'Street name and house number',
    marital_status: '',
    has_proprietorship: '',
    kvk_proprietorship: '',
    company_name: 'Enter company name',
    zip_code: 'Fill in the zipcode',
    location: 'Fill in the name of the city',
    chamber: 'Enter the COC number',
    nationality: '',
    document_number: 'Identification Number',
    place_of_issue: 'Enter the place',
    holding_name: 'Enter name of the Holding',
    bsn: 'BSN Number',
    issue_date: 'YYYY-MM-DD',
    expiration_date: 'YYYY-MM-DD',
    shares: 'Enter number',
    company_location: 'Location',
    company_address: 'Address',
    company_zipcode: 'Postcode'
  },
  options: {
    proprietorship_action: {
      keep: 'The sole proprietorship / VOF will be continued next to the BV',
      transfer: 'The sole proprietorship / VOF will be converted to a BV',
      close:
        'The sole proprietorship / VOF will be discontinued before the incorporation of the BV'
    },
    document_type: {
      passport: 'Passport',
      driver_licence: 'Driver Licence',
      identity_card: 'Identity Card'
    },
    marital_status: {
      unmarried: 'Unmarried and not in a registered partnership',
      registered_partner: 'In a registered partnership',
      married: 'Married'
    },
    representation_type: {
      independently_authorized_director: 'Solely authorized director',
      jointly_authorized_director: 'Jointly authorized director'
    },
    nationality: {
      dutch: 'Dutch',
      belgian: 'Belgian',
      french: 'French',
      english: 'English'
    },
    prefix: {
      Mr: 'Mr',
      Mrs: 'Mrs'
    }
  },
  label_tooltip: {
    firstname:
      'Names includes in deeds need to be identical to names as included in passport or ID card. Include here for instance also you middle name(s), if applicable.',
    birthdate:
      'Are you younger than 18 and do you want to start your own business? Please contact Ligo on 020 303 1043 first to discuss the additional requirements.',
    marital_status:
      'Make sure you enter your marital status as it is officially registered in the BRP.',
    nationality:
      "Can't find your nationality in this list? This may be the case if your nationality is on the European Commission's list of high-risk countries. Please contact Ligo at +31 20 303 1043. We are happy to help you.",
    expiration_date:
      'Please note that the chosen identity document must be valid when you identify yourself at the notary.',
    lives_in_netherlands:
      'If you live abroad, it is possible to set up your BV at Ligo. However, note that extra documentation might be needed. Please contact Ligo at +31 20 303 1043. We are happy to guide you in that regard.',
    english_proficient:
      'For the incorporation in English, all shareholders must be able to speak, read and understand English. The notary will also verify this.',
    ubos:
      "UBO stands for 'Ultimate Beneficial Owner'. The UBO is the person who has control over a company. If you own 25% or more of the shares, you are the owner of the company and you are then an UBO. It is not relevant whether there is a holding between you and the operating BV. As the owner of the holding, you are also the owner of the operating BV. Are there more people having 25% or more of the shares? Then the company has more than one UBO.",
    proprietorship_action_banners: {
      keep:
        'Change the name of the sole proprietorship via the Chamber of Commerce before incorporation of the BV, or change the name of the new BV. The BV cannot be established with the same name as the sole proprietorship.',
      transfer:
        'If you want to convert your sole proprietorship, start the specific questionnaire for conversion to a BV here.',
      close:
        "You can close the sole proprietorship (eenmanszaak) or VOF yourself using this <a href='https://www.kvk.nl/wijzigen/opheffen-rpio/?step=eenmanszaak-info' target='_blank'>link</a>"
    },
    representation_type:
      'You are independently authorized if you set up a holding company for yourself. Are there multiple directors? Then each director can be independently authorized, or jointly authorized. In the case of the latter, you must both sign to bind the holding. Consider opening a bank account or concluding an agreement.'
  }
};
