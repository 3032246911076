







































import { defineComponent, PropType } from '@vue/composition-api';
import { CalculatorFieldSelect, fieldDescriptions } from '@ligo/bv-flow/store';
import { getI18n } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'CalculatorSelectField',
  props: {
    field: {
      type: Object as PropType<CalculatorFieldSelect>,
      require: true
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const emitValue = (value) => {
      // TODO: check later if we really need this logic
      emit('on-answer', value);
    };
    const getOptions = getI18n(props.field, 'options').value.filter((item) => {
      return (
        !props.field.optionKeys || props.field.optionKeys.includes(item.value)
      );
    });
    return {
      emitValue,
      getI18n,
      getOptions
    };
  }
});
