import { nully, RootContext } from '@ligo/shared/utils';
import { computed, Ref, ref } from '@vue/composition-api';

export const useLocaleTools = (root: RootContext) => {
  const stored = localStorage.getItem('locale');
  const lang = ref(stored ? stored : root.$i18n.locale) as Ref<'en' | 'nl'>;
  const enableSwitch = ref(true);

  let onRouteChange;

  function changeLanguage(language: 'en' | 'nl') {
    lang.value = language;
    root.$i18n.locale = lang.value === 'en' ? 'en-us' : language;
    localStorage.setItem('locale', lang.value);
  }

  const i18nSwitch = computed(() => {
    const language = root.$i18n.locale;
    return {
      en: {
        active: language === 'en-us',
        language: 'layout.en'
      },
      nl: {
        active: language === 'nl',
        language: 'layout.nl'
      }
    };
  });

  (onRouteChange = function () {
    const route = root.$router.currentRoute;
    if (!nully(route.meta?.language)) {
      changeLanguage(route.meta.language);
      enableSwitch.value = false;
    } else enableSwitch.value = true;
  })();

  root.$router.onReady(() => {
    onRouteChange();
  });
  root.$router.afterEach(() => {
    onRouteChange();
  });

  return { i18nSwitch, changeLanguage, enableSwitch };
};
