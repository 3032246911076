export default {
  name: 'BV Details',
  title: 'Fill in the basic details of',
  description:
    'Please review your details and make sure they are correct. You can change them after submission if needed, but this will carry extra costs from the notary.',
  opt1: 'Service selected',
  opt2: 'General details',
  opt3: 'Activities',
  opt4: 'People'
};
