import { computed } from '@vue/composition-api';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';
import { useShareholders } from '../tools';
import { ShareStepPageInformation } from '../../../../hooks/useBvPageInformation.hooks';

const WARNING_MIN = 300.1;

export const useChart = (notary_product: NotaryProduct) => {
  const { isReady, t, te, load } = ShareStepPageInformation;

  load();

  const { shareholders, total, bvForm, save } = useShareholders(notary_product);
  const series = computed(() => {
    const a: Array<number> = [];
    const valueOfShares = bvForm.value.fields.value_of_shares.value;
    let sum = 0;
    shareholders.value.forEach((element) => {
      a.push(Number(element.form.fields.shares.value) * valueOfShares);
      sum += a[a.length - 1];
    });
    return sum === 0
      ? [bvForm.value.fields.number_of_shares.defaultValue * valueOfShares]
      : a;
  });

  const labels = computed(() => {
    const a: Array<string> = [];
    shareholders.value.forEach((element) => {
      a.push(element.name);
    });
    return a;
  });

  const warning = computed(() => {
    return (
      bvForm.value.fields.value_of_shares.value *
        series.value.reduce((sum, share) => sum + share, 0) >=
      WARNING_MIN
    );
  });

  const title = {
    dark: productLocale(notary_product, 'title')
  };

  const subtitle = productLocale(notary_product, 'description');

  const donutData = {
    chart: {
      width: 380,
      type: 'donut'
    },
    dataLabels: {
      enabled: true
    },
    colors: ['#061387', '#FFB0B0', '#37CFB1', '#C62828'],
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              showAlways: true,
              show: true,
              formatter: (val) =>
                `€${val.config.series
                  .reduce((sum, share) => sum + share, 0)
                  .toFixed(2)}`
            }
          },
          size: '40%'
        },
        size: 400
      }
    },
    labels: labels,
    legend: {
      position: 'bottom',
      horizontalAlign: 'center'
    }
  };

  return {
    shareholders,
    total,
    bvForm,
    donutData,
    series,
    title,
    subtitle,
    warning,
    save,
    t,
    te,
    isReady
  };
};
