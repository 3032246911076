





















import { defineComponent, PropType } from '@vue/composition-api';
import { AppLink } from '@ligo/shared/components';
import { AuthError } from '@ligo/bv-flow/store';
import { socialAuthLink } from './auth';
import BvAlertBanner from '../../components/base/BvAlertBanner.vue';

export default defineComponent({
  components: { AppLink, BvAlertBanner },
  props: {
    error: { type: Object as PropType<AuthError> },
    redirectUrl: String
  },
  setup(props, { root }) {
    const errorMsg = () =>
      props.error.code
        ? root.$i18n.t(`auth.errorCodeMsg.${props.error.code}`)
        : props.error.message;
    const errorLinks = {
      different_provider: root.$t(
        `auth.errorCodeLink.different_provider.${
          props.error.data ? props.error.data.provider : ''
        }`
      )
    };
    const errorURLs = {
      different_provider: socialAuthLink(
        props.error.data ? props.error.data.provider : null,
        props.redirectUrl
      )
    };
    return {
      errorMsg,
      errorLinks,
      errorURLs
    };
  }
});
