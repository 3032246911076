











import { defineComponent } from '@vue/composition-api';
import { PropType } from 'vue';
import { Blok } from '../models/blok';

export default defineComponent({
  name: 'Grid',
  props: {
    columns: { type: (Array as unknown) as PropType<Blok[]>, required: true },
    classes: String
  }
});
