export default {
  name: 'ESSENTIALS',
  title: 'We strongly recommend you to setup a holding structure to save tax',
  description:
    'A holdingstructure is important for each and every entrepreneur. Whether you want to start of small or go big instantly. This structure provides you with many benefits:',
  holding_for: 'Holding for ',
  save: 'SAVE',
  checklist1: 'Save money by avoiding a high tax burden',
  checklist2: 'Minimise liability risks',
  checklist3:
    'Multifunctional, can be used for all your existing and new companies',
  saves_tooltip: 'Start now and get',
  saves_tooltip_highlighted: '€30 discount in your personal holding structure!'
};
