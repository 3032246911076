










































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import {
  buildLabel,
  useNotaryForm,
  useNotariesTools
} from './choose-notary.hooks';
import { productLocale, NotaryProduct } from '@ligo/bv-flow/store';
import { analyticSelectNotary } from '../../../../analytics';
import InstructionBanner from '../../base-components/instruction-banner.vue';
import IconList from '../../base-components/icon-list.vue';

export default defineComponent({
  name: 'ChooseNotary',
  components: { InstructionBanner, IconList },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    }
  },
  setup(props, { emit, root }) {
    const online_notary = ref(true);
    const notary_choice = ref('not0|0');
    const {
      notaries,
      assignNotary,
      requestNotaries,
      getNotaryFromChoice,
      hasInformation,
      getInformation,
      initializeData,
      getInitialOnlineChoice,
      ENABLED_ONLINE,
      loading,
      checkPredefinedNotary
    } = useNotariesTools(props.notary_product.slug);

    loading.value = true;
    checkPredefinedNotary(props.notary_product).then((result) => {
      if (result) {
        if (props.notary_product.temporalStep < props.notary_product.step) {
          emit('previous-step');
          loading.value = false;
        } else endStep().then(() => (loading.value = false));
      } else loading.value = false;
    });

    const header = computed(() => {
      return {
        title: {
          dark: productLocale(
            props.notary_product,
            'choose_notary.almost_ready'
          )
        }
      };
    });

    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });

    initializeData(props.notary_product).then((initialData) => {
      notary_choice.value = initialData.notary_choice;
    });
    online_notary.value = Boolean(
      getInitialOnlineChoice(props.notary_product) && ENABLED_ONLINE
    );

    const handleToggle = () => {
      online_notary.value = !online_notary.value;

      if (!online_notary.value && !notaries.value) {
        requestNotaries(props.notary_product.slug);
      }
    };

    const { form } = useNotaryForm(props.notary_product);
    const endStep = async () => {
      analyticSelectNotary(
        props.notary_product.locale,
        root.$store.state.authentication.accessToken,
        getNotaryFromChoice(notary_choice.value)
      );

      await assignNotary(
        online_notary.value,
        notary_choice.value,
        props.notary_product
      );

      emit('endedStep');
    };
    return {
      props,
      online_notary,
      notaries,
      notary_choice,
      buildLabel,
      form,
      endStep,
      productLocale,
      hasInformation,
      getInformation,
      header,
      handleToggle,
      ENABLED_ONLINE,
      loading
    };
  }
});
