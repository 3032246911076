


























































































































































































































import { defineComponent, ref } from '@vue/composition-api';
import { useData, useHasFreeQuotes } from './Checkout.hooks';
import { Notify } from 'quasar';
import { productLocale } from '@ligo/shared/mvc';
import Vue from 'vue';
import OrderList from './OrderList.vue';
import NewContactCard from '../../components/base/NewContactCard.vue';
import BackButton from '../../components/base/BackButton.vue';
import NextButton from '../../components/base/NextButton.vue';
import { useLocaleTools } from '../../components/layout/locale-hooks';
import { RESOURCES } from '@ligo/bv-flow/store';
import BvAlertBanner from '../../components/base/BvAlertBanner.vue';

const IMAGE_PAYMENT = '/questionnaire/images/payments.svg';
const ICON_CHECK = 'img:/questionnaire/icons/radio_check.svg';
const ICON_UNCHECK = 'img:/questionnaire/icons/radio_uncheck.svg';
const HEIGHT_MD = 40;
const HEIGHT_SM = 60;

export default defineComponent({
  name: 'Checkout',
  components: {
    OrderList,
    NewContactCard,
    BackButton,
    NextButton,
    BvAlertBanner
  },
  props: {
    slug: String,
    uid: String
  },
  setup(props, { root }) {
    const { changeLanguage } = useLocaleTools(root);

    const {
      loading: freeLoading,
      hasBookkepper,
      load: loadFreeQuotes
    } = useHasFreeQuotes();

    const {
      loading,
      checkoutData,
      checkoutProductFormRef,
      criminalRecordsLabel,
      notaryProduct,
      CATEGORIES_ORDER,
      goBack
    } = useData(props.uid, props.slug, root, (notaryProduct) => {
      loadFreeQuotes(notaryProduct);
    });

    changeLanguage(notaryProduct.value.language);
    const termsAndConditions = ref(false);
    function checkboxChange() {
      void checkoutProductFormRef.value.save(false);
    }
    function changeApproval(value) {
      checkoutProductFormRef.value.fields.lead_approval.value = value;
    }
    const finish = async () => {
      if (
        checkoutProductFormRef.value.fields.criminal_record_status.value &&
        termsAndConditions.value
      ) {
        loading.value = true;
        await checkoutProductFormRef.value.save();
        Vue['Router'].push({
          name: 'Payment',
          params: {
            uuid: checkoutData.value.payment.uuid
          }
        });
      } else {
        const alertLocale = !checkoutProductFormRef.value.fields
          .criminal_record_status.value
          ? 'preview_order.criminal_record_alert'
          : 'preview_order.terms_and_conditions_alert';
        Notify.create({
          message: root.$i18n.t(
            productLocale(notaryProduct.value, alertLocale)
          ) as string,
          color: 'surface-critical',
          position: 'top',
          icon: 'mdi-alert',
          classes: 'bv-notify'
        });
      }
    };
    return {
      loading,
      checkoutData,
      checkoutProductFormRef,
      criminalRecordsLabel,
      notaryProduct,
      productLocale,
      termsAndConditions,
      checkboxChange,
      finish,
      CATEGORIES_ORDER,
      goBack,
      changeApproval,
      RESOURCES,
      IMAGE_PAYMENT,
      HEIGHT_SM,
      ICON_CHECK,
      ICON_UNCHECK,
      HEIGHT_MD,
      freeLoading,
      hasBookkepper
    };
  }
});
