import { ref, Ref } from '@vue/composition-api';
import { AxiosPromise, AxiosResponse } from 'axios';

export const strFix = (s: string) => {
  s = s.replace(/\//g, '');
  s = s.replace(/\*/g, '');
  return s;
};

const getReactiveResults = <T>(promise: Promise<T>) => {
  const res = ref(null as any) as Ref<T>;
  promise
    .then(result => {
      res.value = result;
      console.log(res.value);
    })
    .catch((e: any) => {
      console.log(e);
    });
  return res;
};

const promiseToResults = <T, M>(transform?: (obj: T) => M) => (
  response: AxiosResponse<Array<T>>
) => {
  return transform ? response.data.map(transform) : response.data;
};

export const APIResultWrapper = <T, M>(
  promise: AxiosPromise<Array<T>>,
  transform?: (obj: T) => M
) => {
  return getReactiveResults(promise.then(promiseToResults(transform)));
};

export const APISingleObject = <T, M>(
  promise: AxiosPromise<T>,
  transform?: (obj: T) => M
) => {
  return getReactiveResults(
    promise.then((response: AxiosResponse) => {
      return transform ? transform(response.data) : response.data;
    })
  );
};
