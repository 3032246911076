
































































































import { defineComponent, computed } from '@vue/composition-api';
import BenefitCard from './BenefitCard.vue';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';
import {
  useUpsellPackageData,
  useUpsellPackageSave
} from './contract_upsell.hooks';
import UpsellPackageCard from './UpsellPackageCard.vue';
import UpsellVideoDialog from './UpsellVideoDialog.vue';
import UpsellContractsDialog from './UpsellContractsDialog.vue';
import UpsellContractsLibraryDialog from './UpsellContractsLibraryDialog.vue';
import NewContactCard from '../../../base/NewContactCard.vue';
import BackButton from '../../../base/BackButton.vue';
import NextButton from '../../../base/NextButton.vue';
import BvAlertBanner from '../../../base/BvAlertBanner.vue';
import {
  analyticGTMEnterMembershipUpsell,
  analyticGTMMembershipUpsell
} from '../../../../hooks/useBvFlowGTM.hooks';
import { BvBannerDiscountProps } from '../../../base/bvAlertBanner.hooks';

const SPINNER_SIZE = '200px';
const ICON_SIZE = 20;

export default defineComponent({
  name: 'ContractUpsell',
  components: {
    BenefitCard,
    UpsellPackageCard,
    UpsellVideoDialog,
    UpsellContractsDialog,
    UpsellContractsLibraryDialog,
    NewContactCard,
    BackButton,
    NextButton,
    BvAlertBanner
  },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    }
  },
  setup(props, { emit }) {
    const {
      loading,
      dialogs,
      benefits,
      packageTypesInfo,
      videoDialogData,
      contractsDialogData,
      contractsLibraryDialog,
      t,
      getImage,
      getIcon,
      onAction
    } = useUpsellPackageData();

    analyticGTMEnterMembershipUpsell(
      props.notary_product.locale,
      props.notary_product.temporalStep,
      props.notary_product.slug
    );

    const { onSave, packagePlans, selectedPackagePlan } = useUpsellPackageSave(
      loading,
      props.notary_product,
      emit
    );

    const getActivePackages = computed(() =>
      packagePlans.value.filter(
        (x) => !!packageTypesInfo.value[x.product_type.slug]
      )
    );

    return {
      benefits,
      packageTypesInfo,
      loading,
      packagePlans,
      selectedPackagePlan,
      getActivePackages,
      dialogs,
      videoDialogData,
      contractsDialogData,
      contractsLibraryDialog,
      SPINNER_SIZE,
      ICON_SIZE,
      BvBannerDiscountProps,
      t,
      onSave,
      productLocale,
      getImage,
      onAction,
      getIcon,
      onSelectPlan(plan: any) {
        selectedPackagePlan.value = plan;
        if (!!selectedPackagePlan.value)
          analyticGTMMembershipUpsell(
            selectedPackagePlan.value,
            packagePlans.value
          );
      }
    };
  }
});
