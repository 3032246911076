import { NotaryProduct } from '@ligo/bv-flow/store';
import { RootContext } from '@ligo/shared/utils';
import { ComputedRef, Ref } from '@vue/composition-api';
import { paymentRedirect } from './payment_redirection';

export const reloadPage = (
  loading: Ref<boolean>,
  root: RootContext,
  notary_product: NotaryProduct
) => {
  loading.value = true;
  const loggedIn = root.$store.state.authentication.accessToken ? true : false;
  void notary_product.load(notary_product.uuid, loggedIn).then(() => {
    loading.value = false;
  });
};

export const endStep = (
  currentStep: ComputedRef<number>,
  notary_product: NotaryProduct,
  root: RootContext
) => {
  if (currentStep.value != notary_product.finalStep) {
    notary_product.nextStep();
  } else {
    paymentRedirect(currentStep, notary_product, root, true);
  }
};
