import { ref } from '@vue/composition-api';
import {
  getField,
  NotaryProductSlug,
  Shareholder,
  ShareholderForms
} from '@ligo/bv-flow/store';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';

export function checkDirectorRequirements(slug: NotaryProductSlug) {
  return [
    NotaryProductSlug.BV_OPRICHTEN,
    NotaryProductSlug.HOLDING_OPRICHTEN,
    NotaryProductSlug.INCORPORATE_DUTCH_BV,
    NotaryProductSlug.INCORPORATE_HOLDING,
    NotaryProductSlug.OMZETTING
  ].includes(slug);
}

export const useData = (
  notary_product: NotaryProduct,
  shareholderForms: Array<ShareholderForms>,
  setDefaultActives?: Array<number>,
  shareholders_len = 4
) => {
  const title = {
    dark: productLocale(notary_product, 'shareholder_information.title'),
    light: getField(notary_product, 'main', 'company_name'),
    useLightText: true
  };
  const subtitle = productLocale(
    notary_product,
    'shareholder_information.description'
  );
  const sideOptions = [1, 2, 3, 4].map((x) =>
    productLocale(notary_product, `shareholder_information.opt${x}`)
  );
  const shareholdersList = ref([] as Array<Shareholder>);
  for (let i = 0; i < shareholders_len; ++i) {
    const key = `p${i + 1}`;
    const sh = new Shareholder(
      key,
      notary_product,
      shareholderForms.length == 1 ? shareholderForms[0] : shareholderForms[i]
    );
    shareholdersList.value.push(sh);
  }
  shareholdersList.value[0].active = true;
  shareholdersList.value[0].save(false);
  if (setDefaultActives && setDefaultActives.length > 0) {
    setDefaultActives.forEach((i) => {
      shareholdersList.value[i].active = true;
      shareholdersList.value[i].save(false);
    });
  }
  const getActiveIndexes = () => {
    const active = [];
    for (let i = 0; i < shareholdersList.value.length; ++i) {
      if (shareholdersList.value[i].active) active.push(i);
    }
    return active;
  };
  const activeIndexes = ref(getActiveIndexes());

  const getJSONValue = (index: number) => {
    return notary_product.getValues(shareholdersList.value[index].id);
  };

  return {
    shareholdersList,
    title,
    subtitle,
    sideOptions,
    activeIndexes,
    getActiveIndexes,
    getJSONValue
  };
};
