









































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { ContractsDialog } from '@ligo/bv-flow/store';
import { Screen } from 'quasar';
const DOCUMENT_ICON = '/questionnaire/images/upsell/document.png';
const IMAGE_WIDTH = 28;
export default defineComponent({
  name: 'UpsellContractsDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object as PropType<ContractsDialog>
    }
  },
  setup() {
    const cardWidth = computed(() => {
      if (Screen.gt.sm) return 1080;
      if (Screen.sm) return 700;
      return 900;
    });
    return { cardWidth, DOCUMENT_ICON, IMAGE_WIDTH };
  }
});
