import { NotaryProduct } from '@ligo/bv-flow/store';
import Vue from 'vue';

const KEY = 'product_type';

export const saveProductType = (type: string) => {
  localStorage.setItem(KEY, type);
};

export const loadProductType = () => {
  return localStorage.getItem(KEY);
};

export const cleanProductType = () => {
  return localStorage.removeItem(KEY);
};

export const authRedirection = (
  redirect_url?: string,
  product_type?: string
) => {
  const getType = product_type ? product_type : loadProductType();
  let defaultPath = '/questionnaire/';
  if (getType) defaultPath += getType;
  const path = redirect_url ? redirect_url : defaultPath;
  if (path) void Vue['Router'].push(path);
  else {
    void Vue['Router'].push({ name: 'Home' });
  }
};

export function getField(
  notary_product: NotaryProduct,
  resource: string,
  field: string,
  default_val?: any
) {
  const field_val = notary_product.resources[resource].values[field];
  if (field_val) {
    return field_val;
  }
  return default_val;
}
