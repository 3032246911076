












import { defineComponent } from '@vue/composition-api';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'PreviewBanner',
  components: {},
  props: {
    title: {
      type: String,
      required: true
    }
  },
  setup() {
    return {
      productLocale
    };
  }
});
