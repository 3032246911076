





















import { defineComponent, ref, watch } from '@vue/composition-api';
import {
  DutchBvHandler,
  EnglishBvHandler,
  HoldingHandler,
  OmzettingHandler,
  StichtingHandler,
  AnbiStichtingHandler,
  TransferOfSharesHandler,
  IncorporateHoldingHandler
} from '../../components/handlers';
import { Analytic } from '../../analytics';
import CloseDialog from '../../components/base/BvCloseDialog.vue';
import { useLoadProduct } from './load-product.hook';
import { toKebabCase } from '@ligo/shared/utils';

const SPINNER_SIZE = '200px';

export default defineComponent({
  name: 'NotaryProductHandler',
  components: {
    DutchBvHandler,
    EnglishBvHandler,
    HoldingHandler,
    OmzettingHandler,
    StichtingHandler,
    AnbiStichtingHandler,
    TransferOfSharesHandler,
    CloseDialog,
    IncorporateHoldingHandler
  },
  props: {
    uid: {
      type: String,
      required: false
    },
    calculationUuid: {
      type: String
    },
    initialValues: {
      type: Object
    },
    redirectRouteName: {
      type: String
    }
  },
  setup(props, { root, emit }) {
    Analytic.registerPage('Steps');

    const {
      handler,
      notary_product,
      loading,
      reload,
      productGoBack
    } = useLoadProduct(
      root,
      props.redirectRouteName || root.$route.name,
      props.uid,
      props.initialValues || {},
      props.calculationUuid
    );

    const goingBack = ref(false);

    function handleGoBack(backToHome = false) {
      if (
        notary_product.value.temporalStep > 0 ||
        backToHome ||
        notary_product.value.calculationUuid
      )
        productGoBack();
      else goingBack.value = true;
    }
    watch(loading, () => {
      if (!loading.value)
        emit('on-notary-product-load', notary_product.value, reload);
    });
    return {
      productGoBack,
      notary_product,
      handler,
      reload,
      loading,
      goingBack,
      handleGoBack,
      toKebabCase,
      SPINNER_SIZE
    };
  }
});
