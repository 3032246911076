import { NotaryProductSlug } from '@ligo/bv-flow/store';
import { HoldingUpsellPageInformation } from '@ligo/bv-flow/hooks';
import { computed } from '@vue/composition-api';

const HOLDING_PRICE = 369;
const DISCOUNT = 30;

export interface DescriptionList {
  title: string;
  subtitle: string;
}

export const useHoldingUpsell = (slug: NotaryProductSlug) => {
  const { load: vload, isReady, get, getNested } = HoldingUpsellPageInformation;

  function load() {
    if (!isReady()) {
      return vload();
    }
    return Promise.resolve();
  }

  const prices = computed(() => {
    const defaultPrices = { price: HOLDING_PRICE, discount: DISCOUNT };
    return get<{ price: number; discount: number }>(slug, defaultPrices);
  });

  const defaultTInfo = getNested('default');
  const productTInfo = getNested(slug);

  const t = (key: string) => {
    if (productTInfo.te(key)) return productTInfo.t(key);
    if (defaultTInfo.te(key)) return defaultTInfo.t(key);
    return '';
  };

  function getObj<T>(key: string, defaultObj: T) {
    if (productTInfo.te(key)) return productTInfo.get<T>(key, defaultObj);
    if (defaultTInfo.te(key)) return defaultTInfo.get<T>(key, defaultObj);
    return defaultObj;
  }

  const advantangeList = computed(() => {
    return getObj<DescriptionList[]>('advantages_list', []);
  });

  const disadvantangeList = computed(() => {
    return getObj<DescriptionList[]>('disadvantages_list', []);
  });

  return { prices, load, t, getObj, advantangeList, disadvantangeList };
};
