import { ProductField, ProductFieldClassMap } from './ProductField';
import { ref } from '@vue/composition-api';
import { BasicForm, GConstructor } from '@ligo/shared/mvc';
import { Dictionary } from '@ligo/shared/utils';

export class ProductForm extends BasicForm<ProductField> {
  col_class: string;

  getFieldClassMap(): Dictionary<GConstructor<ProductField>> {
    return ProductFieldClassMap as Dictionary<GConstructor<ProductField>>;
  }

  processExtraValidations() {
    const fieldDcls = this.resource.getFields(this.id);
    this.keys().forEach((key) => {
      const fieldDcl = fieldDcls[key] as ProductField;
      if (fieldDcl && fieldDcl.form_validation) {
        const rule = fieldDcl.form_validation;
        const field_ref = ref(this.fields[rule.fieldParam]);
        const new_rule = rule.rule(field_ref);
        this.fields[key].external_validation = new_rule;
        this.fields[key].external_validation_message = rule.message;
      }

      const rule = fieldDcl.dependency_dcl || this.fields[key].dependency_dcl;

      if (fieldDcl && rule) {
        const field_ref = ref(this.fields[rule.fieldParam]);
        const new_rule = rule.rule(field_ref);
        this.fields[key].dependency = new_rule;
      }
    });
  }
}
