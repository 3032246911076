export default {
  labels: {
    name: 'Vul hier een naam of bedrijfsnaam in',
    street: 'Adres',
    house_number: 'Huisnummer',
    zip: 'Postcode',
    city: 'Woonplaats',
    phone_number: 'Telefoonnummer',
    vat_number: 'BTW-nummer',
    extra: 'Aanvullende informatie'
  },
  placeholders: {
    name: '',
    street: '',
    house_number: '',
    zip: '',
    city: '',
    phone_number: '',
    vat_number: 'Optioneel',
    extra: 'Optioneel'
  },
  tooltips: {}
};
