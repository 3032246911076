import { ref, computed, watch, Ref } from '@vue/composition-api';
import { BasicForm } from '@ligo/shared/mvc';
import {
  ShareholderType,
  NotaryProduct,
  NotaryProductResource,
  ProductForm
} from '@ligo/bv-flow/store';
import { ApiService, Dictionary } from '@ligo/shared/utils';

type UpsellResponse = { upsell_package: { payment: { id: number } } };

const SHAREHOLDER_NAME_LENGTH = 2;

export const useShareholders = (notary_product: NotaryProduct) => {
  const activeShareholders: Array<NotaryProductResource<ShareholderType>> = [];
  Object.keys(notary_product.resources).forEach((x) => {
    if (
      x.length == SHAREHOLDER_NAME_LENGTH &&
      x[0] == 'p' &&
      notary_product.resources[x].active
    ) {
      activeShareholders.push(notary_product.resources[x]);
    }
  });
  const bvFormFields = [['number_of_shares'], ['value_of_shares']];
  const bvForm = ref(new ProductForm('main', bvFormFields, notary_product));
  if (!bvForm.value.fields.value_of_shares.value)
    bvForm.value.fields.value_of_shares.value = 0.01;

  const shareholderFormFields = [
    [
      'shares',
      'dga_plan',
      'holding',
      'holding_name',
      'is_new_company',
      'is_natural_person',
      'company_address',
      'company_location',
      'company_zipcode',
      'address',
      'location',
      'zip_code'
    ]
  ];

  const getName = (sh: any) => {
    if (sh.values.is_natural_person) {
      return `${sh.values.firstname} ${sh.values.lastname}`;
    }
    return sh.values.company_name;
  };

  const shareholders: Ref<
    { name: string; form: BasicForm; id: string }[]
  > = ref([]);
  const ableToHold: Ref<{ name: string; form: BasicForm; id: string }[]> = ref(
    []
  );

  activeShareholders.forEach((sh: NotaryProductResource<ShareholderType>) => {
    const data = {
      id: sh.id,
      name: getName(sh) as string,
      form: new ProductForm(sh.id, shareholderFormFields, notary_product)
    };
    shareholders.value.push(data);
    if (sh.values.is_natural_person) {
      ableToHold.value.push(data);
    }
  });

  const total = computed(() => {
    return shareholders.value.reduce(
      (sum, shareholderShares) =>
        sum + Number(shareholderShares.form.fields.shares.value),
      0
    );
  });

  watch(
    () => total.value,
    (after) => {
      bvForm.value.fields.number_of_shares.value = after;
    }
  );

  const save = async () => {
    shareholders.value.forEach((sh: any) => {
      if (
        sh.form.fields.is_natural_person.value &&
        sh.form.fields.holding?.value
      ) {
        sh.form.fields.company_address.value = sh.form.fields.address.value;
        sh.form.fields.company_location.value = sh.form.fields.location.value;
        sh.form.fields.company_zipcode.value = sh.form.fields.zip_code.value;
      }
      void sh.form.save(false);
    });
    await bvForm.value.save();
  };

  return {
    shareholders,
    ableToHold,
    total,
    bvForm,
    save
  };
};

export const updatePayment = async (uuid: string, url: string) => {
  return await ApiService.patch<Dictionary, UpsellResponse>(
    `${url + uuid}/update_upsell_package`,
    {}
  );
};
