import VueApexCharts from './apexcharts';
import Api from './api';
import Axios from './axios';
import CompositionApi from './composition-api';
import I18n from './i18n';
import pageInformation from './pageInformation';
import PDFViewer from '@quasar/quasar-app-extension-qpdfviewer/src/boot/qpdfviewer.js';
import HideDirective from './hide-directive';
import GTM from './gtm';
import { BootFunction } from '@ligo/shared/quasar';
import { bugsnag, BugsnagStage } from '@ligo/shared/utils';
import { useEnvironment } from '../../../../netlify_functions';
import { storyblokBoot } from '@ligo/shared/storyblok';

const bugsnagStage = useEnvironment().APP_CONTEXT;
const storyblokToken = useEnvironment().STORYBLOK_TOKEN;

export const bootFiles: BootFunction[] = [
  storyblokBoot(storyblokToken),
  bugsnag('436dc1c40c808d1f17c73f443ca9b26e', bugsnagStage as BugsnagStage),
  CompositionApi,
  pageInformation,
  I18n,
  Axios,
  Api,
  VueApexCharts,
  PDFViewer,
  HideDirective,
  GTM
];
