import {
  NotaryProductSlug,
  RESOURCES,
  UpsellPackageTypeProduct
} from '@ligo/bv-flow/store';
import { ApiService, pushDataLayerGTM } from '@ligo/shared/utils';
import {
  EVENT_NAME_FOR_GTM_SERVICE,
  EVENT_NAME_FOR_GTM_SHAREHOLDERS,
  NotarialGTMEvent
} from './useGtmStepsEvent';

export interface NotaryProductDataLayer {
  event: string;
  step?: number;
  product_type: string;
  product_slug?: NotaryProductSlug;
}

export interface EcommerceDataLayer {
  event?: string;
  ecommerce: {
    promotion_name: string;
    items: {
      item_id: string;
      item_name: string;
      item_brand?: string;
      item_category?: string;
      item_quantity?: number;
      item_price?: number;
    }[];
  } | null;
  product_type?: string;
  step?: number;
  product_category?: 'service';
  product_slug?: NotaryProductSlug;
}

export interface DataLayerGTMModel extends NotaryProductDataLayer {
  product_category: string;
}

export const sendNotaryProductGTMData = (data: NotaryProductDataLayer) => {
  if (data.step === undefined) {
    pushDataLayerGTM<DataLayerGTMModel>({
      ...data,
      product_category: 'service'
    });
  } else
    pushDataLayerGTM<DataLayerGTMModel>({
      ...data,
      step: data.step + 1,
      product_category: 'service'
    });
};

export const sendEcommerceGTMData = (data: EcommerceDataLayer) => {
  pushDataLayerGTM<EcommerceDataLayer>({ ecommerce: null });
  pushDataLayerGTM<EcommerceDataLayer>(data);
};

export function analyticGTMServiceDetails(
  product: string,
  step: number,
  substep: number,
  slug: NotaryProductSlug
) {
  if (EVENT_NAME_FOR_GTM_SERVICE[slug])
    sendNotaryProductGTMData({
      event:
        EVENT_NAME_FOR_GTM_SERVICE[slug][substep] ||
        EVENT_NAME_FOR_GTM_SERVICE[slug][0],
      step: step,
      product_slug: slug,
      product_type: product
    });
}

export function analyticGTMSelectShareholders(
  product: string,
  step: number,
  substep: number,
  slug: NotaryProductSlug
) {
  if (EVENT_NAME_FOR_GTM_SHAREHOLDERS[slug])
    sendNotaryProductGTMData({
      event:
        EVENT_NAME_FOR_GTM_SHAREHOLDERS[slug][substep] ||
        EVENT_NAME_FOR_GTM_SHAREHOLDERS[slug][0],
      step: step,
      product_slug: slug,
      product_type: product
    });
}

export function analyticGTMShare(product: string, step: number, slug: string) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_SHAREHOLDERS_SHARE,
    step: step,
    product_type: product,
    product_slug: slug as NotaryProductSlug
  });
}

export function analyticGTMSelectValueOfShares(
  product: string,
  step: number,
  slug: string
) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_SERVICE_DETAILS_TRANSFER_SHARE,
    step: step,
    product_type: product,
    product_slug: slug as NotaryProductSlug
  });
}

export function analyticGTMEnterMembershipUpsell(
  product: string,
  step: number,
  slug: string
) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_UPSELL_PACKAGE,
    product_type: product,
    step: step,
    product_slug: slug as NotaryProductSlug
  });
}

export function analyticGTMPartnerOffers(
  product: string,
  step: number,
  slug: string
) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_PARTNER_OFFER,
    product_type: product,
    step: step,
    product_slug: slug as NotaryProductSlug
  });
}

export function analyticGTMPreviewMembershipUpsell(
  packageList: UpsellPackageTypeProduct[]
) {
  sendEcommerceGTMData({
    event: NotarialGTMEvent.SELECT_ECOMMERCE_VIEW_NAME,
    ecommerce: {
      promotion_name: NotarialGTMEvent.SELECT_UPSELL_ECOMMERCE_NAME,
      items: packageList
        .filter((p) => !!p.id)
        .map((selectedPackage) => ({
          item_id: `MT-${selectedPackage?.id}`,
          item_name: selectedPackage?.product_type_type,
          item_brand: 'Ligo',
          item_category: 'Notarial',
          item_quantity: 1,
          item_price: selectedPackage?.package_price
        }))
    }
  });
}

export function analyticGTMPreviewPartnerOffer(
  packageList: UpsellPackageTypeProduct[]
) {
  sendEcommerceGTMData({
    event: NotarialGTMEvent.SELECT_ECOMMERCE_VIEW_NAME,
    ecommerce: {
      promotion_name: NotarialGTMEvent.SELECT_PARTNER_OFFER_ECOMMERCE_NAME,
      items: packageList
        .filter((p) => !!p.id)
        .map((selectedPackage) => ({
          item_id: `PO-${selectedPackage?.id}`,
          item_name: selectedPackage?.product_type_type,
          item_brand: 'Ligo',
          item_category: 'Notarial',
          item_quantity: 1,
          item_price: selectedPackage?.package_price
        }))
    }
  });
}

export function analyticGTMMembershipUpsell(
  selectedId: number,
  packageList: UpsellPackageTypeProduct[]
) {
  const selectedPackage = packageList.find((p) => p.id == selectedId);

  sendEcommerceGTMData({
    event: NotarialGTMEvent.SELECT_ECOMMERCE_SELECT_NAME,
    ecommerce: {
      promotion_name: selectedId
        ? NotarialGTMEvent.SELECT_UPSELL_ECOMMERCE_NAME
        : '',
      items: [
        {
          item_id: `MT-${selectedPackage?.id}`,
          item_name: selectedPackage?.product_type_type,
          item_brand: 'Ligo',
          item_category: 'Notarial',
          item_quantity: 1,
          item_price: selectedPackage?.package_price
        }
      ]
    }
  });
}

export function analyticGTMSelectPartnerOffer(
  selectedId: number[],
  packageList: UpsellPackageTypeProduct[]
) {
  const selectedPackages = packageList.filter((p) => selectedId.includes(p.id));

  sendEcommerceGTMData({
    event: NotarialGTMEvent.SELECT_ECOMMERCE_SELECT_NAME,
    ecommerce: {
      promotion_name:
        selectedId.length > 0
          ? NotarialGTMEvent.SELECT_PARTNER_OFFER_ECOMMERCE_NAME
          : '',
      items: selectedPackages.map((selectedPackage) => ({
        item_id: `PO-${selectedPackage?.id}`,
        item_name: selectedPackage?.product_type_type,
        item_brand: 'Ligo',
        item_category: 'Notarial',
        item_quantity: 1,
        item_price: selectedPackage?.package_price
      }))
    }
  });
}

export function analyticGTMEnterSelectHolding(
  product: string,
  step: number,
  slug: string,
  holdingCost: number
) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_HOLDING_UPSELL,
    product_type: product,
    step: step,
    product_slug: slug as NotaryProductSlug
  });

  sendEcommerceGTMData({
    event: NotarialGTMEvent.SELECT_ECOMMERCE_VIEW_NAME,
    ecommerce: {
      promotion_name: NotarialGTMEvent.SELECT_HOLDING_UPSELL_ECOMMERCE,
      items: [
        {
          item_id: NotarialGTMEvent.SELECT_HOLDING_UPSELL_ECOMMERCE,
          item_name: NotarialGTMEvent.SELECT_HOLDING_UPSELL_ECOMMERCE,
          item_brand: 'Ligo',
          item_category: 'Notarial',
          item_price: holdingCost
        }
      ]
    }
  });
}

export function analyticGTMSelectHolding(
  amountHoldingShareholders: number,
  holdingCost: number
) {
  sendEcommerceGTMData({
    event: NotarialGTMEvent.SELECT_ECOMMERCE_SELECT_NAME,
    ecommerce: {
      promotion_name: NotarialGTMEvent.SELECT_HOLDING_UPSELL_ECOMMERCE,
      items: [
        {
          item_id: NotarialGTMEvent.SELECT_HOLDING_UPSELL_ECOMMERCE,
          item_name: NotarialGTMEvent.SELECT_HOLDING_UPSELL_ECOMMERCE,
          item_brand: 'Ligo',
          item_category: 'Notarial',
          item_quantity: amountHoldingShareholders,
          item_price: holdingCost
        }
      ]
    }
  });
}

export function analyticGTMEnterSelectDga(
  product: string,
  step: number,
  slug: string,
  dgaCost: number
) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_DGA,
    product_type: product,
    step: step,
    product_slug: slug as NotaryProductSlug
  });

  sendEcommerceGTMData({
    event: NotarialGTMEvent.SELECT_ECOMMERCE_VIEW_NAME,
    ecommerce: {
      promotion_name: NotarialGTMEvent.SELECT_DGA_ECOMMERCE,
      items: [
        {
          item_id: NotarialGTMEvent.SELECT_DGA_ECOMMERCE,
          item_name: NotarialGTMEvent.SELECT_DGA_ECOMMERCE,
          item_brand: 'Ligo',
          item_category: 'Notarial',
          item_price: dgaCost
        }
      ]
    }
  });
}

export function analyticGTMSelectDga(
  amountDGAShareholders: number,
  dgaCost: number
) {
  sendEcommerceGTMData({
    event: NotarialGTMEvent.SELECT_ECOMMERCE_SELECT_NAME,
    ecommerce: {
      promotion_name: NotarialGTMEvent.SELECT_DGA_ECOMMERCE,
      items: [
        {
          item_id: NotarialGTMEvent.SELECT_DGA_ECOMMERCE,
          item_name: NotarialGTMEvent.SELECT_DGA_ECOMMERCE,
          item_brand: 'Ligo',
          item_category: 'Notarial',
          item_quantity: amountDGAShareholders,
          item_price: dgaCost
        }
      ]
    }
  });
}

export function analyticGTMSelectDeed(
  product: string,
  step: number,
  slug: string
) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_PREVIEW_DOCUMENT,
    product_type: product,
    step: step,
    product_slug: slug as NotaryProductSlug
  });
}

export function analyticGTMSignUp(product: string, step: number, slug: string) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_CREATE_ACCOUNT,
    product_type: product,
    step: step,
    product_slug: slug as NotaryProductSlug
  });
}

export function analyticGTMSelectSignUp(product: string) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_CREATE_SIGNUP,
    product_type: product
  });
}

export function analyticGTMSelectLogin(product: string) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_CREATE_LOGIN,
    product_type: product
  });
}

export function analyticGTMBilling(
  product: string,
  step: number,
  slug: string
) {
  sendNotaryProductGTMData({
    event: NotarialGTMEvent.SELECT_BILLING,
    product_type: product,
    step: step,
    product_slug: slug as NotaryProductSlug
  });
}

export async function analyticGTMCheckout(
  product: string,
  step: number,
  slug: string,
  paymentUuid: string
) {
  const response = await ApiService.get<EcommerceDataLayer>(
    RESOURCES.GTM_PAYMENT_DATA(paymentUuid)
  );

  const ecommerce = response.data.ecommerce;

  sendEcommerceGTMData({
    event: NotarialGTMEvent.SELECT_CHECKOUT_ECOMMERCE,
    ecommerce: ecommerce,
    product_type: product,
    product_category: 'service',
    step: step,
    product_slug: slug as NotaryProductSlug
  });
}
