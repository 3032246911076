import { usePageInformation } from '@ligo/shared/utils';

export const CalculatorPageInformation = usePageInformation('calculator');
export const ContractUpsellPageInformation = usePageInformation(
  'contract-upsell-step'
);
export const HoldingUpsellPageInformation = usePageInformation(
  'holding-upsell-step'
);
export const DGAPageInformation = usePageInformation('dga-step');
export const PreviewDocPageInformation = usePageInformation(
  'preview-documents'
);
export const ShareStepPageInformation = usePageInformation('share-step');

export const BvLayoutPageInformation = usePageInformation('Bv-Layout');
