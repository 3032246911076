import { ApiService } from '@ligo/shared/utils';
import Vue from 'vue';
import { ref, Ref } from '@vue/composition-api';
import { RESOURCES, NotaryProduct, ProductForm } from '@ligo/bv-flow/store';

const ENABLED_ONLINE = false;

const LOCALE = {
  EN: 'en-us',
  NL: 'nl'
};

export interface NotaryAPI {
  id: string;
  uuid: string;
  firstname: string;
  lastname: string;
  firm: string;
  street: string;
  house_number: string;
  zip: string;
  city: string;
  allow_video_calls: boolean;
  dutch_information?: string;
  english_information?: string;
}

const splitNotaries = (notaries: NotaryAPI[], columns: number) => {
  return notaries.reduce((splitted, currentNotary, index) => {
    const newIndex = index % columns;
    if (newIndex > splitted.length - 1) splitted.push([]);
    splitted[newIndex].push(currentNotary);
    return splitted;
  }, [] as NotaryAPI[][]);
};

export const fields = [['online_notary', 'notary_id']];
export const useNotaryForm = (notary_product: NotaryProduct) => {
  const form = ref(new ProductForm('main', fields, notary_product));
  return { form };
};

export const buildLabel = (notary: NotaryAPI) => {
  return notary.city;
};

export const useNotariesTools = (slug: string) => {
  const notaries = ref() as Ref<NotaryAPI[][]>;
  const loading = ref(false);

  const getNotaryFromChoice = (choice: string) => {
    const notary_index = choice.slice(3).split('|');
    if (notaries.value)
      return notaries.value[parseInt(notary_index[1])][
        parseInt(notary_index[0])
      ];
  };

  const requestNotaries = async (slug: string) => {
    const response = await ApiService.get<Array<NotaryAPI>>(
      RESOURCES.NOTARIES + slug
    );
    console.log('RESPONSE', response);
    notaries.value = splitNotaries(response.data, 2);
  };

  const checkPredefinedNotary = async (notary_product: NotaryProduct) => {
    const response = await ApiService.get<NotaryAPI>(
      `${RESOURCES.BASE}${notary_product.url}${notary_product.uuid}${RESOURCES.PREDEFINED_NOTARY}`
    );
    console.log('Notary Assignment', response);
    if(response.data?.id){
      notary_product.resources['main'].updateFromJSON({notary_id: response.data.id});
      await notary_product.save(true);
      return true
    } else{
      return false
    }
  };

  const assignNotary = (
    online_notary: boolean,
    notary_choice: string,
    notary_product: NotaryProduct
  ) => {
    loading.value = true;
    const payload = {
      online_notary,
      notary_id: ''
    };
    if (!online_notary) {
      const notary = getNotaryFromChoice(notary_choice);
      payload['notary_id'] = notary.id;
    }
    console.log('PAYLOAD', payload);
    notary_product.resources['main'].updateFromJSON(payload);
    return notary_product.save(true);
  };

  const hasInformation = (notary: NotaryAPI) => {
    return notary.dutch_information || notary.english_information;
  };

  const getInformation = (notary: NotaryAPI) => {
    if (Vue['i18n'].locale !== LOCALE.EN) {
      return notary.dutch_information;
    }
    return notary.english_information;
  };

  const initializeData = async (notary_product: NotaryProduct) => {
    const fields = notary_product.resources['main'].values;
    if (!fields.online_notary) {
      await requestNotaries(notary_product.slug);
    }
    return {
      notary_choice: findNotaryIndex(fields.notary_id)
    };
  };

  const getInitialOnlineChoice = (notary_product: NotaryProduct) => {
    const fields = notary_product.resources['main'].values;
    return fields.online_notary;
  };

  const findNotaryIndex = (notary_id: string) => {
    if (notaries.value) {
      for (let i = 0; i < notaries.value.length; i++) {
        const result = notaries.value[i].findIndex(
          (notary) => notary.id == notary_id
        );
        if (result > 0) {
          return `not${result}|${i}`;
        }
      }
    }
    return 'not0|0';
  };

  if (!ENABLED_ONLINE) requestNotaries(slug);

  return {
    requestNotaries,
    assignNotary,
    notaries,
    getNotaryFromChoice,
    hasInformation,
    getInformation,
    initializeData,
    getInitialOnlineChoice,
    ENABLED_ONLINE,
    loading,
    checkPredefinedNotary
  };
};
