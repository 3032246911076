









































import { defineComponent, PropType, computed } from '@vue/composition-api';
import { SideMenuOption } from '@ligo/bv-flow/store';

export default defineComponent({
  name: '',
  components: {},
  props: {
    backgroundColor: {
      type: String,
      default: 'aqua'
    },
    optionsRef: {
      type: (Array as unknown) as PropType<Array<SideMenuOption>>,
      required: true
    },
    tab: {
      type: Number,
      required: true
    }
  },
  setup(props, { emit }) {
    const changeOption = (i: number) => {
      emit('change-option', i);
    };
    const curTab = computed({
      get: () => props.tab,
      set: () => 0
    });
    return { curTab, props, changeOption };
  }
});
