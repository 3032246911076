




















import { defineComponent } from '@vue/composition-api';
import { bannerText } from './instruction-banner-models';

export default defineComponent({
  name: 'InstructionBanner',
  components: {},
  props: {
    title: {
      type: Object as () => bannerText,
      default: () => ({ dark: 'This is a', light: 'colored', append: 'text' })
    },
    subtitle: {
      type: String,
      default: ''
    },
    position: {
      type: String,
      validator: (val: string) => ['left', 'center', 'right'].includes(val),
      default: 'left'
    },
    usei18n: {
      type: Boolean,
      default: true
    }
  },
  setup(props) {
    return { props };
  }
});
