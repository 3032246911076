const date = new Date();
const this_year = `31-12-${date.getFullYear().toString()}`;
const next_year = `31-12-${(date.getFullYear() + 1).toString()}`;

export default {
  labels: {
    company_name: 'Naam stichting',
    email: 'Emailadres contactpersoon',
    headquarters: 'Statutaire zetel',
    office_address: 'Adres',
    place_of_business: '',
    postal_code: '',
    industry: 'In welke sector zal je Stichting actief zijn? ',
    activities: 'Beschrijf het doel van de stichting',
    sell_products:
      'Verkoopt de stichting aan consumenten of aan ondernemingen?',
    where_are_sold:
      'Waar worden de producten verkocht? (Meerdere opties mogelijk)',
    export_products: 'Exporteert de stichting producten?',
    import_products: 'Importeert de stichting producten?',
    number_of_shares: 'Totaal aantal aandelen',
    value_of_shares: 'Wat wordt de waarde van een aandeel?',
    financial_year_end: 'Wanneer eindigt het eerste boekjaar van de stichting?',
    full_time_work:
      'Hoeveel mensen werken full-time (15 uur of meer per week) bij de stichting?',
    part_time_work:
      'Hoeveel mensen werken part-time (minder dan 15 uur per week) bij de stichting?',
    employees_loan: 'Is er sprake van het incidenteel uitlenen van werknemers?',
    representation:
      'Op welke manier kan de bestuurder de Stichting vertegenwoordigen?',
    custom_notary: 'Notaris die de legalisatie zal verzorgen',
    custom_notary_city: 'Jouw gebied',
    realizing_activities:
      'Beschrijf op welke manier het doel van de stichting bereikt zal worden:',
    president:
      'Volledige naam van de persoon die als voorzitter wordt benoemd:',
    secretary:
      'Volledige naam van de persoon die als secretaris wordt benoemd:',
    treasurer:
      'Volledige naam van de persoon die als penningmeester wordt benoemd:',
    extra_shareholders: 'Wil je een vierde bestuurder noemen?',
    remaining_roles:
      'Geef hier de volledige naam of namen op van de persoon die als overige leden worden benoemd:',
    term_managing: 'Voor welke termijn wordt een bestuurder benoemd?',
    reappointed:
      'Geef hier aan hoeveel keer een bestuurder hernoemd kan worden:',
    lead_approval:
      'Wil je gratis en vrijblijvend 3 offertes te ontvangen van door ons geselecteerde boekhouders uit uw regio?'
  },
  placeholders: {
    industry: 'Type bedrijfstak invoeren.',
    email: 'Emailadres contactpersoon',
    president: '',
    secretary: '',
    treasurer: '',
    extra_shareholders: '',
    remaining_roles: '',
    term_managing: '',
    reappointed: '',
    headquarters: 'Vul de plaats in waar het hoofdkantoor is gevestigd',
    office_address: 'Vul de straatnaam en het huisnummer in',
    place_of_business:
      'Vul de plaats in waar het fysieke kantoor is gevestigd ',
    company_name: 'Vul de naam van je stichting in',
    holding_name: 'Type here your holding name',
    postal_code: 'Vul de postcode in',
    activities: 'Beschrijf de activiteiten van de onderneming....',
    sell_products: 'Kies uit de lijst',
    where_are_sold: 'Kies uit de lijst',
    value_of_shares: 'Vul een waarde in',
    enter_number: 'Voer nummer in',
    financial_year_end: '',
    full_time_work: 'Voer hier het aantal in',
    part_time_work: 'Voer hier het aantal in',
    number_of_shares: 'Totaal aantal aandelen',
    shares: 'Voer nummer in',
    custom_notary:
      'Vermeld hier de naam, plaats en telefoonnummer van de notaris die de legalisatie zal uitvoeren:',
    custom_notary_city:
      'Wat is de plaats waar je graag bij een notaris langs wil gaan?',
    realizing_activities: ''
  },
  options: {
    industry: {
      business_services: 'Zakelijke dienstverlening',
      construction: 'Bouw',
      health: 'Gezondheid',
      retail: 'Detailhandel',
      culture_sport_recreation: 'Cultuur, sport en recreatie',
      ict_media: 'ICT en Media',
      wholesale: 'Groothandel',
      agriculture: 'Landbouw',
      manufacturing: 'Maakindustrie',
      logistics: 'Logistiek',
      hospitality: 'Horeca',
      financial_services: 'Financiële diensten',
      energy_water_environment: 'Energie, water en milieu',
      other: 'Overig'
    },
    sell_products: {
      consumers: 'Aan consumenten',
      companies: 'Aan ondernemingen',
      none: 'Niet van toepassing',
      consumers_and_businesses: 'Aan zowel consumenten als ondernemingen'
    },
    where_are_sold: {
      store_or_kiosk: 'In een winkel of kiosk',
      market: 'Op de markt',
      street_trade: 'Via straathandel',
      internet: 'Via internet',
      from_home: 'Vanuit huis',
      postorder: 'Per postorder',
      not_applicable: 'Niet van toepassing'
    },
    representation: {
      one_signature:
        'De bestuurder is zelfstandig (alleen) vertegenwoordigingsbevoegd',
      two_signatures:
        'De bestuurder is samen met een mede-bestuurder vertegenwoordigingsbevoegd'
    },
    financial_year_end: {
      this_year: this_year,
      next_year: next_year
    }
  },
  label_tooltip: {
    industry:
      'Als jouw sector er niet bij staat, kies je voor “overige”. We vragen later in het proces wat de precieze activiteiten zijn. Een stichting derdengelden kan niet via deze vragenlijst opgericht worden. Neem hiervoor contact op met Ligo.',
    caregiver:
      'Actief in de zorg? Je hebt mogelijk specifieke “zorgstatuten” (WTZA) nodig. De notaris voert in dat geval ook extra controles uit. De extra kosten voor zorgstatuten zijn €999.',
    company_name:
      'Vul hier de volledige naam in, zoals “Stichting Ligo”. Let op: vergeet niet ‘Stichting’ aan het begin of einde van de naam te zetten. Weet je niet zeker of je gekozen naam al bestaat? Controleer het via kvk.nl/zoeken. Zo voorkom je problemen met ondernemingen met een gelijke naam. Als je nog niet zeker weet wat de naam van je stichting wordt, geen probleem. Je kan de naam van je stichting later in het proces nog aanpassen',
    headquarters:
      "De statutaire zetel is vaak hetzelfde als de vestigingsplaats van de stichting en bepaalt welke rechter bevoegd is bij een geschil. De zetel wordt ook wel de formele 'woonplaats' van de stichting genoemd en zal worden opgenomen in je statuten.",
    office_address:
      'Weet je nog niet waar je de stichting wil vestigen? Registreer de stichting dan op je woonadres. Dit scheelt papierwerk en tijd. De notaris heeft dan geen huurovereenkomst nodig om je stichting op te richten. Je kan het adres na oprichting nog kosteloos en eenvoudig zelf wijzigen via de KVK. <br>Let op: het adres van de stichting moet een fysiek Nederlands adres zijn en kan geen virtueel kantooradres of briefadres zijn.',
    activities:
      'Een stichting heeft vaak een ideëel of maatschappelijk doel. Denk bijvoorbeeld aan een goed doel of een kunstinstelling.',
    realizing_activities:
      'Uit je doelomschrijving moet duidelijk zijn wat je stichting wil bereiken, in welk gebied de stichting zich zal inzetten en waarom de stichting zich inzet voor dat specifieke doel.',
    president:
      'Om de stichting op te richten heb je minimaal 3 bestuurders nodig voor de verschillende rollen. Hiermee voorkom je belangenverstrengeling. Heb je vragen? Bel ons gerust via 020 303 1043.',
    term_managing:
      'Geef het aantal jaren aan, waarvoor de bestuurder benoemd wordt. Vaak wordt er gekozen voor een periode van 4 jaar.',
    reappointed:
      'Vul het getal in van het aantal maal dat een bestuurder hernoemd kan worden. Bij de meeste stichtingen kan een bestuurder één keer hernoemd kan worden.',
    financial_year_end:
      'De meeste oprichters kiezen ervoor om het eerste boekjaar aan het eind van het volgende jaar te laten aflopen om tijd en kosten te besparen.',
    full_time_work:
      'Deze informatie is nodig om de stichting in te schrijven bij de KvK. Heb je later meer fulltime medewerkers? Geen zorgen, dat kan je later eenvoudig kosteloos wijzigen bij de KvK.',
    part_time_work:
      'Deze informatie is nodig om de stichting in te schrijven bij de KvK. Heb je later meer parttime medewerkers? Geen zorgen, dat kan je later eenvoudig kosteloos wijzigen bij de KvK.',
    employees_loan:
      'Stel je werknemers ter beschikking aan anderen of leen je ze uit tegen betaling? En hoort dit niet tot de activiteiten van je stichting? De KvK wil dit dan graag van je weten, zodat er duidelijkheid is over of deze werknemers onder jou of iemand anders werken.',
    representation:
      "Als je vindt dat elke bestuurder in zijn eentje de stichting mag vertegenwoordigen, kies dan voor 'alleen'. Vind je juist dat er altijd twee bestuurders nodig moeten zijn om bijvoorbeeld een bankrekening namens de BV te openen of een overeenkomst te sluiten? Kies dan voor 'samen'. Sommige ondernemers vinden verplicht 'samen' een fijne gedachte, anderen vinden het weer onnodig."
  }
};
