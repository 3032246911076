




























































































































































































import { computed, defineComponent } from '@vue/composition-api';
import { Screen } from 'quasar';
import { ItemComparison } from './models/ProsCons';
import BvBadge from '../base/BvBadge.vue';
import { useHoldingUpsell } from '../handlers/shared-steps/HoldingUpsell/holding_upsell.hooks';
import { CalculatorResource } from '@ligo/bv-flow/store';
import { PropType } from 'vue';
const BADGE_ICON = 'img:/questionnaire/icons/badge-percent.svg';
const ICON_SIZE = '20px';
export default defineComponent({
  name: 'ProsConsDialog',
  components: { BvBadge },
  props: {
    calculator: {
      type: Object as PropType<CalculatorResource>
    },
    i18nKey: {
      type: String,
      required: true
    },
    openDialog: {
      type: Boolean,
      required: true
    }
  },
  setup(props, { root }) {
    const { prices } = useHoldingUpsell(
      props.calculator.getNotaryProductTypeSlug()
    );

    const comparisons = root.$calct(
      `${props.i18nKey}.comparisons`
    ) as ItemComparison[];
    const cardWidth = computed(() => {
      if (Screen.gt.sm) return 1080;
      if (Screen.sm) return 700;
      return 900;
    });
    return {
      comparisons,
      cardWidth,
      BADGE_ICON,
      ICON_SIZE,
      prices
    };
  }
});
