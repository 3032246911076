export default {
  labels: {
    prefix: 'Aanhef',
    firstname: 'Voornamen zoals in paspoort',
    lastname: 'Achternaam',
    birthdate: 'Geboortedatum',
    birthplace: 'Geboorteplaats',
    country_of_birth: 'Geboorteland',
    residence_country: 'Land',
    company_country: 'Land',
    email: 'E-mailadres',
    phone_number: 'Telefoonnummer',
    address: 'Woonadres',
    company_name: 'Bedrijfsnaam',
    founder_address: 'What is the shareholder’s address?',
    zip_code: '',
    location: '',
    chamber: 'KvK-nummer',
    director_authorized:
      'Is de vertegenwoordigingsbevoegde bestuurder van deze aandeelhouder een onderneming?',
    representation_type:
      ' Deze persoon mag de nieuwe holding vertegenwoordigen als:',
    document_type: 'Soort legitimatiebewijs',
    nationality: 'Nationaliteit',
    document_number: 'Documentnummer',
    issue_date: 'Datum van uitgifte',
    expiration_date: 'Geldig tot',
    place_of_issue: 'Plaats van uitgifte',
    bsn: 'BSN nummer',
    natural_person_lives_in_netherlands:
      'Woont deze aandeelhouder in Nederland?',
    natural_person_english_proficient:
      'Is deze aandeelhouder de Nederlandse taal machtig?',
    lives_in_netherlands:
      'Woont de persoon die namens de onderneming optreedt in Nederland?',
    english_proficient:
      'Is de persoon die namens de onderneming optreedt de Nederlandse taal machtig?',
    become_director: 'Wordt de onderneming bestuurder van de op te richten BV?',
    marital_status: 'Burgerlijke staat',
    has_proprietorship:
      'Heeft deze aandeelhouder een actieve eenmanszaak / VOF?',
    kvk_proprietorship:
      'Wat is het KVK-nummer van de eenmanszaak / VOF van deze aandeelhouder?',
    function: 'Wat is de functie van deze persoon?',
    company_location: '',
    company_address: 'Adres',
    company_zipcode: '',
    representative_birthdate: 'Geboortedatum',
    representative_birthplace: 'Geboorteplaats',
    natural_person_director:
      'Wordt deze aandeelhouder bestuurder van de op te richten onderneming?',
    incorporated_in_netherlands: 'Is de aandeelhouder opgericht in Nederland?',
    ubos: 'Hoeveel UBO’s heeft deze onderneming?',
    ubo_kvk_registered: 'Heb je de UBO registratie bij de KVK al gedaan?',
    proprietorship_action:
      'Wat zal deze aandeelhouder doen met de VOF/eenmanszaak? '
  },
  checkboxes: {
    ubo_kvk_registered_check:
      'Ik begrijp dat de notaris de BV niet mag oprichten voordat de UBO‘s zijn opgegeven bij de KVK.',
    bsn_not_present: 'Vink als deze aandeelhouder geen Nederlands BSN heeft',
    address_equals_company_address: 'Gebruik hetzelfde adres als hierboven'
  },
  placeholders: {
    prefix: 'Titel',
    firstname: 'Officiële voornamen',
    lastname: 'Achternaam',
    email: 'Vul het e-mailadres in',
    birthdate: 'YYYY-MM-DD',
    birthplace: 'Geboorteplaats',
    address: 'Vul straat en huisnummer in',
    marital_status: '',
    has_proprietorship: '',
    kvk_proprietorship: '',
    company_name: 'Vul de naam van de holding in',
    zip_code: 'Vul de postcode in',
    location: 'Vul de plaatsnaam in',
    chamber: 'Vul het KvK-nummer in',
    holding_name: 'Naam van de holding',
    nationality: 'Kies uit de lijst',
    document_number: 'Documentnummer',
    place_of_issue: 'Plaats van uitgifte',
    bsn: 'BSN nummer',
    issue_date: 'YYYY-MM-DD',
    expiration_date: 'YYYY-MM-DD',
    shares: 'Vul een aantal in',
    company_location:
      'Vul de plaatsnaam waar het fysieke kantoor is gevestigd in',
    company_address: 'Vul de straatnaam en het huisnummer in',
    company_zipcode: 'Vul de postcode in'
  },
  options: {
    proprietorship_action: {
      keep: 'De eenmanszaak / VOF wordt voortgezet naast de BV',
      transfer: 'De eenmanszaak / VOF wordt omgezet naar een BV',
      close: 'De eenmanszaak / VOF wordt beïndigd vóór oprichting van de BV'
    },
    document_type: {
      passport: 'Paspoort',
      driver_licence: 'Rijbewijs',
      identity_card: 'Identiteitskaart'
    },
    marital_status: {
      unmarried: 'Ongehuwd en geen geregistreerd partnerschap',
      registered_partner: 'Geregistreerd partnerschap',
      married: 'Gehuwd'
    },
    representation_type: {
      independently_authorized_director: 'Zelfstandig bevoegd bestuurder',
      jointly_authorized_director: 'Gezamenlijk bevoegd bestuurder'
    },
    nationality: {
      dutch: 'Nederlands',
      belgian: 'Belgisch',
      french: 'Frans',
      english: 'Brits'
    },
    prefix: {
      Mr: 'Dhr',
      Mrs: 'Mevr'
    },
    function: {
      authorized_director: 'Schriftelijk gevolmachtigde',
      authorized_representative: 'Vertegenwoordigingsbevoegde bestuurder'
    }
  },
  label_tooltip: {
    firstname:
      'Namen in akten moeten identiek zijn aan de gegevens op je paspoort of ID kaart. Vul bijvoorbeeld ook je tweede en derde voornaam in, indien van toepassing.',
    birthdate:
      'Ben jij jonger dan 18 jaar en wil jij je eigen bedrijf starten? Neem dan eerst contact op met Ligo om de aanvullende voorwaarden te bespreken.',
    marital_status:
      'Zorg dat je de burgerlijke staat invult, zoals deze officieel is ingeschreven in het BRP',
    nationality:
      'Kan jij je nationaliteit niet vinden in deze lijst? Dit kan het geval zijn als je nationaliteit voorkomt op de lijst met high-risk landen van de Europese Commissie. Neem dan contact op met Ligo. Wij helpen je graag verder.',
    expiration_date:
      'Hou er rekening mee dat het gekozen identiteitsdocument geldig moet zijn op het moment dat jij je identificeert bij de notaris.',
    lives_in_netherlands:
      'Als je in het buitenland woont, is het mogelijk om je BV bij Ligo op te richten. De notaris heeft dan een bankafschrift of energierekening met jouw naam en adres erop nodig. Hiermee kan de notaris jouw adres controleren.',
    english_proficient:
      'Alle aandeelhouders moeten Nederlands spreken, lezen en verstaan. Is dit niet zo, moet de oprichting in het Engels worden gedaan.',
    ubos:
      'UBO staat voor ‘Ultimate Beneficial Owner’, ook wel de uiteindelijk belanghebbende. De UBO is de persoon die het voor het zeggen heeft bij een onderneming. Als je 25% of meer van de aandelen hebt, ben je eigenaar van de onderneming en ben je de UBO. Het maakt niet uit of er een holding is geplaatst tussen jou en een werk-BV. Als eigenaar van de holding ben je namelijk ook gewoon de eigenaar van de werk-BV. Hebben meerdere mensen 25% of meer van de aandelen? Dan heeft de onderneming meerdere UBO’s.',
    proprietorship_action_banners: {
      keep:
        'Verander de naam van de eenmanszaak / VOF via de KVK vóór oprichting van de BV, of verander de naam van de nieuwe BV. De BV kan niet opgericht worden met dezelfde naam als de eenmanszaak / VOF.',
      transfer:
        'Als je de eenmanszaak / VOF wilt omzetten, start je hier de specifieke vragenlijst voor omzetting naar een BV',
      close:
        "Beëindiging van je eenmanszaak of VOF regel je zelf <a href='https://www.kvk.nl/wijzigen/opheffen-rpio/?step=eenmanszaak-info' target='_blank'>hier</a> gemakkelijk online"
    },
    representation_type:
      'Als je voor jezelf een holding opricht, ben je zelfstandig bevoegd. Zijn er meerdere bestuurders? Dan kan iedere bestuurder zelfstandig bevoegd zijn, of samen gezamenlijk bevoegd. Je moet dan beide tekenen om de holding te binden. Denk aan het openen van een bankrekening of een overeenkomst sluiten.'
  }
};
