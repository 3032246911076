export default {
  title: 'Factuurgegevens',
  subtitle: 'Vul hieronder de factuurgegevens in',
  bankruptcy_question:
    'Is een van de bestuurders of oprichters de afgelopen 4 jaar direct of indirect betrokken geweest bij een faillissement?',
  bankruptcy_settled: 'Is dit faillissement afgerond?',
  remove_shareholder:
    'Deze oprichter kan geen onderdeel vormen van deze oprichting. Je faillissement moet afgerond zijn om je B.V. te kunnen oprichten via Ligo',
  go_back_to_shareholders: 'Ga terug naar de oprichters'
};
