























































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import {
  CalculatorFieldOptions,
  CalculatorOptionModel
} from '@ligo/bv-flow/store';
import { getI18n } from '@ligo/bv-flow/store';

const CHECK_ICON = 'img:/questionnaire/icons/radio_check.svg';
const UNCHECK_ICON = 'img:/questionnaire/icons/radio_uncheck.svg';

export default defineComponent({
  name: 'CalculatorOptionField',
  props: {
    field: {
      type: Object as PropType<CalculatorFieldOptions>,
      require: true
    },
    rules: {
      type: Array,
      default: () => []
    }
  },
  setup(props, { emit }) {
    const emitValue = (value) => {
      emit('on-answer', value);
    };
    const cardStyle = computed(() => {
      return {
        minWidth: '81px',
        minHeight: '51px',
        ...(props.field.optionsConfig?.styles || {})
      };
    });

    const options = computed<CalculatorOptionModel[]>(() => {
      return getI18n(props.field, 'options').value.filter((item) => {
        return (
          !props.field.optionKeys ||
          props.field.boolean ||
          props.field.optionKeys.includes(item.value)
        );
      });
    });

    const values = ref(props.field.value || []);

    function update(value: any) {
      if (props.field.multiple) {
        if (values.value.includes(value)) {
          const i = values.value.findIndex((item) => item == value);
          values.value.splice(i, 1);
        } else values.value.push(value);
      } else {
        emitValue(value);
      }
    }

    function active(option) {
      if (props.field.multiple && values) {
        return values.value.includes(option.value);
      } else {
        return (
          option.value == props.field.value ||
          (props.field.boolean &&
            ((props.field.value === true && option.value == 'yes') ||
              (props.field.value === false && option.value == 'no')))
        );
      }
    }

    function getIcon(option) {
      if (!props.field.boolean && option.icon) return option.icon;
      else {
        return active(option) ? CHECK_ICON : UNCHECK_ICON;
      }
    }

    return {
      emitValue,
      values,
      cardStyle,
      options,
      active,
      update,
      getIcon
    };
  }
});
