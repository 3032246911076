export default {
  name: 'Kopen en Verkoper',
  title: 'Wie verkoopt en koopt de aandelen van',
  description:
    'Vul hier de gegevens van de verkoper en koper in. Controleer of je de gegevens compleet en juist hebt ingevuld. Zo kunnen wij jou zo snel mogelijk van dienst zijn.',
  opt1: 'Basisgegevens',
  opt2: 'Gegevens verkoper',
  opt3: 'Identificatie (ID)',
  opt4: 'Overige vragen',
  shareholder: 'Aandeelhouder',
  complete_shareholder:
    'Je dient alle gegevens van partijen in te vullen voordat je door kan naar de volgende stap',
  type_title_1: 'Verkoper is een:',
  type_title_2: 'Koper is een:',
  type_message: '',
  main_person:
    'Wat is de volledige naam van de persoon die namens de onderneming mag optreden?',
  cant_incorporate:
    'In dit geval is het helaas niet mogelijk om via Ligo aandelen over te dragen.',
  other_services: 'Bekijk andere services'
};
