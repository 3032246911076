var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(!_vm.loading)?_c('div',{staticClass:"bv-flow-card bg-surface-white border-radius-4 q-my-md"},[_c('instruction-banner',{staticClass:"q-pt-lg q-px-lg",attrs:{"title":_vm.title,"subtitle":_vm.subtitle}}),_c('q-separator',{staticClass:"q-mt-sm"}),_c('share-holder-group',{ref:"shareholders",staticClass:"q-pb-sm q-pt-sm q-px-md",attrs:{"active":_vm.activeIndexes,"add-tag":_vm.addTag,"card-tag":_vm.cardTag,"card-tags-list":_vm.cardTagsList,"enable-add":_vm.enableAdd,"enable-deletable":_vm.enableDeletable,"single-card-tag":_vm.singleCardTag,"minimum-shareholders-amount":_vm.minimumActives},on:{"selectedShareholder":_vm.selectedShareholder,"createdShareholder":_vm.createdShareholder,"deletedShareholder":_vm.deletedShareholder}}),_c('q-separator'),_c('div',{staticClass:"col-10 col-sm-10"},[_c('div',{staticClass:"row"},[_vm._l((_vm.shareholderLen),function(j){return _c('div',{key:j,class:{
            'col-12 q-pt-md q-pb-lg q-pl-lg col-sm-5 col-md-4': j - 1 == _vm.index
          }},[(j - 1 == _vm.index)?_c('side-menu',{ref:("sideMenu" + j),refInFor:true,class:("" + (j - 1 == _vm.index ? 'q-mb-lg' : 'q-mb-xl')),attrs:{"color":"teal-14","background-color":"transparent","options":_vm.sideOptions,"index":_vm.shareholdersList[_vm.index].stepSideBar},on:{"change-option":_vm.changeOption}}):_vm._e()],1)}),_c('q-separator',{attrs:{"vertical":""}}),_c('div',{staticClass:"col-12 col-md q-pa-lg"},[(_vm.shareholdersList[_vm.index].stepSideBar == 0)?_c('div',{staticClass:"q-mb-md"},[(_vm.showTypes)?_c('type-list',{ref:"typeList",attrs:{"index":_vm.getType,"types-subset":_vm.typesSubset,"types-custom-labels":_vm.typesCustomLabels,"show-types-message":_vm.showTypesMessage,"label":_vm.typeTitle,"locale":_vm.productLocale(_vm.notary_product, 'shareholder_information.')},on:{"selectedType":_vm.selectedType}}):_vm._e()],1):_vm._e(),_c('div',[_c('base-form',{ref:"form",attrs:{"form":_vm.getForm,"save":""},on:{"submit":_vm.next,"previous-step":function($event){return _vm.goBack()}},scopedSlots:_vm._u([(
                  _vm.getForm.fields.proprietorship_action &&
                  !!_vm.getForm.fields.proprietorship_action.value &&
                  _vm.getForm.fields.proprietorship_action.value != 'transfer'
                )?{key:"field-bottom-proprietorship_action",fn:function(){return [_c('bv-alert-banner',{staticClass:"q-mb-md",attrs:{"icon-color":"subdued","icon-src":'info',"card-class":"bg-surface-default border-border-default"}},[_c('span',{domProps:{"innerHTML":_vm._s(
                      _vm.$t(
                        _vm.productLocale(
                          _vm.notary_product,
                          ("shareholder.label_tooltip.proprietorship_action_banners." + (_vm.getForm.fields.proprietorship_action.value))
                        )
                      )
                    )}})])]},proxy:true}:null],null,true)}),(
                _vm.getForm.fields.proprietorship_action &&
                _vm.getForm.fields.proprietorship_action.value
              )?_c('bv-close-dialog',{attrs:{"value":_vm.getForm.fields.proprietorship_action.value == 'transfer',"title":_vm.productLocale(
                  _vm.notary_product,
                  'shareholder.label_tooltip.proprietorship_action_banners.transfer'
                ),"label-confirm":"continue","hide-cancel":""},on:{"input":function($event){_vm.getForm.fields.proprietorship_action.value = null},"confirm":function($event){return _vm.$emit('dialog-transfer')}}}):_vm._e(),(
                _vm.getForm.fields.lives_in_netherlands &&
                _vm.notary_product.slug === _vm.NotaryProductSlug.TRANSFER_OF_SHARES
              )?_c('bv-close-dialog',{attrs:{"value":_vm.getForm.fields.lives_in_netherlands.value == false,"title":_vm.productLocale(
                  _vm.notary_product,
                  'shareholder_information.cant_incorporate'
                ),"label-confirm":_vm.productLocale(
                  _vm.notary_product,
                  'shareholder_information.other_services'
                ),"hide-cancel":""},on:{"input":function($event){_vm.getForm.fields.lives_in_netherlands.value = null},"confirm":function($event){return _vm.$emit('dialog-refuse')}}}):_vm._e(),(_vm.getForm.fields.english_proficient)?_c('bv-close-dialog',{attrs:{"value":_vm.getForm.fields.english_proficient.value == false,"title":_vm.productLocale(
                  _vm.notary_product,
                  'shareholder_information.cant_incorporate'
                ),"label-confirm":_vm.productLocale(
                  _vm.notary_product,
                  'shareholder_information.other_services'
                ),"hide-cancel":""},on:{"input":function($event){_vm.getForm.fields.english_proficient.value = null},"confirm":function($event){return _vm.$emit('dialog-refuse')}}}):_vm._e(),(_vm.dialog.state)?_c('bv-close-dialog',{attrs:{"title":_vm.dialog.title,"label-confirm":_vm.dialog.label,"hide-cancel":""},on:{"confirm":function($event){_vm.dialog.state = false}},model:{value:(_vm.dialog.state),callback:function ($$v) {_vm.$set(_vm.dialog, "state", $$v)},expression:"dialog.state"}}):_vm._e()],1)])],2)])],1):_vm._e(),_c('q-inner-loading',{attrs:{"showing":_vm.loading}},[_c('q-spinner-tail',{attrs:{"size":_vm.SPINNER_SIZE,"color":"primary"}})],1),(!_vm.loading)?_c('new-contact-card',{staticClass:"q-mb-md"}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }