
















































import { computed, defineComponent } from '@vue/composition-api';
import { PropType } from 'vue';
import { DiscountDescription } from '@ligo/bv-flow/store';
import BvBadge from '../../components/base/BvBadge.vue';

const INFO_ICON = 'img:/questionnaire/icons/circle-info.svg';
const BADGE_ICON = 'img:/questionnaire/icons/badge-percent.svg';
const ICON_SIZE = '20px';

export default defineComponent({
  name: 'BillingCard',
  components: { BvBadge },
  props: {
    title: {
      type: String
    },
    price: {
      type: [String, Number],
      default: 0
    },
    amount: { type: Number },
    subtitle: {
      type: String
    },
    discountValue: Object as PropType<DiscountDescription>,
    contentClass: {
      type: String,
      default: 'font-body text-default q-pb-md'
    },
    priceClass: {
      type: String,
      default: 'font-body'
    },
    subtitleAsTooltip: {
      type: Boolean,
      default: false
    },
    free: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { root }) {
    function formatLabel() {
      return props.amount > 1 ? `${props.amount}x ${props.title}` : props.title;
    }
    function toDiscount(price: string | number) {
      return (parseFloat(price.toString()) / 100).toFixed(0);
    }

    function toPrice(price: string | number, toAdd: string | number = 0) {
      if (props.free) return root.$t('free');
      else {
        const toAddRounded = (parseFloat(toAdd.toString()) / 100).toFixed(0);
        const toPriceParse = parseFloat(price.toString()) / 100;
        return (toPriceParse + parseFloat(toAddRounded.toString())).toFixed(2);
      }
    }
    const discount = computed(() => !!props.discountValue);
    return {
      formatLabel,
      toPrice,
      discount,
      toDiscount,
      INFO_ICON,
      BADGE_ICON,
      ICON_SIZE
    };
  }
});
