
































import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'Rating',
  components: {},
  props: {
    rating: {
      type: Number,
      default: 4.5
    },
    displayed: {
      type: String
    },
    text: {
      type: String,
      default: ''
    },
    sizeImg: {
      type: String
    },
    svg: {
      type: String,
      default: '/questionnaire/images/like.svg'
    }
  },
  setup() {
    return {};
  }
});
