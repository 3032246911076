export default {
  name: 'aandeelhouders',
  title: 'Wie worden de aandeelhouders van',
  description:
    'Vul hier de gegevens in van alle aandeelhouders. Controleer of je de gegevens compleet en juist hebt ingevuld. Zo kunnen wij jou zo snel en goed mogelijk van dienst zijn.',
  opt1: 'Gegevens BV',
  opt2: 'Gegevens aandeelhouder(s)',
  opt3: 'Identificatie (ID)',
  opt4: 'Overige vragen',
  shareholder: 'Aandeelhouder',
  complete_shareholder:
    ' Je dient alle gegevens van de aandeelhouders in te vullen om door te kunnen naar de volgende stap',
  type_title:
    'Richt deze aandeelhouder zelf op (als natuurlijk persoon), met een bestaande holding of met een nieuwe holding?',
  type_message:
    'Heeft u ook een persoonlijke holding nodig? Je kunt het opzetten voor € 310 in plaats van € 350.',
  cant_incorporate:
    'In dit geval is het helaas niet mogelijk om via ligo een BV op te richten.',
  other_services: 'Bekijk andere services',
  natural_person_tooltip:
    'De aandeelhouder van je BV kan een persoon, bestaande holding of nog op te richten holding zijn. Als het een persoon is, kies dan voor natuurlijk persoon. Richt je op met een bestaande holding of ga je samen met je BV een nieuwe holding oprichten? Kies dan voor bestaande holding of nog op te richten holding.'
};
