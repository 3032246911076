




































import { defineComponent, PropType } from '@vue/composition-api';
import { Intercom } from '@ligo/shared/utils';
import { AppLink } from '@ligo/shared/components';
import { CalculatorBasicField, getI18n } from '@ligo/bv-flow/store';

export default defineComponent({
  components: {
    AppLink
  },
  props: {
    field: {
      type: Object as PropType<CalculatorBasicField<any>>,
      required: true
    },
    fieldKey: String
  },
  setup(props) {
    function launchIntercom() {
      Intercom.identifyUser(null);
      Intercom.update();
      Intercom.wakeUp();
    }

    const label = getI18n(props.field, 'label');
    const subtitle = getI18n(props.field, 'subtitle');

    return { launchIntercom, label, subtitle };
  }
});
