export default {
  labels: {
    prefix: 'Aanhef',
    firstname: 'Voornamen zoals in paspoort',
    lastname: 'Achternaam',
    birthdate: 'Geboortedatum',
    birthplace: 'Geboorteplaats',
    country_of_birth: 'Geboorteland',
    residence_country: 'Land',
    email: 'E-mailadres',
    phone_number: 'Telefoonnummer',
    address: 'Woonadres',
    company_name: 'Bedrijfsnaam',
    founder_address: 'What is the shareholder’s address?',
    zip_code: '',
    location: '',
    chamber: 'KvK-nummer',
    director_authorized:
      'Wordt deze oprichter bestuurder van de op te richten stichting?',
    representation_type: 'Deze persoon mag de stichting vertegenwoordigen als:',
    document_type: 'Soort legitimatiebewijs',
    nationality: 'Nationaliteit',
    document_number: 'Documentnummer',
    issue_date: 'Datum van uitgifte',
    expiration_date: 'Geldig tot',
    place_of_issue: 'Plaats van uitgifte',
    bsn: 'BSN nummer',
    lives_in_netherlands: 'Woont deze oprichter in Nederland?',
    english_proficient: 'Is deze oprichter de Nederlandse taal machtig?',
    become_director:
      'Wordt deze oprichter ook bestuurder van de op te richten stichting? ',
    foundation_role: 'Wat is de rol van deze bestuurder?',
    marital_status: 'Wat is de burgerlijke staat van deze persoon?',
    has_proprietorship:
      'Heeft deze aandeelhouder een actieve eenmanszaak / VOF?',
    kvk_proprietorship:
      'Wat is het KVK-nummer van de eenmanszaak / VOF van deze aandeelhouder?',
    function: 'Wat is de functie van deze persoon?',
    company_location: '',
    company_address: 'Adres',
    company_zipcode: '',
    incorporated_in_netherlands: 'Is de oprichter opgericht in Nederland?'
  },
  checkboxes: {
    bsn_not_present: 'Vink aan als oprichter geen Nederlands BSN heeft',
    address_equals_company_address: 'Gebruik hetzelfde adres als hierboven'
  },
  placeholders: {
    prefix: 'Titel',
    firstname: 'Officiële voornamen',
    lastname: 'Achternaam',
    email: 'Vul het e-mailadres in',
    birthdate: 'YYYY-MM-DD',
    birthplace: 'Geboorteplaats',
    address: 'Vul straat en huisnummer in',
    marital_status: '',
    has_proprietorship: '',
    kvk_proprietorship: '',
    company_name: 'Vul de naam van de oprichter in',
    zip_code: 'Vul de postcode in',
    location: 'Vul de plaatsnaam in',
    chamber: 'Vul het KvK-nummer in',
    holding_name: 'Naam van de holding',
    nationality: 'Kies uit de lijst',
    document_number: 'Documentnummer',
    place_of_issue: 'Plaats van uitgifte',
    bsn: 'BSN nummer',
    issue_date: 'YYYY-MM-DD',
    expiration_date: 'YYYY-MM-DD',
    shares: 'Vul een aantal in',
    company_location:
      'Vul de plaatsnaam waar het fysieke kantoor is gevestigd in',
    company_address: 'Vul de straatnaam en het huisnummer in',
    company_zipcode: 'Vul de postcode in'
  },
  options: {
    document_type: {
      passport: 'Paspoort',
      driver_licence: 'Rijbewijs',
      identity_card: 'Identiteitskaart'
    },
    marital_status: {
      unmarried: 'Ongehuwd en geen geregistreerd partnerschap',
      registered_partner: 'Geregistreerd partnerschap',
      married: 'Gehuwd'
    },
    representation_type: {
      independently_authorized_director: 'Zelfstandig bevoegd bestuurder',
      jointly_authorized_director: 'Gezamenlijk bevoegd bestuurder'
    },
    nationality: {
      dutch: 'Nederlands',
      belgian: 'Belgisch',
      french: 'Frans',
      english: 'Brits'
    },
    prefix: {
      Mr: 'Dhr',
      Mrs: 'Mevr'
    },
    function: {
      authorized_director: 'Schriftelijk gevolmachtigde',
      authorized_representative: 'Vertegenwoordigingsbevoegde bestuurder'
    },
    foundation_role: {
      chairman: 'Voorzitter',
      secretary: 'Secretaris',
      treasurer: 'Penningmeester',
      other: 'Allen Bestuurder'
    }
  },
  label_tooltip: {
    firstname:
      'Namen in akten moeten identiek zijn aan de gegevens op je paspoort of ID kaart. Vul bijvoorbeeld ook je tweede en derde voornaam in, indien van toepassing.',
    birthdate:
      'Ben jij jonger dan 18 jaar en wil jij je eigen stichting starten? Neem dan eerst contact op met Ligo om de aanvullende voorwaarden te bespreken.',
    address:
      'Woon je in het buitenland? Geen probleem. De notaris heeft alleen een bewijs van jouw adres nodig.',
    marital_status:
      'Zorg dat je de burgerlijke staat invult, zoals deze officieel is ingeschreven in het BRP',
    nationality:
      'Kan jij je nationaliteit niet vinden in deze lijst? Dit kan het geval zijn als je nationaliteit voorkomt op de lijst met high-risk landen van de Europese Commissie. Neem dan contact op met Ligo. Wij helpen je graag verder.',
    expiration_date:
      'Hou er rekening mee dat het gekozen identiteitsdocument geldig moet zijn op het moment dat jij je identificeert bij de notaris.',
    lives_in_netherlands:
      'Als je in het buitenland woont, is het mogelijk om je stichting bij Ligo op te richten. De notaris heeft dan een bankafschrift of energierekening met jouw naam en adres erop nodig. Hiermee kan de notaris jouw adres controleren.',
    english_proficient:
      'In case one of the incorporators cannot speak, read and write in Dutch, please note that the incorporation needs to be done in English. Please contact Ligo in order to find out which additional conditions and costs may apply. Voor de oprichting in het Nederlands moeten alle oprichters Nederlands kunnen spreken, lezen en verstaan. De notaris zal dit ook controleren.'
  }
};
