












import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'BackButton',
  components: {},
  props: {
    loading: {
      type: Boolean
    },
    text: {
      type: String,
      default: 'go_back'
    },
    classes: {
      type: String,
      default:
        'outline-light-grey border-radius-4 hover-surface-default font-button text-default q-py-xs q-px-lg'
    }
  }
});
