import { ActionTree } from 'vuex';
import { StateInterface } from '../../index';
import { PropsInterface } from './state';
import { UserService } from '../services';
import { TokenInterface, AuthToken } from '../services';
import { RESOURCES } from '../resources';
import { Notify } from 'quasar';
import Vue from 'vue';
import { ApiService } from '@ligo/shared/utils';
import { UserInfo } from '../models/UserInfo';
import { Analytic } from '@ligo/bv-flow/analytics';

const actions: ActionTree<PropsInterface, StateInterface> = {
  async auth({ commit }, { data, registration = false }): Promise<boolean> {
    commit('authRequest');
    try {
      const token = await UserService.auth(data, registration);
      commit('authSuccess', token);
      return true;
    } catch (e) {
      commit('authError', {
        errorCode: e.errorCode,
        errorMessage: e.message,
        errorData: e.data,
      });
      return false;
    }
  },
  async auth_redirection({ commit }, data) {
    commit('authRequest');
    const token = await UserService.redirection_auth(data);
    commit('authSuccess', token);
    return token;
  },
  logout({ commit }) {
    void UserService.logout().then(() => {
      Analytic.reset();
      localStorage.setItem('stepsInfo', '');
      commit('logoutSuccess');
    });
  },
  async saveToken({ commit }, tokenData: TokenInterface) {
    const token = new AuthToken(undefined, tokenData);
    token.save();
    commit('authSuccess', token);
    Notify.create({
      message: Vue['i18n'].t('auth.login.succeeded') as string,
      color: 'dark',
      position: 'top',
      icon: 'mdi-emoticon'
    });
    const response = await ApiService.get<UserInfo>(RESOURCES.ME);
    token.firstname = response.data.data.firstname;
    token.lastname = response.data.data.lastname;
    token.userHash = response.data.data.user_hash;
    token.userUuid = response.data.data.uuid;
    token.profileImage = response.data.data.profile_image;
    token.save();
  },
  resetPassword({}, { data }) {
    const response = UserService.resetPassword(data);
    return response;
  },
  changePassword({}, data) {
    const response = UserService.changePassword(data);
    return response;
  },
  async confirm({ commit }, token): Promise<boolean> {
    commit('authRequest');
    const result = await UserService.confirm(token);
    if (result) {
      commit('confirmRequest');
    }
    return result;
  },
  openLogin({ commit }) {
    commit('openLogin');
  },
  openRegister({ commit }) {
    commit('openRegister');
  },
  closeDialog({ commit }) {
    commit('closeDialog');
  }
};

export default actions;
