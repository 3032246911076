


































import { defineComponent, ref } from '@vue/composition-api';
import { PropType } from 'vue';
import { useTypes, selectType } from './type-list.hooks';
import { LabelButton, LabelTooltip } from '@ligo/shared/mvc';

export default defineComponent({
  name: 'TypeList',
  components: { LabelButton, LabelTooltip },
  props: {
    index: {
      type: Number,
      default: 0
    },
    typesSubset: {
      type: (Array as unknown) as PropType<Array<number>>
    },
    typesCustomLabels: {
      type: (Array as unknown) as PropType<Array<string>>,
      validator: (value) => {
        return Array.isArray(value) && value.length === 3;
      }
    },
    showTypesMessage: {
      type: Boolean,
      default: true
    },
    locale: {
      type: String,
      required: true
    },
    label: {
      type: String,
      default: 'type_title'
    }
  },
  setup(props, { emit }) {
    const localIndex = ref(props.index);
    props.typesCustomLabels;
    const { typesRef } = useTypes(props.typesSubset, props.typesCustomLabels);
    const select = (index: number, update = true) => {
      selectType(typesRef, index);
      localIndex.value = index;
      emit('selectedType', typesRef.value[index].id, update);
    };
    const externalSelection = (index: number) => {
      select(index, false);
    };
    select(localIndex.value, false);
    const labelTooltip = ref(false);
    const tooltipText = `${props.locale}natural_person_tooltip`;
    return {
      typesRef,
      select,
      localIndex,
      externalSelection,
      labelTooltip,
      tooltipText
    };
  }
});
