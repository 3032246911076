import { ApiService, APIResultWrapper } from '@ligo/shared/utils';
import {
  RESOURCES,
  Membership,
  SerializeMembership
} from '@ligo/bv-flow/store';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';

const serializeMembership = (result: Membership): SerializeMembership => ({
  id: result.id,
  name: result.name,
  originalPrice: result.strikethrough_price,
  currentPrice: result.notary_product_price,
  totalPrice: result.price,
  details: result.translation_json,
  upsell_package_type_products: result.upsell_package_type_products
});

export const useDataFromAPI = (typeId: string) => {
  const membershipData = APIResultWrapper(
    ApiService.get<Array<Membership>>(RESOURCES.MEMBERSHIPS + typeId),
    serializeMembership
  );
  return { membershipData };
};

export const useData = (notary_product: NotaryProduct) => {
  const title = {
    dark: productLocale(notary_product, 'upsell_packages.title'),
    light: productLocale(notary_product, 'upsell_packages.title_light')
  };

  const subtitle = productLocale(notary_product, 'upsell_packages.description');
  return { title, subtitle };
};
