













































import { defineComponent, computed, ref } from '@vue/composition-api';
import { NotaryProduct } from '@ligo/bv-flow/store';

import NewContactCard from '../../../base/NewContactCard.vue';
import BackButton from '../../../base/BackButton.vue';
import NextButton from '../../../base/NextButton.vue';
import ExtraServiceCard from './ExtraServiceCard.vue';
import { useExtraService } from './extraService.hooks';

const SPINNER_SIZE = '200px';

import Vue from 'vue';
import { analyticGTMPartnerOffers } from '../../../../hooks/useBvFlowGTM.hooks';

export default defineComponent({
  name: 'ExtraServices',
  components: {
    NewContactCard,
    BackButton,
    NextButton,
    ExtraServiceCard
  },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    }
  },
  setup(props, { emit }) {
    const i18n = Vue['i18n'];
    const {
      loading,
      extraServices,
      selectedPackages,
      t,
      onSave,
      onSelectPackage
    } = useExtraService(props.notary_product, i18n, emit);

    analyticGTMPartnerOffers(
      props.notary_product.locale,
      props.notary_product.temporalStep,
      props.notary_product.slug
    );

    return {
      selectedPackages,
      loading,
      extraServices,
      SPINNER_SIZE,
      onSelectPackage,
      onSave,
      t
    };
  }
});
