export default {
  almost_ready: 'Bijna klaar, kies je notaris',
  text1:
    'Verspreid over Nederland zijn onafhankelijke notarissen aangesloten bij Ligo. <br/> Zit er bij jou geen Ligo notaris in de buurt? Geen probleem. Je kunt naar een notaris bij jou in de buurt om je te identificeren en de volmacht te ondertekenen. Deze notaris zal meestal tussen de €25 en €50 rekenen voor deze identificatie. Een Ligo notaris zal de notariële akte daarna passeren. Nadat de betaling is afgerond, zal een Ligo notaris contact met je opnemen om de stappen te doorlopen.',
  text2:
    'Zit er bij jou geen Ligo notaris in de buurt? Geen probleem. Je kunt naar een notaris bij jou in de buurt om je te identificeren en de volmacht te ondertekenen. Deze notaris zal meestal tussen de €25 en €50 rekenen voor deze identificatie. Een Ligo notaris zal de notariële akte daarna passeren. Nadat de betaling is afgerond, zal een Ligo notaris contact met je opnemen om de stappen te doorlopen.',
  notary_question:
    'Bij welke notaris wil je langsgaan voor identificatie en het tekenen van een volmacht (legalisatie)?',
  aclaration:
    'Als je bij een Ligo notaris langs gaat, zijn hier geen extra kosten aan verbonden. Ga je liever langs een andere notaris bij jou in de buurt? Houd dan rekening met kosten tussen de € 25 en € 50 voor de legalisatie.',
  choose: 'Kies de Ligo notaris waarbij je de akte wilt laten passeren:',
  label1: 'Bij een notaris van Ligo',
  label2: 'Bij een andere notaris in de buurt',
  label3: 'Ik weet al bij welke notaris in de buurt ik langs wil',
  label4:
    'Ik wil graag hulp van Ligo bij het vinden van een notaris in de buurt',
  tooltip: '100% Online Oprichting',
  disclaimer:
    'Zie hieronder bij welke notaris 100% online oprichting mogelijk is. Je hoeft dan niet bij de notaris langs te gaan. Let op: dit is niet mogelijk voor buitenlandse oprichters. Daarnaast kan de notaris altijd verlangen dat je onder bepaalde omstandigheden alsnog langskomt op kantoor.',
  subtitle:
    'Je kan een notaris kiezen die 100% online jouw dienst verricht, of je kunt langsgaan bij de notaris.',
  info:
    'Let op: voor 100% online diensten kan een notaris in zeer beperkt aantal gevallen verlangen dat je toch langskomt op kantoor. Heb je te maken met buitenlandse oprichters? In dat geval is het mogelijk dat de notaris aangeeft dat 100% online dienst niet mogelijk is. Neem contact op met ons Support team voor meer informatie.',
  online: 'Online',
  online_pros: [
    'Snel geregeld',
    'Geen reiskosten',
    'Gemakkelijk via videocall'
  ],
  in_person: 'Langs bij de notaris',
  in_person_pros: ['Persoonlijke ontmoeting op kantoor']
};
