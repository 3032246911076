

























import { defineComponent, ref, computed, PropType } from '@vue/composition-api';

import Logo from './Logo.vue';
import BaseI18nSwitch from '../base/BaseI18nSwitch.vue';
import AuthButton from './AuthButton.vue';
import { TelephoneNumber } from '@ligo/shared/components';

export default defineComponent({
  name: 'Toolbar',
  components: {
    Logo,
    AuthButton,
    BaseI18nSwitch,
    TelephoneNumber
  },
  props: {
    value: {
      type: Boolean,
      default: true
    },
    screen: {
      type: Number,
      default: 1
    },
    drawer: Boolean,
    enableSwitch: Boolean,
    i18nSwitch: Object as PropType<{
      en: { active: boolean; language: string };
      nl: { active: boolean; language: string };
    }>,
    progressBarStep: {
      type: Number,
      default: 1
    },

    noPhoneNumber: {
      type: Boolean,
      default: false
    },
    contentClass: {
      type: String,
      default: ''
    }
  },
  setup(_, { root }) {
    const stepper = ref();
    const showGoBack = computed(() => {
      const isHome = root.$router.currentRoute.name === 'home';
      return !isHome;
    });

    return {
      stepper,
      showGoBack
    };
  }
});
