export default {
  labels: {
    prefix: 'Aanhef',
    firstname: 'Names',
    lastname: 'Achternaam',
    birthdate: 'Geboortedatum',
    birthplace: 'Geboorteplaats',
    email: 'E-mailadres',
    address: 'Woonadres',
    company_name: 'Bedrijfsnaam',
    founder_address: 'What is the shareholder’s address?',
    zip_code: '',
    location: '',
    chamber: 'KvK-nummer',
    director_authorized:
      'Is de vertegenwoordigingsbevoegde bestuurder van deze aandeelhouder een onderneming?',
    representation_type:
      'Deze persoon mag de onderneming vertegenwoordigen als:',
    document_type: 'Soort legitimatiebewijs',
    nationality: 'Nationaliteit',
    document_number: 'Documentnummer',
    issue_date: 'Datum van uitgifte',
    expiration_date: 'Geldig tot',
    place_of_issue: 'Plaats van uitgifte',
    bsn: 'BSN nummer',
    natural_person_lives_in_netherlands:
      'Is deze aandeelhouder in Nederland woonachtig?',
    natural_person_english_proficient:
      'Is deze aandeelhouder de Nederlandse taal machtig?',
    lives_in_netherlands:
      'Woont de persoon die namens de onderneming optreedt in Nederland?',
    english_proficient:
      'Is de persoon die namens de onderneming optreedt de Nederlandse taal machtig?',
    become_director: 'Wordt de onderneming bestuurder van de op te richten BV?',
    marital_status: 'Burgerlijke staat',
    has_proprietorship:
      'Heeft deze aandeelhouder een actieve eenmanszaak / VOF?',
    kvk_proprietorship:
      'Wat is het KVK-nummer van de eenmanszaak / VOF van deze aandeelhouder?',
    function: 'Wat is de functie van deze persoon?',
    company_location: '',
    company_address: 'Adres',
    company_zipcode: '',
    representative_birthdate:
      'Vul de geboortedatum in van de persoon die namens de onderneming mag optreden',
    representative_birthplace:
      'Vul de geboorteplaats in van de persoon die namens de onderneming mag optreden',
    natural_person_director:
      'Wordt deze aandeelhouder bestuurder van de op te richten onderneming?',
    incorporated_in_netherlands: 'Is de aandeelhouder opgericht in Nederland?',
    ubos: 'How many UBO’s does this company have?',
    ubo_kvk_registered: 'Heb je de UBO registratie bij de KVK al gedaan?'
  },
  checkboxes: {
    ubo_kvk_registered_check:
      'Ik begrijp dat de notaris de BV niet mag oprichten voordat de UBO‘s zijn opgegeven bij de KVK.'
  },
  placeholders: {
    prefix: 'Titel',
    firstname: 'Officiële voornamen',
    lastname: 'Achternaam',
    email: 'Vul het e-mailadres in',
    birthdate: 'YYYY-MM-DD',
    birthplace: 'Geboorteplaats',
    address: 'Vul straat en huisnummer in',
    marital_status: '',
    has_proprietorship: '',
    kvk_proprietorship: '',
    company_name: 'Vul de naam van de holding in',
    zip_code: 'Vul de postcode in',
    location: 'Vul de plaatsnaam in',
    chamber: 'Vul het KvK-nummer in',
    holding_name: 'Naam van de holding',
    nationality: 'Kies uit de lijst',
    document_number: 'Documentnummer',
    place_of_issue: 'Plaats van uitgifte',
    bsn: 'BSN nummer',
    issue_date: 'YYYY-MM-DD',
    expiration_date: 'YYYY-MM-DD',
    shares: 'Vul een aantal in',
    company_location:
      'Vul de plaatsnaam waar het fysieke kantoor is gevestigd in',
    company_address: 'Vul de straatnaam en het huisnummer in',
    company_zipcode: 'Vul de postcode in'
  },
  options: {
    document_type: {
      passport: 'Paspoort',
      driver_licence: 'Rijbewijs',
      identity_card: 'Identiteitskaart'
    },
    marital_status: {
      unmarried: 'Ongehuwd en geen geregistreerd partnerschap',
      registered_partner: 'Geregistreerd partnerschap',
      married: 'Gehuwd'
    },
    representation_type: {
      independently_authorized_director: 'Zelfstandig bevoegd bestuurder',
      jointly_authorized_director: 'Gezamenlijk bevoegd bestuurder'
    },
    nationality: {
      dutch: 'Nederlands',
      belgian: 'Belgisch',
      french: 'Frans',
      english: 'Brits'
    },
    prefix: {
      Mr: 'Dhr',
      Mrs: 'Mevr'
    },
    function: {
      authorized_director: 'Authorized director',
      authorized_representative: 'Authorized representative'
    }
  }
};
