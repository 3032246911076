



































import { defineComponent } from '@vue/composition-api';

const IMAGE = '/questionnaire/images/error404.svg';

export default defineComponent({
  name: 'Error404',
  setup() {
    return {
      IMAGE
    };
  }
});
