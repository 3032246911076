var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row justify-center bv-flow-card q-mt-md q-pa-32 q-mb-md"},[_c('div',{staticClass:"col-12 col-md-9 self-top"},[_c('instruction-banner',{attrs:{"title":_vm.computedTitle,"usei18n":false}}),_c('base-form',{ref:"form",staticClass:"q-mt-xl",attrs:{"form":_vm.transferOfSharesForm},on:{"previous-step":function($event){return _vm.$emit('previous-step')},"submit":_vm.next}}),(
          _vm.transferOfSharesForm.fields.accountant &&
          _vm.transferOfSharesForm.fields.accountant.value
        )?_c('bv-close-dialog',{attrs:{"value":_vm.transferOfSharesForm.fields.accountant.value == 'no',"title":_vm.productLocale(
            _vm.notary_product,
            'shareholder_information.cant_incorporate'
          ),"label-confirm":_vm.productLocale(
            _vm.notary_product,
            'shareholder_information.other_services'
          ),"hide-cancel":""},on:{"input":function($event){_vm.transferOfSharesForm.fields.accountant.value = null},"confirm":function($event){return _vm.$emit('dialog-refuse')}}}):_vm._e()],1)]),_c('new-contact-card',{staticClass:"q-mb-md"})],1)}
var staticRenderFns = []

export { render, staticRenderFns }