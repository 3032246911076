import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/bv-flow/store';
import { BasicFieldType } from '@ligo/shared/mvc';
import Vue from 'vue';

export const loginFormLayout = [['email'], ['password']];

export const registerFormLayout = [
  ['firstname', 'lastname'],
  ['phone'],
  ['email'],
  ['password'],
  ['password_confirmation']
];

const googleBtn = {
  icon: 'img:/questionnaire/icons/google.svg',
  label: 'google',
  link: RESOURCES.GOOGLEOAUTH
};

const linkedinBtn = {
  icon: 'img:/questionnaire/icons/linkedin.svg',
  label: 'linkedin',
  link: RESOURCES.LINKEDINOAUTH
};

export const socialBtns = [googleBtn, linkedinBtn];

export const socialAuth = (
  socialBtn: typeof googleBtn,
  redirect_url = '/questionnaire'
) => {
  const redirection = `&auth_redirect_url=${redirect_url}`;
  window.open(
    `${socialBtn.link + window.location.host}/questionnaire${redirection}`,
    '_self'
  );
};

export const providerLinks = {
  google_oauth2: RESOURCES.GOOGLEOAUTH,
  linkedin: RESOURCES.LINKEDINOAUTH
};

export const socialAuthLink = (
  provider: string,
  redirect_url = '/questionnaire'
) => {
  const redirection = `&auth_redirect_url=${redirect_url}`;
  return `${
    providerLinks[provider] + window.location.host
  }/questionnaire${redirection}`;
};

const fieldsToDict = (fields: BasicFieldType[]) => {
  const result: { [id: string]: any } = {};
  fields.forEach((field) => {
    result[field.key] = field.value;
  });
  return result;
};

export const useLogin = () => {
  const performLogin = async (fields: BasicFieldType[]) => {
    const result: boolean = await Vue['Store'].dispatch('authentication/auth', {
      data: fieldsToDict(fields)
    });
    return result;
  };
  return { performLogin };
};

export const useRegister = () => {
  const performRegister = async (
    fields: BasicFieldType[],
    remainingParams = {}
  ) => {
    const result: boolean = await Vue['Store'].dispatch('authentication/auth', {
      data: {
        ...fieldsToDict(fields),
        ...remainingParams,
        confirm_success_url: RESOURCES.CONFIRMATION
      },
      registration: true
    });
    return result;
  };
  return { performRegister };
};

export const sendConfirmationEmail = (email: string) => {
  return ApiService.post(RESOURCES.RETRY_CONFIRMATION, { email });
};
