export default {
  labels: {
    name: 'Company name',
    street: 'Street',
    house_number: 'House number',
    zip: 'Postal code',
    city: 'City',
    phone_number: 'Telephone number',
    vat_number: 'VAT number',
    extra: 'Extra information'
  },
  placeholders: {
    name: 'eg Ligo B.V.',
    street: 'eg Stadhouderskade',
    house_number: 'e.g. 60',
    zip: 'eg 1072 AC',
    city: 'eg Amsterdam',
    phone_number: 'e.g. 020-3031043',
    vat_number: 'Optional',
    extra: 'Optional'
  },
  tooltips: {}
};
