
























































































































































import { Dictionary } from '@ligo/shared/utils';
import { computed, defineComponent } from '@vue/composition-api';
import { Screen } from 'quasar';
import BvAlertBanner from '../../base/BvAlertBanner.vue';

export default defineComponent({
  name: 'ConversionDialog',
  components: { BvAlertBanner },
  props: {
    value: {
      type: Boolean,
      required: true
    }
  },
  setup(_, { root }) {
    const comparisons = root.$calct(
      'conversion_dialog.comparison'
    ) as Dictionary<string>[];
    const headers = root.$calct(
      'conversion_dialog.headers'
    ) as Dictionary<string>;
    const cardWidth = computed(() => {
      if (Screen.gt.sm) return 1240;
      if (Screen.sm) return 800;
      return 900;
    });
    return {
      comparisons,
      headers,
      cardWidth
    };
  }
});
