










































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { VideoDialog } from '@ligo/bv-flow/store';
import { Screen } from 'quasar';
export default defineComponent({
  name: 'UpsellVideoDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object as PropType<VideoDialog>
    }
  },
  setup() {
    const cardWidth = computed(() => {
      if (Screen.gt.sm) return 1080;
      if (Screen.sm) return 700;
      return 900;
    });
    const videoHeight = computed(() => {
      if (Screen.gt.sm) return '60vh';
      if (Screen.sm) return '70vh';
      return '70vh';
    });
    return { cardWidth, videoHeight };
  }
});
