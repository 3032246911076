export default {
  calculator_email: 'You must provide a valid Email Address',
  email_format: 'You must provide a valid Email Address',
  email: 'You must provide a valid Email Address',
  only_numbers: 'This field can be only numbers',
  password_length: 'The password must have at least 8 characters',
  password_match: 'Password must match',
  before_today: 'The document must have been issued before today',
  after_today: "The document shouldn't be expired",
  positive: 'It must be a number greater than 0',
  non_negative: 'It must be a number greater or equal than 0',
  above1: 'It must be a number greater than 1',
  legal_age: 'You must be older than 18 years old in order to incorporate',
  required_email: 'You must provide an Email Address',
  required_password: 'You must provide a password',
  required_first_name: 'You must provide a First Name',
  required_last_name: 'You must provide a Last Name',
  required_phone_number: 'You must provide a Phone Number',
  phone_number: 'You must provide a valid telephone number',
  required_generic: 'This field is required',
  bv_suffix:
    'We will add automatically "BV" at the end of the name. Therefore you do not have to fill it in',
  zip_code:
    'you must write exactly 4 characters a space and 2 uppercase letters',
  no_fundation:
    'It is not possible to add a foundation as a shareholder with Ligo. If you wish to do so, please contact our Customer Service Team'
};
