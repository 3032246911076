import { RouteConfig } from 'vue-router';
import {
  BVLayout,
  Index,
  BvIndex,
  Error404,
  Login,
  Registration,
  NotaryProductHandler,
  ResetPassword,
  BillingInformation,
  Checkout,
  EmailConfirmation,
  CalculatorLayout,
  Calculator,
  Payment,
  LegalCheckup
} from '@ligo/bv-flow/components';

import { servicesCard, bvServicesCard } from './ligo-services';

const routes: RouteConfig[] = [
  {
    path: '/questionnaire',
    component: BVLayout,
    children: [
      {
        path: '',
        name: 'home',
        component: Index,
        props: (route) => ({
          access_token: route.query.access_token,
          client: route.query.client,
          expiry: route.query.expiry,
          uid: route.query.uid,
          new_product: route.query.new_product,
          redirect_url: route.query.redirect_url,
          servicesCard
        }),
        meta: {
          progressBarStep: 0
        }
      },
      {
        path: 'bv',
        name: 'bv-home',
        component: BvIndex,
        props: (route) => ({
          access_token: route.query.access_token,
          client: route.query.client,
          expiry: route.query.expiry,
          uid: route.query.uid,
          new_product: route.query.new_product,
          redirect_url: route.query.redirect_url,
          servicesCard: bvServicesCard
        }),
        meta: {
          progressBarStep: 0
        }
      },
      {
        path: 'login',
        name: 'login',
        component: Login,
        props: (route) => ({
          redirectUrl: route.query.redirect_url,
          productType: route.query.product_type
        }),
        meta: {
          progressBarStep: 0,
          customLayout: 'max-display-checkout'
        }
      },
      {
        path: 'confirmation',
        name: 'Confirmation',
        component: EmailConfirmation,
        props: (route) => ({
          redirectUrl: route.query.redirect_url,
          productType: route.query.product_type
        }),
        meta: {
          progressBarStep: 0,
          customLayout: 'max-display-checkout'
        }
      },
      {
        path: 'reset-password',
        name: 'ResetPassword',
        component: ResetPassword,
        meta: {
          progressBarStep: 0,
          customLayout: 'max-display-checkout'
        }
      },
      {
        path: 'register',
        name: 'register',
        component: Registration,
        props: (route) => ({
          redirectUrl: route.query.redirect_url,
          productType: route.query.product_type
        }),
        meta: {
          progressBarStep: 0,
          customLayout: 'max-display-checkout'
        }
      },
      {
        path: 'dutch-bv/:uid?',
        name: 'DutchBV',
        component: NotaryProductHandler,
        meta: {
          language: 'nl'
        },
        props: (route) => ({
          uid: route.params.uid,
          initialValues: { bv_type: 'standard' },
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: 'spaar-bv/',
        name: 'SpaarBV',
        component: NotaryProductHandler,
        meta: {
          language: 'nl'
        },
        props: (route) => ({
          uid: route.params.uid,
          initialValues: { bv_type: 'private_savings' },
          redirectRouteName: 'DutchBV',
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: 'private-saving-bv/',
        name: 'PrivateSavingBV',
        component: NotaryProductHandler,
        meta: {
          language: 'en'
        },
        props: (route) => ({
          uid: route.params.uid,
          initialValues: { bv_type: 'private_savings' },
          redirectRouteName: 'EnglishBV',
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: 'english-bv/:uid?',
        name: 'EnglishBV',
        component: NotaryProductHandler,
        meta: {
          language: 'en'
        },
        props: (route) => ({
          uid: route.params.uid,
          initialValues: { bv_type: 'standard' },
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: 'holding/:uid?',
        name: 'Holding',
        component: NotaryProductHandler,
        meta: {
          language: 'nl'
        },
        props: (route) => ({
          uid: route.params.uid,
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: 'incorporate-holding/:uid?',
        name: 'IncorporateHolding',
        component: NotaryProductHandler,
        meta: {
          language: 'en'
        },
        props: (route) => ({
          uid: route.params.uid,
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: 'omzetting/:uid?',
        name: 'Omzetting',
        component: NotaryProductHandler,
        meta: {
          language: 'nl'
        },
        props: (route) => ({
          uid: route.params.uid,
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: 'stichting/:uid?',
        name: 'Stichting',
        component: NotaryProductHandler,
        meta: {
          language: 'nl'
        },
        props: (route) => ({
          uid: route.params.uid,
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: 'anbi_stichting_oprichten/:uid?',
        name: 'AnbiStichting',
        component: NotaryProductHandler,
        meta: {
          language: 'nl'
        },
        props: (route) => ({
          uid: route.params.uid,
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: 'transfer_of_shares/:uid?',
        name: 'TransferOfShares',
        component: NotaryProductHandler,
        meta: {
          language: 'nl'
        },
        props: (route) => ({
          uid: route.params.uid,
          calculationUuid: route.query.calculationUuid
        })
      },
      {
        path: ':slug/:uid/billing',
        name: 'BillingInformation',
        component: BillingInformation,
        props: true,
        meta: {
          requiresAuth: true,
          redirect: 'register',
          progressBarStep: 3,
          customLayout: 'max-display-checkout'
        }
      },
      {
        path: ':slug/:uid/checkout',
        name: 'Checkout',
        props: true,
        component: Checkout,
        meta: {
          requiresAuth: true,
          redirect: 'register',
          progressBarStep: 3,
          customLayout: 'max-display-checkout'
        }
      },
      {
        path: 'payment/:uuid',
        name: 'Payment',
        props: (route) => ({
          query: route.query,
          uuid: route.params.uuid
        }),
        component: Payment,
        meta: { progressBarStep: 3 }
      }
    ]
  },
  {
    path: '/questionnaire/calculator/',
    component: CalculatorLayout,
    children: [
      {
        path: ':uuid?',
        props: (route) => ({
          uuid: route.params.uuid,
          origin: route.query.origin,
          lang: route.query.lang
        }),
        name: 'Calculator',
        component: Calculator
      },
      {
        path: '/questionnaire/legal-scan/:slug/:uuid?',
        props: (route) => ({
          uuid: route.params.uuid,
          slug: route.params.slug,
          lang: route.query.lang
        }),
        name: 'LegalCheckup',
        component: LegalCheckup
      }
    ]
  },
  // Always leave this as last one,
  // but you can also remove it
  {
    path: '*',
    name: '404',
    component: Error404
  }
];

export default routes;
