


















































import { PackageTypesInfo } from '@ligo/bv-flow/store';
import { computed, defineComponent, PropType } from '@vue/composition-api';
import BvBadge from '../../../base/BvBadge.vue';

const CHECK_ICON = 'img:/questionnaire/icons/radio_check.svg';
const UNCHECK_ICON = 'img:/questionnaire/icons/radio_uncheck.svg';
const NULL_CHECK_ICON = 'img:/questionnaire/icons/radio_null_check.svg';

export default defineComponent({
  name: 'UpsellPackageCard',
  components: { BvBadge },
  props: {
    package: {
      type: Object as PropType<PackageTypesInfo>
    },
    val: {
      type: Number
    },
    selectedValue: {
      type: Number
    }
  },
  setup(props) {
    const active = computed(() => {
      return props.val == props.selectedValue;
    });
    return {
      CHECK_ICON,
      UNCHECK_ICON,
      NULL_CHECK_ICON,
      active
    };
  }
});
