export default {
  title: 'Je conceptakte wacht op je in jouw dashboard!',
  note: 'Let op :',
  note_text: ' Dit is slechts een voorbeeld van de akte',
  list: 'Lijst van documenten',
  d1: 'Concept akte aandelenoverdracht',
  d2: 'Toelichting op de akte',
  d3: 'Verklaring afstand voorkeursrecht aandeelhouder',
  d4: 'Persoonlijke Datacard voor de Kamer van Koophandel',
  btn_download: 'Download',
  btn_subtitle: 'Je kunt de documenten downloaden na betaling',
  next_step_title: 'Volgende stap',
  next_step_description:
    'Na afronding van deze vragenlijst zal je worden gevraagd om de benodigde documenten te uploaden. Let er op dat je alle documenten uploadt. Pas daarna zal de notaris contact met je opnemen voor het passeren van de akte.',
  previous: 'Vorige',
  next: 'Volgende',
  name: 'Bekijk je documenten'
};
