import { Ref, ref } from '@vue/composition-api';
import {
  NotaryProduct,
  ProductBasicFieldDcl,
  ProductForm
} from '@ligo/bv-flow/store';
import { TypeFields } from './steps/forms_definitions';
import { FormConfig } from '@ligo/shared/mvc';

export type ShareholderForms = Array<
  Array<
    { [P in keyof ProductForm]?: ProductForm[P] } &
      FormConfig<ProductBasicFieldDcl<any>>
  >
>;

export class Shareholder {
  id: string;
  forms: Array<Array<Ref<ProductForm>>>;
  formGlobal: Ref<ProductForm>;
  stepSideBar: number;
  sideMenuRef: Ref<any> = ref(null as any);
  active: boolean;
  completed: boolean;
  index: number;
  notary_product: NotaryProduct;

  constructor(
    id: string,
    notary_product: NotaryProduct,
    shareholderForms: ShareholderForms
  ) {
    this.id = id;
    this.notary_product = notary_product;
    this.forms = shareholderForms.map((row) =>
      row.map((def) => {
        const { layout, ...config } = def;
        return ref(new ProductForm(id, layout, notary_product, config));
      })
    );
    this.formGlobal = ref(
      new ProductForm(id, TypeFields, notary_product, { sendStep: false })
    );
    const resource = this.notary_product.resources[this.id];
    this.stepSideBar = resource.substep;
    this.active = resource.active;
    if (this.formGlobal.value.fields.is_natural_person.value === null) {
      this.setType(0);
    }
    this.index = parseInt(this.id.slice(-1));
  }

  setType(index: number) {
    [0, 1, 2].forEach((key) => {
      this.formGlobal.value.fields[TypeFields[0][key]].value = key == index;
    });
    this.formGlobal.value.fields['holding'].value = index == 2;
    if (index in [1, 2]) {
      this.formGlobal.value.fields['holding_name'].value = null;
    }
    void this.formGlobal.value.save(false);
  }

  getType(): number {
    let res = 0;
    [2, 1, 0].forEach((key) => {
      if (this.formGlobal.value.fields[TypeFields[0][key]].value === true) {
        res = key;
      }
    });
    return res;
  }

  getMainForm() {
    const form = this.forms[this.stepSideBar][this.getType()];
    return form;
  }

  clear() {
    this.active = false;
    this.stepSideBar = 0;
    const resource = this.notary_product.resources[this.id];
    resource.active = false;
    resource.substep = 0;
    void this.notary_product.save(false);
    this.forms.forEach((row) =>
      row.forEach((form) => {
        form.value.reset();
        void form.value.save(false);
      })
    );
    this.formGlobal.value.reset();
    void this.formGlobal.value.save(false);
  }

  save(send = true, sendStep = true) {
    this.notary_product.resources[this.id].active = true;
    this.notary_product.resources[this.id].substep = this.stepSideBar;
    void this.notary_product.save(send, { sendStep: sendStep });
  }
}
