import { ref, Ref } from '@vue/composition-api';
import { ShareHolder } from '@ligo/bv-flow/store';

const COLORS = ['blue', 'red', 'orange', 'purple'];
const DEFAULT_INACTIVE_ARRAY = [4, 3, 2];
const GRADUATION = '-12';
const MAXLENGTH = 4;

let IDS: number[];

const shareholders: Array<ShareHolder> = [
  {
    id: 1,
    label: ' 1',
    selected: true,
    icon: 'face',
    color: COLORS[0] + GRADUATION
  }
];

export const addShareHolder = (list: Ref<Array<ShareHolder>>) => {
  if (IDS.length > 0) {
    const ID = IDS[IDS.length - 1];
    list.value.push({
      id: ID,
      label: ' ' + ID.toString(),
      selected: false,
      icon: 'face',
      color: COLORS[ID - 1] + GRADUATION,
      deletable: true
    });
    IDS.pop();
    selectShareHolder(list, list.value.length - 1);
  }
};

export const deleteShareHolder = (
  list: Ref<Array<ShareHolder>>,
  index: number
) => {
  if (list.value[index].selected) selectShareHolder(list, 0);
  IDS.push(list.value[index].id);
  IDS.sort();
  IDS.reverse();
  list.value.splice(index, 1);
};

export const addCheck = (list: Ref<Array<ShareHolder>>) => {
  if (list.value.length < MAXLENGTH) return true;
  return false;
};

export const selectShareHolder = (
  list: Ref<Array<ShareHolder>>,
  index: number
) => {
  for (let i = 0; i < list.value.length; ++i) {
    list.value[i].selected = i == index;
  }
};

export const useShareHolders = (active?: Array<number>, minimum = 0) => {
  IDS = DEFAULT_INACTIVE_ARRAY;
  const list = [...shareholders];
  if (active) {
    active.sort();
    for (let i = 0; i < active.length; ++i) {
      if (active[i] != 0) {
        const id = active[i] + 1;
        list.push({
          id: id,
          label: ' ' + (active[i] + 1).toString(),
          selected: false,
          icon: 'face',
          color: COLORS[active[i]] + GRADUATION,
          deletable: id > minimum
        });
      }
    }
    IDS = IDS.filter((x) => !active.includes(x - 1));
    IDS.sort();
    IDS.reverse();
  }
  const listReference = ref(list);
  return { listReference };
};
