import { CalculatorResource } from '@ligo/bv-flow/store';
import { getRouteNameFromSlug } from 'libs/bv-flow/store/src/lib/models/NotaryProduct';
import { getLegalCheckupTypes } from 'libs/bv-flow/store/src/lib/services';

const DEFINITION_SLUG = 'calculator';

export function toProductRoute(calculator: CalculatorResource) {
  return getRouteNameFromSlug(calculator.getNotaryProductTypeSlug());
}

export async function initializeCalculator(uuid?: string) {
  const typeResponse = await getLegalCheckupTypes(DEFINITION_SLUG);

  const calculator = new CalculatorResource(
    {
      fieldsDescriptions: typeResponse.data.configuration.fieldDescriptions,
      loadOnCreate: true
    },
    'fields',
    uuid
  );

  return calculator;
}
