





































import { NotaryProduct } from '@ligo/bv-flow/store';
import {
  defineComponent,
  ref,
  computed,
  ComputedRef,
  watch
} from '@vue/composition-api';
import {
  getGlobalStep,
  initStepper,
  useStepper
} from './handlers/base-components/stepper.hooks';

const ICON_SIZE = '26px';

export default defineComponent({
  name: 'StepsProgressBar',
  props: {
    notaryProduct: {
      type: Object as () => NotaryProduct,
      required: true
    }
  },
  setup(props, { emit }) {
    const { stepsRef } = useStepper(props.notaryProduct);
    const substep = ref(0);
    const index = ref(0);

    const currentStoreStep: ComputedRef<number> = computed(() => {
      return props.notaryProduct.step;
    });

    initStepper(stepsRef, substep, index, currentStoreStep.value);
    watch(currentStoreStep, (newVal) => {
      initStepper(stepsRef, substep, index, newVal);
    });

    function goTo(i: number) {
      if (i <= currentStoreStep.value) {
        emit('go-to-step', getGlobalStep(stepsRef, 0, i));
      }
    }
    return {
      stepsRef,
      substep,
      index,
      ICON_SIZE,
      goTo
    };
  }
});
