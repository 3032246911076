
















import { defineComponent, PropType } from '@vue/composition-api';

export default defineComponent({
  name: 'IconList',
  props: {
    list: {
      type: Array as PropType<string[]>,
      required: true
    },
    icons: {
      type: Array as PropType<string[]>,
      required: true
    },
    iconSize: {
      type: String,
      default: 'md'
    },
    iconColor: {
      type: String
    },
    fontSize: {
      type: String,
      default: '16'
    }
  },
  setup() {
    return {};
  }
});
