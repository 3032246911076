





































































































































import { defineComponent, ref } from '@vue/composition-api';
import { useChart } from './pie-chart.hooks';
import InstructionBanner from '../../base-components/instruction-banner.vue';
import { analyticSelectValueOfShares } from '../../../../analytics';
import { LabelButton, LabelTooltip } from '@ligo/shared/mvc';
import { productLocale, NotaryProduct } from '@ligo/bv-flow/store';
import NextButton from '../../../base/NextButton.vue';
import BackButton from '../../../base/BackButton.vue';
import NewContactCard from '../../../base/NewContactCard.vue';
import BvAlertBanner from '../../../base/BvAlertBanner.vue';
import { analyticGTMShare } from '../../../../hooks/useBvFlowGTM.hooks';

export default defineComponent({
  name: 'PieChart',
  components: {
    InstructionBanner,
    LabelTooltip,
    LabelButton,
    NextButton,
    BackButton,
    NewContactCard,
    BvAlertBanner
  },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    }
  },
  setup(props, { emit, root }) {
    const form = ref(null as any);
    const loading = ref(false);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    const {
      shareholders,
      bvForm,
      total,
      donutData,
      series,
      title,
      subtitle,
      warning,
      save,
      t,
      te,
      isReady
    } = useChart(props.notary_product);

    analyticGTMShare(
      props.notary_product.locale,
      props.notary_product.temporalStep,
      props.notary_product.slug
    );

    const initial_shares =
      bvForm.value.fields.number_of_shares.value / shareholders.value.length;

    if (!shareholders.value[0].form.fields.shares.value)
      shareholders.value.forEach((shareholder) => {
        shareholder.form.fields.shares.value = initial_shares;
      });

    const next = async () => {
      const valid = await form.value.validate();
      if (valid) {
        loading.value = true;
        await save();
        loading.value = false;
        emit('endedStep');
        analyticSelectValueOfShares(
          props.notary_product.locale,
          root.$store.state.authentication.accessToken
        );
        return true;
      }
    };
    const totalLabelTooltip = ref(false);
    const totalTooltipText = `${props.notary_product.locale}.main.label_tooltip.total_shares`;

    return {
      shareholders,
      bvForm,
      total,
      donutData,
      series,
      next,
      title,
      subtitle,
      form,
      productLocale,
      loading,
      totalLabelTooltip,
      totalTooltipText,
      warning,
      t,
      te,
      isReady
    };
  }
});
