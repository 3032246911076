


































import { defineComponent, ref, PropType } from '@vue/composition-api';
import PreviewBanner from './preview-banner.vue';
import DocumentViewer from './document-viewer.vue';
import { analyticSelectDeed } from '../../../../analytics';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';
import NewContactCard from '../../../base/NewContactCard.vue';
import NextButton from '../../../base/NextButton.vue';
import BackButton from '../../../base/BackButton.vue';
import {
  analyticGTMSelectDeed,
  analyticGTMSignUp
} from '../../../../hooks/useBvFlowGTM.hooks';
import Vue from 'vue';
import { PreviewDocPageInformation } from '../../../../hooks/useBvPageInformation.hooks';
const SPINNER_SIZE = '200px';

export default defineComponent({
  name: 'PreviewDocuments',
  components: {
    PreviewBanner,
    DocumentViewer,
    NewContactCard,
    NextButton,
    BackButton
  },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    },
    documentsSubset: {
      type: (Array as unknown) as PropType<Array<number>>
    },
    description: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const loggedIn = Vue['Store'].state.authentication.accessToken
      ? true
      : false;

    const { load, isReady, t } = PreviewDocPageInformation;
    load();

    analyticGTMSelectDeed(
      props.notary_product.locale,
      props.notary_product.temporalStep,
      props.notary_product.slug
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    const next = () => {
      void props.notary_product.save();
      if (!loggedIn) {
        analyticGTMSignUp(
          props.notary_product.locale,
          props.notary_product.temporalStep,
          props.notary_product.slug
        );
      }
      emit('endedStep');
      analyticSelectDeed(
        props.notary_product.locale,
        root.$store.state.authentication.accessToken
      );
    };
    return {
      next,
      t,
      productLocale,
      isReady,
      SPINNER_SIZE
    };
  }
});
