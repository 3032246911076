export default {
  name: 'Shareholders',
  title: 'Information about the shareholders of',
  description:
    'Our notaries require a foundation to be incorporated with at least three directors. If you would like to incorporate a foundation with more than four directors, please contact our legal support team.',
  opt1: 'Basic details',
  opt2: 'Personal details',
  opt3: 'Identification (ID)',
  opt4: 'Other questions',
  shareholder: 'Shareholder',
  complete_shareholder:
    'You should complete all shareholders information before continue',
  cant_incorporate:
    'In this case, it is unfortunately not possible to set up a foundation via Ligo.',
  other_services: 'Check other services'
};
