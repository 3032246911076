














































































































































































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import {
  PriceEstimationModel,
  FreeLineItemModel,
  ExtraFeeLineItemModel,
  CalculatorResource
} from '@ligo/bv-flow/store';
import { Dictionary } from '@ligo/shared/utils';
import NextButton from '../base/NextButton.vue';
import BvBadge from '../base/BvBadge.vue';
import { useHoldingUpsell } from '../handlers/shared-steps/HoldingUpsell/holding_upsell.hooks';

const I18N_FREE_KEY = 'price_estimation.free_line_items';
const STATUTORY_FEES_KEY = 'price_estimation.statutory_fees';
const INFO_ICON = 'img:/questionnaire/icons/circle-info.svg';
const BADGE_ICON = 'img:/questionnaire/icons/badge-percent.svg';
const ICON_SIZE = '20px';

export default defineComponent({
  name: 'CalculatorPriceComponent',
  components: { NextButton, BvBadge },
  props: {
    config: {
      type: Object as PropType<PriceEstimationModel>,
      required: true
    },
    calculator: {
      type: Object as PropType<CalculatorResource>
    }
  },
  setup(props, { root }) {
    const { prices } = useHoldingUpsell(
      props.calculator.getNotaryProductTypeSlug()
    );

    function formatNumber(rvalue: string | number) {
      const value = typeof rvalue === 'string' ? rvalue : rvalue.toString();
      return Number(value) == 0
        ? root.$t('free')
        : `€${parseFloat(value).toFixed(2)}`;
    }
    function formatDiscount(rvalue: string | number) {
      const value = typeof rvalue === 'string' ? rvalue : rvalue.toString();
      const realvalue = parseFloat(value);
      const roundValue = Math.round(realvalue);
      return Math.abs(realvalue - roundValue) < 1e-8
        ? roundValue.toFixed(0)
        : `${realvalue.toFixed(2)}`;
    }
    const upsellDiscount = computed(() =>
      props.config.upsell_package_products.reduce(
        (prev, curr) => prev + parseFloat(curr.discount),
        0
      )
    );

    const bvCost = computed(() => {
      return formatNumber(
        parseFloat(props.config.product_price) - upsellDiscount.value
      );
    });

    function showDiscount(price: string) {
      return (parseFloat(price) - prices.value.discount).toFixed(2);
    }
    const freeLineItemsData = root.$calct(I18N_FREE_KEY);
    const freeLineItems: FreeLineItemModel[] = Array.isArray(freeLineItemsData)
      ? freeLineItemsData
      : [];

    const totalDiscount = computed(
      () =>
        props.config.extra_products.notary_products.length *
        prices.value.discount
    );

    const totalPrice = computed(
      () => parseFloat(props.config.partial_price) - totalDiscount.value
    );
    const totalBtw = computed(() => (totalPrice.value * 0.21).toFixed(2));

    // Get statutory fees key from calculator page information
    const statutoryFeesKeys = root.$calct(STATUTORY_FEES_KEY) as string[];
    // Create statutory fees obj using the previus keys from config.extra_fees with the
    const statutoryFeesObj = statutoryFeesKeys.reduce((prev, key) => {
      if (props.config.extra_fees[key])
        return {
          ...prev,
          [key]: props.config.extra_fees[key]
        };
      return prev;
    }, {} as Dictionary<ExtraFeeLineItemModel>);
    // Remove the statutory fees keys from the config.extra_fees
    const newExtraFees = Object.keys(props.config.extra_fees).reduce(
      (prev, key) => {
        if (statutoryFeesKeys.indexOf(key) < 0)
          return {
            ...prev,
            [key]: props.config.extra_fees[key]
          };
        return prev;
      },
      {} as Dictionary<ExtraFeeLineItemModel>
    );
    // Get statutory fees amount
    const statutoryFeesAmount = Object.keys(statutoryFeesObj).reduce(
      (prev, key) => prev + parseFloat(statutoryFeesObj[key].price),
      0
    );
    // Create the subTotal concept by subtract the statutory fees amount from the totalPrice
    const subTotalPrice = computed(
      () => totalPrice.value - statutoryFeesAmount
    );
    return {
      formatNumber,
      formatDiscount,
      showDiscount,
      bvCost,
      upsellDiscount,
      freeLineItems,
      totalPrice,
      totalBtw,
      newExtraFees,
      statutoryFeesObj,
      subTotalPrice,
      INFO_ICON,
      BADGE_ICON,
      ICON_SIZE,
      prices
    };
  }
});
