import { SideMenuOption } from '@ligo/bv-flow/store';
import { ref, Ref } from '@vue/composition-api';

let options: Array<SideMenuOption> = [];

export const getClass = (completed: boolean, i: number, index: number) => {
  if (i == index) return 'text-primary';
  if (completed) return 'text-default';
  else return 'text-subdued';
};

export const nextOption = (list: Ref<Array<SideMenuOption>>, index: number) => {
  if (index == list.value.length - 2) return false;
  list.value[++index].completed = true;
  for (let i = index + 1; i < list.value.length; ++i)
    list.value[i].completed = false;
  return true;
};

export const useOptions = (list: Array<string>, index: number) => {
  options = [] as Array<SideMenuOption>;
  for (let i = 0; i < list.length; ++i) {
    options.push({
      label: list[i],
      completed: i < index + 1
    });
  }
  const optionsRef = ref(options);
  return { optionsRef };
};
