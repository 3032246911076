import { computed, Ref, ref } from '@vue/composition-api';
import { buildRule } from '@ligo/shared/mvc';
import Vue from 'vue';
import {
  ProductBasicFieldDcl,
  ProductField,
  ProductTextField
} from '@ligo/bv-flow/store';

const password_confirm = (field: Ref<ProductField>) => {
  return computed(() => {
    return (v: any) => {
      return v && v != field.value.value;
    };
  });
};

const user_fields: { [id: string]: ProductBasicFieldDcl<string> } = {
  email: {
    rules: [
      buildRule('email', 'validations.email_format'),
      buildRule('required', 'validations.required_generic')
    ]
  },
  firstname: {},
  lastname: {},
  phone: {},
  password_login: {
    type: 'password'
  },
  password: {
    type: 'password',
    rules: [
      (val: string) =>
        val.length >= 8 ||
        Vue['i18n'].t('validations.password_length').toString()
    ]
  },
  password_confirmation: {
    type: 'password',
    form_validation: {
      fieldParam: 'password',
      rule: password_confirm,
      message: Vue['i18n'].t('validations.password_match').toString()
    }
  }
};

const locale = 'user';

const useLoginFields = () => {
  const login_fields = ref([
    new ProductTextField('email', locale, user_fields['email']),
    new ProductTextField('password', locale, user_fields['password_login'])
  ]);

  return { login_fields };
};

const useConfirmationFields = () => {
  const confirmationField = ref(
    new ProductTextField('email', locale, user_fields['email'])
  );

  return { confirmationField };
};

const useRegisterFields = () => {
  const register_fields = ref([
    new ProductTextField('firstname', locale, user_fields['firstname']),
    new ProductTextField('lastname', locale, user_fields['lastname']),
    new ProductTextField('email', locale, user_fields['email']),
    new ProductTextField('password', locale, user_fields['password'])
  ]);

  return { register_fields };
};

export { useLoginFields, useRegisterFields, useConfirmationFields };
