






import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'BaseLoading',
  props: {
    size: {
      type: String,
      default: '200px'
    }
  }
});
