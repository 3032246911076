

































































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import { useShareholders } from '../../shared-steps/tools';
import CheckList from '../../base-components/check-list.vue';
import { analyticSelectHolding } from '../../../../analytics';
import {
  productLocale,
  NotaryProduct,
  NotaryProductSlug
} from '@ligo/bv-flow/store';
import InstructionBanner from '../../base-components/instruction-banner.vue';
import { useHoldingUpsell } from './holding_upsell.hooks';
import NewContactCard from '../../../base/NewContactCard.vue';
import BvAlertBanner from '../../../base/BvAlertBanner.vue';
import BvBadge from '../../../base/BvBadge.vue';
import NextButton from '../../../base/NextButton.vue';
import BackButton from '../../../base/BackButton.vue';
import BaseLoading from '../../../base/BaseLoading.vue';
import { analyticGTMEnterSelectHolding } from '../../../../hooks/useBvFlowGTM.hooks';
import { analyticGTMSelectHolding } from '../../../../hooks/useBvFlowGTM.hooks';

const HOLDING_ON = '/questionnaire/images/holding_on.svg';
const HOLDING_OFF = '/questionnaire/images/holding_off.svg';

export default defineComponent({
  name: 'HoldingUpsell',
  components: {
    CheckList,
    InstructionBanner,
    BvAlertBanner,
    NewContactCard,
    BvBadge,
    NextButton,
    BackButton,
    BaseLoading
  },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    }
  },
  setup(props, { emit, root }) {
    {
      const { ableToHold, save, shareholders } = useShareholders(
        props.notary_product
      );

      const forms = ref(null as any);
      const loading = ref(true);

      const {
        load,
        prices,
        t,
        advantangeList,
        disadvantangeList
      } = useHoldingUpsell(props.notary_product.slug as NotaryProductSlug);

      load().then(async () => {
        if (ableToHold.value.length == 0) {
          await save();
          emit('endedStep');
        }
        loading.value = false;
        analyticGTMEnterSelectHolding(
          props.notary_product.locale,
          props.notary_product.temporalStep,
          props.notary_product.slug,
          prices.value.price - prices.value.discount
        );
      });

      window.scrollTo({
        top: 0,
        left: 0,
        behavior: 'smooth'
      });

      const title = computed(() => ({
        dark: t('title')
      }));

      const next = async () => {
        let validation = true;
        for (let i = 0; i < ableToHold.value.length; i++) {
          if (forms.value && forms.value[i])
            validation = validation && (await forms.value[i].validate());
        }
        if (validation) {
          loading.value = true;
          await save();
          loading.value = false;
          emit('endedStep');

          analyticSelectHolding(
            props.notary_product.locale,
            root.$store.state.authentication.accessToken,
            shareholders.value,
            prices.value.price - prices.value.discount
          );
        }
      };

      const alerts = {
        safe: 'alert.safe',
        risk: 'alert.risk'
      };

      const getAlertProps = computed(() => {
        const allActive = ableToHold.value.every(
          (shareholder) => shareholder.form.fields.holding.value === true
        );
        return {
          cardClass: allActive
            ? 'bg-surface-green border-border-green'
            : 'bg-surface-default border-border-default',
          text: allActive ? alerts.safe : alerts.risk,
          iconSrc: allActive ? 'mdi-check-circle' : 'info',
          iconColor: allActive ? 'green' : 'subdued'
        };
      });

      return {
        HOLDING_OFF,
        HOLDING_ON,
        ableToHold,
        advantangeList,
        disadvantangeList,
        title,
        next,
        forms,
        productLocale,
        loading,
        getAlertProps,
        alerts,
        prices,
        t,
        onSelectShareHolder(value) {
          const amountHoldingShareholders = shareholders.value.filter(
            (sh) => sh.form.fields.holding.value
          ).length;
          if (value)
            analyticGTMSelectHolding(
              amountHoldingShareholders,
              prices.value.price - prices.value.discount
            );
        }
      };
    }
  }
});
