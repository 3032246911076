























































import { defineComponent, ref, PropType } from '@vue/composition-api';
import InstructionBanner from '../../base-components/instruction-banner.vue';
import SideMenu from '../../base-components/side-menu/side-menu.vue';
import { useData, setSubstep } from './generic-sections.hooks';
import { analyticGenericSection } from '../../../../analytics';
import {
  productLocale,
  NotaryProduct,
  NotaryProductSlug
} from '@ligo/bv-flow/store';
import NewContactCard from '../../../base/NewContactCard.vue';
import BvCloseDialog from '../../../base/BvCloseDialog.vue';
import {
  analyticGTMSelectShareholders,
  analyticGTMServiceDetails
} from '../../../../hooks/useBvFlowGTM.hooks';
const COMPANY_NAME_KEY = 'company_name';

export default defineComponent({
  name: 'CompanyInfo',
  components: { InstructionBanner, SideMenu, NewContactCard, BvCloseDialog },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    },
    locale: {
      type: String,
      required: true
    },
    sections: {
      type: (Array as unknown) as PropType<Array<Array<Array<string>>>>,
      required: true
    },
    resource: {
      type: String,
      required: true
    },
    booleanDialog: {
      type: (Object as unknown) as PropType<{
        index: number;
        field_name: string;
        trigger_value: boolean;
      }>
    }
  },

  setup(props, { emit, root }) {
    const sideMenu = ref(null as any);
    const {
      sideOptions,
      title,
      subtitle,
      index,
      formsRef,
      formatTitle
    } = useData(
      props.notary_product,
      props.sections,
      props.locale,
      props.resource
    );
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    if (props.locale == 'shareholder_information') {
      analyticGTMSelectShareholders(
        props.notary_product.locale,
        props.notary_product.temporalStep,
        props.notary_product.substep,
        props.notary_product.slug as NotaryProductSlug
      );
    } else
      analyticGTMServiceDetails(
        props.notary_product.locale,
        props.notary_product.temporalStep,
        props.notary_product.substep,
        props.notary_product.slug as NotaryProductSlug
      );
    const baseForm = ref(null as any);
    const next = async () => {
      baseForm.value.setLoadingSubmit(true);
      const next = sideMenu.value.next && sideMenu.value.next();
      await formsRef.value[index.value].value.save(true);

      if (next) {
        index.value++;
        setSubstep(props.notary_product, props.resource, index.value);
        root.$nextTick(() => {
          baseForm.value.resetValidation();
        });
        if (props.locale == 'shareholder_information') {
          analyticGTMSelectShareholders(
            props.notary_product.locale,
            props.notary_product.temporalStep,
            props.notary_product.substep,
            props.notary_product.slug as NotaryProductSlug
          );
        } else {
          analyticGTMServiceDetails(
            props.notary_product.locale,
            props.notary_product.temporalStep,
            props.notary_product.substep,
            props.notary_product.slug as NotaryProductSlug
          );
        }
        analyticGenericSection(
          props.notary_product.locale,
          root.$store.state.authentication.accessToken,
          props.locale,
          index.value,
          props.notary_product.slug
        );
      } else {
        emit('endedStep');
      }

      baseForm.value.setLoadingSubmit(false);
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: 'smooth'
      });
    };
    const changeOption = (i: number) => {
      index.value = i - 1;
      setSubstep(props.notary_product, props.resource, index.value);
      root.$nextTick(() => {
        baseForm.value.resetValidation();
      });
    };

    const onInput = (key, value) => {
      if (key == COMPANY_NAME_KEY) {
        const field =
          props.notary_product.resources['main'].fields[COMPANY_NAME_KEY];
        title.value.light = formatTitle(field, value);
        title.value.useLightText = !!value;
      }
    };

    function onGoBack() {
      if (index.value > 0) changeOption(index.value);
      else emit('previous-step');
    }

    return {
      sideOptions,
      next,
      sideMenu,
      formsRef,
      index,
      title,
      subtitle,
      changeOption,
      baseForm,
      productLocale,
      onInput,
      onGoBack
    };
  }
});
