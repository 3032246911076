

























































































































































































import { defineComponent, ref, computed } from '@vue/composition-api';
import InstructionBanner from '../../base-components/instruction-banner.vue';
import SideMenu from '../../base-components/side-menu/side-menu.vue';
import ShareHolderGroup from '../../base-components/shareholder-group.vue';
import TypeList from '../../base-components/type-list.vue';
import {
  checkDirectorRequirements,
  useData
} from './shareholder-information.hooks';
import { Notify } from 'quasar';
import { Dictionary, getWidth } from '@ligo/shared/utils';
import { analyticSelectShareholders } from '../../../../analytics';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';
import type { ShareholderForms } from '@ligo/bv-flow/store';
import { PropType } from 'vue';
import BvCloseDialog from '../../../base/BvCloseDialog.vue';
import NewContactCard from '../../../base/NewContactCard.vue';
import { analyticGTMSelectShareholders } from '../../../../hooks/useBvFlowGTM.hooks';
import { NotaryProductSlug } from '@ligo/dashboard/store';
import BvAlertBanner from '../../../base/BvAlertBanner.vue';
const SPINNER_SIZE = '200px';

export default defineComponent({
  name: 'ShareholderInformation',
  components: {
    InstructionBanner,
    SideMenu,
    ShareHolderGroup,
    TypeList,
    NewContactCard,
    BvCloseDialog,
    BvAlertBanner
  },
  props: {
    notary_product: {
      type: Object as () => NotaryProduct,
      required: true
    },
    shareholderLen: {
      type: Number,
      default: 4
    },
    shareholderForms: {
      type: Array as () => ShareholderForms | ShareholderForms[],
      required: true
    },
    cardTag: {
      type: String,
      default: 'shareholder'
    },
    addTag: {
      type: String,
      default: 'add_shareholder'
    },
    typesSubset: {
      type: (Array as unknown) as PropType<Array<number>>
    },
    setDefaultActives: {
      type: (Array as unknown) as PropType<Array<number>>,
      default: () => []
    },
    enableAdd: {
      type: Boolean,
      default: true
    },
    enableDeletable: {
      type: Boolean,
      default: true
    },
    singleCardTag: {
      type: Boolean,
      default: true
    },
    cardTagsList: {
      type: (Array as unknown) as PropType<Array<string>>
    },
    typeTitles: {
      type: Array as PropType<Array<string>>
    },
    typesCustomLabels: {
      type: (Array as unknown) as PropType<Array<string>>,
      validator: (value) => {
        return Array.isArray(value);
      }
    },
    showTypesMessage: {
      type: Boolean,
      default: true
    },
    showTypes: {
      type: Boolean,
      default: true
    },
    minimumActives: {
      type: Number,
      default: 1
    }
  },
  setup(props, { emit, root }) {
    const { width } = getWidth();
    const loading = ref(false);
    const dialog = ref<Dictionary>({
      state: false,
      title: '',
      label: ''
    });
    const {
      title,
      subtitle,
      shareholdersList,
      sideOptions,
      activeIndexes,
      getJSONValue,
      getActiveIndexes
    } = useData(
      props.notary_product,
      (() => {
        if (!Array.isArray(props.shareholderForms[0][0]))
          return [props.shareholderForms];
        else return props.shareholderForms;
      })(),
      props.setDefaultActives,
      props.shareholderLen
    );
    const index = ref(0);
    root.$nextTick(() => {
      updateShareholder(index.value);
    });

    // Component Refs
    const sideMenu1 = shareholdersList.value[0]?.sideMenuRef || ref(null);
    const sideMenu2 = shareholdersList.value[1]?.sideMenuRef || ref(null);
    const sideMenu3 = shareholdersList.value[2]?.sideMenuRef || ref(null);
    const sideMenu4 = shareholdersList.value[3]?.sideMenuRef || ref(null);

    const typeList = ref(null as any);
    const shareholders = ref(null as any);

    const form = ref(null as any);
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
    const typeTitle = props.typeTitles ? ref(props.typeTitles[0]) : undefined;

    const selectedShareholder = (i: number) => {
      index.value = i - 1;
      if (
        props.showTypes &&
        shareholdersList.value[index.value].stepSideBar == 0
      ) {
        root.$nextTick(() => {
          typeList.value.externalSelection(
            shareholdersList.value[index.value].getType()
          );
        });
      }
      if (props.typeTitles) {
        typeTitle.value = props.typeTitles[i - 1];
      }
    };
    const deletedShareholder = (i: number) => {
      shareholdersList.value[i - 1].clear();
      if (i - 1 == index.value) index.value = 0;
      activeIndexes.value = getActiveIndexes();
    };
    const createdShareholder = (i: number) => {
      props.notary_product.syncSteps();
      shareholdersList.value[i - 1].clear();
      shareholdersList.value[i - 1].active = true;
      shareholdersList.value[i - 1].save(false, false);
      selectedShareholder(i);
      activeIndexes.value = getActiveIndexes();
    };
    const changeOption = (i: number) => {
      shareholdersList.value[index.value].stepSideBar = i - 1;
      shareholdersList.value[index.value].save(false, false);
      analyticGTMSelectShareholders(
        props.notary_product.locale,
        props.notary_product.temporalStep,
        shareholdersList.value[index.value].stepSideBar,
        props.notary_product.slug as NotaryProductSlug
      );
    };

    function goBack() {
      const menuIndex = shareholdersList.value[index.value].stepSideBar;
      if (index.value == 0 && menuIndex <= 0) emit('previous-step');
      else if (menuIndex > 0) changeOption(menuIndex);
      else {
        updateShareholder(index.value - 1);
        analyticGTMSelectShareholders(
          props.notary_product.locale,
          props.notary_product.temporalStep,
          shareholdersList.value[index.value].stepSideBar,
          props.notary_product.slug as NotaryProductSlug
        );
      }
    }

    const selectedType = (i: number, update: boolean) => {
      if (update) {
        props.notary_product.syncSteps();
      }
      shareholdersList.value[index.value].setType(i - 1);
      if (props.typeTitles) {
      }
    };
    const getType = computed(() => {
      return shareholdersList.value[index.value].getType();
    });
    const getForm = computed(() => {
      root.$nextTick(() => {
        form.value.resetValidation();
      });

      return shareholdersList.value[index.value].getMainForm().value;
    });

    analyticGTMSelectShareholders(
      props.notary_product.locale,
      props.notary_product.temporalStep,
      shareholdersList.value[index.value].stepSideBar,
      props.notary_product.slug as NotaryProductSlug
    );

    // Next Step
    const next = async () => {
      const requestedAt = index.value;
      // Component Reference for next Shareholder's questions in ShareholdersList
      const next = shareholdersList.value[
        index.value
      ].sideMenuRef.value[0].next();

      // If the current shareholder is not at the last shareholder,
      // move forwards to the next shareholder
      if (next) {
        shareholdersList.value[index.value].stepSideBar++;
        await shareholdersList.value[index.value].save(true, false);
        props.notary_product.syncSteps();
        analyticGTMSelectShareholders(
          props.notary_product.locale,
          props.notary_product.temporalStep,
          shareholdersList.value[index.value].stepSideBar,
          props.notary_product.slug as NotaryProductSlug
        );
      } else {
        // The current shareholder is the last one
        if (requestedAt >= activeIndexes.value.length - 1) {
          let checkAll = true;
          let atLeastOneDirector = false;

          atLeastOneDirector = activeIndexes.value.some(
            (index) => !!getJSONValue(index)['become_director']
          );

          //  Check if all the shareholder's forms are on the last step,
          // otherwise, move to the first incomplete shareholder
          for (let ind = 0; ind < activeIndexes.value.length; ++ind) {
            const i = activeIndexes.value[ind];
            index.value = i;
            let x = shareholdersList.value[i];
            if (x.active) {
              void (await root.$nextTick());
              let validate = await form.value.validate();
              if (!validate || x.stepSideBar < 2) {
                checkAll = false;
                shareholders.value.externalSelection(
                  shareholdersList.value[i].index
                );
                break;
              }
            }
          }

          if (
            checkAll &&
            (!checkDirectorRequirements(props.notary_product.slug) ||
              atLeastOneDirector)
          ) {
            // Actually Move To The Next Step
            loading.value = true;
            await props.notary_product.save(true, { sendStep: true });
            analyticSelectShareholders(
              props.notary_product.locale,
              root.$store.state.authentication.accessToken,
              shareholdersList.value
            );
            emit('endedStep');
            loading.value = false;
          } else if (checkAll) {
            // Show Director's Requirement Dialog
            dialog.value.title = 'global_dialogs.mandatory_director.title';
            dialog.value.label = 'global_dialogs.mandatory_director.label_cta';
            dialog.value.state = true;
            return;
          } else {
            // Show Incomplete Shareholder Notification
            Notify.create({
              message: root.$i18n
                .t(
                  productLocale(
                    props.notary_product,
                    'shareholder_information.complete_shareholder'
                  )
                )
                .toString(),
              color: 'negative',
              position: 'top',
              icon: 'mdi-alert'
            });
            return;
          }
        } else {
          // Save and move to the next shareholder's form
          updateShareholder(index.value + 1);
          analyticGTMSelectShareholders(
            props.notary_product.locale,
            props.notary_product.temporalStep,
            shareholdersList.value[index.value].stepSideBar,
            props.notary_product.slug as NotaryProductSlug
          );
        }
      }
      window.scrollTo({
        top: 100,
        left: 100,
        behavior: 'smooth'
      });
    };

    function updateShareholder(newIndex: number) {
      index.value = newIndex;
      const shareholder = shareholdersList.value[index.value];
      shareholders.value.externalSelection(shareholder.index);
      if (typeList.value)
        typeList.value.externalSelection(shareholder.getType());
    }
    return {
      dialog,
      sideMenu1,
      sideMenu2,
      sideMenu3,
      sideMenu4,
      typeList,
      shareholders,
      form,
      title,
      subtitle,
      sideOptions,
      shareholdersList,
      index,
      width,
      activeIndexes,
      selectedShareholder,
      createdShareholder,
      deletedShareholder,
      changeOption,
      selectedType,
      getType,
      getForm,
      next,
      productLocale,
      typeTitle,
      goBack,
      loading,
      NotaryProductSlug,
      SPINNER_SIZE
    };
  }
});
