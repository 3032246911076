import { NotaryProduct } from '@ligo/bv-flow/store';
import { Dictionary, RootContext } from '@ligo/shared/utils';
import { onMounted, Ref, ref } from '@vue/composition-api';
import { goBack } from '../tools/handler-tools';
import {
  getPathFromRouteName,
  useNotaryProduct
} from 'libs/bv-flow/store/src/lib/models/NotaryProduct';

export function useLoadProduct(
  root: RootContext,
  routeName: string,
  uid: string,
  initialValues: Dictionary,
  calculationUuid?: string
) {
  const path = getPathFromRouteName(routeName);
  const notaryProduct: Ref<NotaryProduct> = ref(useNotaryProduct(path, uid));
  const handler = ref(null as any);
  const loading = ref(true);

  onMounted(async () => {
    if (!uid) {
      const data = calculationUuid ? { calculation_uuid: calculationUuid } : {};
      // Initialize the product if accessing the page without product uid
      await notaryProduct.value.create(data);

      // Allow with initialValues props for fill some fields inside the main product object
      Object.keys(initialValues).forEach((key) => {
        if (!!notaryProduct.value.resources['main'].fields[key])
          notaryProduct.value.resources['main'].values[key] =
            initialValues[key];
      });

      // Patches the step and other default info of the notary product
      await notaryProduct.value.save(true, { sendStep: false });

      // Adds product uid to the route
      await root.$router.replace({
        name: routeName,
        params: {
          uid: notaryProduct.value.uuid
        }
      });
    } else {
      const loggedIn = root.$store.state.authentication.accessToken
        ? true
        : false;
      await notaryProduct.value.load(undefined, loggedIn);
    }

    reload();
    loading.value = false;
  });

  const reload = () => {
    if (handler.value) {
      handler.value.reload();
    }
  };

  const productGoBack = () => {
    goBack(notaryProduct.value.uuid, root, notaryProduct.value);
  };

  return {
    handler,
    notary_product: notaryProduct,
    loading,
    reload,
    productGoBack
  };
}
