import { Dictionary } from '@ligo/shared/utils';
import {
  CalculatorBasicFieldDcl,
  TransitionCondition
} from '../calculator/CalculatorField';

export enum SideMenuNavigation {
  form,
  settings
}

export interface LegalCheckupType {
  id?: number;
  uuid?: string;
  slug: string;
  configuration: LegalCheckupConfiguration;
  name: string;
  description: string;
  status: string;
  updated_at?: Date;
  created_at?: Date;
}

export enum productTypeOptions {
  Contract = 'Contract',
  NotaryProduct = 'NotaryProduct'
}

export interface Recommendation {
  conditions?: TransitionCondition[];
  product_type: keyof typeof productTypeOptions;
  product_type_slug: string;
}

export interface LegalCheckupConfiguration {
  fieldDescriptions: Dictionary<CalculatorBasicFieldDcl<any>>;
  recommendations: Recommendation[];
}
