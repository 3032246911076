export default {
  login: {
    success: 'We zijn blij je terug te hebben',
    error: 'Er zijn problemen opgetreden met uw inloggegevens'
  },
  register: {
    success: 'Welkom bij Ligo',
    error: 'Er is een probleem opgetreden met uw gegevens'
  },
  confirmation: {
    success: 'Uw account is bevestigd !!',
    error: 'Er zijn problemen opgetreden met uw inloggegevens'
  },
  general: {
    error: 'Actie: mislukt',
    success: 'Actie was succesvol'
  },
  holding_step: {
    safe: 'U heeft uw juridisch risico verkleind.',
    risk:
      'Meer weten over de voordelen van een holding? Onze juridisch adviseurs staan ​​7 dagen per week voor u klaar via de chat om u te adviseren.'
  },
  checkout: {
    success:
      'Alles staat klaar voor je nieuwe bedrijf! Geen zorgen. Na betaling kan je de ingevulde gegevens nog aanpassen.'
  }
};
