const date = new Date();
const this_year = `31-12-${date.getFullYear().toString()}`;
const next_year = `31-12-${(date.getFullYear() + 1).toString()}`;

export default {
  labels: {
    company_name: 'Naam van de BV waarvan aandelen worden overgedragen',
    email: 'Emailadres contactpersoon',
    headquarters: 'Statutaire zetel',
    office_address: 'Adres',
    place_of_business: '',
    postal_code: '',
    number_of_shares: 'Hoeveel aandelen draagt de verkoper over aan de koper?',
    value_of_shares: 'Wat is de nominale waarde van elk aandeel?',
    custom_notary: 'Notaris die de legalisatie zal verzorgen',
    custom_notary_city: 'Jouw gebied',
    kvk: 'KvK-nummer',
    purchase_agreement:
      'Is er al een koopovereenkomst getekend door koper en verkoper voor de aandelenoverdracht?',
    purchase_price_received:
      'Heeft verkoper de koopprijs van de aandelen al ontvangen van koper?',
    old_acquistion: 'Wanneer is verkoper eigenaar geworden van de aandelen?',
    old_acquistion_date:
      'Wat is de datum van de akte van aandelenoverdracht waaronder de verkoper de aandelen heeft verkregen?',
    received_shares:
      'Heeft de verkoper de aandelen verkregen bij oprichting van de BV?',
    received_shares_date:
      'Op welke datum heeft de verkoper de aandelen verkregen?',
    incorporation_date: ' Wat is de datum van de oprichting van de BV?',
    statutes_adopted_date:
      'Wat is de datum waarop de statuten van de BV zijn vastgesteld?',
    shares_rights_date:
      ' Vanaf welke datum gaan de lusten en lasten van de aandelen over van de verkoper naar de koper?',
    transfer_rights_date:
      'Op welke datum gaan de lusten en lasten van de aandelen over op de koper?',
    notarial_costs:
      'De notariële kosten voor de aandelenoverdracht komen voor rekening van:',
    shares_from: 'Van',
    shares_to: 'Naar',
    share_type: 'Om wat voor type aandelen gaat het?',
    number_shares: 'Wat zijn de nummers van de over te dragen aandelen?',
    number_shares_tooltip:
      'n het aandeelhoudersregister van de BV kun je de nummers vinden van de aandelen van elke aandeelhouder. Als verkoper aandeelhouder is geworden bij oprichting van de BV, staat het ook vermeld in de statuten.',
    share_price_details:
      'Licht hier zo uitgebreid mogelijk toe hoe de prijs van de te verkopen aandelen is berekend:',
    total_shares: 'Totaal aantal aandelen in de onderneming',
    total_shares_validation:
      'De ingevoerde waarde is groter dan het totaal. Wijzig het veld voor het aantal aandelen als u die waarde invoert',
    accountant:
      'Heb je een schriftelijke verklaring van een accountant (R/A of AA) dat de prijs van de aandelen reëel is?',
    free_intake: 'Wil je een gratis intakegesprek met een Ligo accountant?',
    balance_sheet:
      'Is er een balans beschikbaar van de onderneming die is opgesteld als onderdeel van de jaarrekening?',
    date_of_balance:
      'Wat is de datum van de balans waarop de koopprijs van de aandelen is gebaseerd?',
    lead_approval:
      'Wil je gratis en vrijblijvend 3 offertes te ontvangen van door ons geselecteerde boekhouders uit uw regio?'
  },
  placeholders: {
    email: 'Emailadres contactpersoon',
    headquarters: 'Vul de plaats in waar het hoofdkantoor is gevestigd',
    office_address: 'Vul de straat, huisnummer en een eventuele toevoeging in',
    place_of_business: 'Vestigingsplaats',
    company_name: 'Naam van BV',
    holding_name: 'Type here your holding name',
    postal_code: 'Vul de postcode in',
    value_of_shares: 'Vul een waarde in',
    enter_number: 'Voer nummer in',
    number_of_shares: '',
    shares: 'Voer nummer in',
    custom_notary:
      'Vermeld hier de naam, plaats en telefoonnummer van de notaris die de legalisatie zal uitvoeren:',
    custom_notary_city:
      'Wat is de plaats waar je graag bij een notaris langs wil gaan?',
    kvk: '',
    purchase_agreement: '',
    purchase_price_received: '',
    old_acquistion: '',
    old_acquistion_date: '',
    received_shares: '',
    received_shares_date: '',
    incorporation_date: '',
    statutes_adopted_date: '',
    shares_rights_date: '',
    transfer_rights_date: '',
    notarial_costs: '',
    shares_from: '',
    shares_to: '',
    share_type: '',
    shares_price_expl: '',
    total_shares: '',
    share_price_details: ''
  },
  options: {
    accountant: {
      yes: 'Ja',
      no: 'Nee',
      will_be_arranged_soon: 'Ik regel dit zo snel mogelijk'
    },
    sell_products: {
      consumers: 'Aan consumenten',
      companies: 'Aan ondernemingen',
      none: 'Niet van toepassing',
      consumers_and_businesses: 'Aan zowel consumenten als ondernemingen'
    },
    where_are_sold: {
      store_or_kiosk: 'In een winkel of kiosk',
      market: 'Op de markt',
      street_trade: 'Via straathandel',
      internet: 'Via internet',
      from_home: 'Vanuit huis',
      postorder: 'Per postorder',
      not_applicable: 'Niet van toepassing'
    },
    representation: {
      one_signature:
        'Een handtekeningenstelsel waarbij iedere bestuurder zelfstandig bevoegd is',
      two_signatures:
        'Een handtekeningenstelsel waarbij 2 gezamelijk handelende bestuurders bevoegd zijn'
    },
    financial_year_end: {
      this_year: this_year,
      next_year: next_year
    },
    shares_rights_date: {
      date_of_transfer: 'De datum waarop notariele akte wordt ondertekend',
      other_date: 'Een andere datum'
    },
    notarial_costs: {
      seller: 'Verkoper',
      buyer: 'Koper'
    },
    share_type: {
      common_stock: 'Gewone',
      cumulative_shares: 'Cumulatieve',
      preference_shares: 'Preferente',
      priority_stocks: 'Prioriteitsaandelen'
    },
    old_acquistion: {
      before_1993: 'vóór het jaar 1993',
      after_1993: 'na het jaar 1993'
    }
  },
  label_tooltip: {
    accountant:
      'Voor een aandelenoverdracht is een accountantsverklaring nodig over de waarde van de aandelen. Zo controleert een notaris of aandelen niet frauduleus (te goedkoop) worden verkocht. Heb je deze verklaring nog niet? Je kunt dit gemakkelijk via Ligo regelen. Neem contact op met Ligo’s support team via de chat of 020 303 1043.',
    headquarters:
      "De statutaire zetel is vaak hetzelfde als de vestigingsplaats van de BV en bepaalt welke rechter bevoegd is bij een geschil. De zetel wordt ook wel de formele 'woonplaats' van de BV genoemd en is opgenomen in je statuten.",
    purchase_agreement:
      'Het is mogelijk dat een aparte overeenkomst de afspraken over de koop van de aandelen regelt, maar dit is niet verplicht. Denk hierbij aan de prijs en voorwaarden. Het overdragen van de eigendom van de aandelen kan alleen maar via een notaris. Het maakt voor de kosten bij Ligo niet uit.',
    old_acquistion:
      'De regels voor aandelenoverdrachten waren voor het jaar 1993 anders. Op het moment dat de verkoper de aandelen vóór of na het jaar 1993 heeft verkregen, moeten er daarom andere bepalingen worden opgenomen in de akte.',
    old_acquistion_date:
      'Deze datum kan je vinden in de akte van overdracht waarmee de aandelen toen zijn overgedragen.',
    incorporation_date:
      'Deze datum kan je vinden in de akte van oprichting van de BV.',
    statutes_adopted_date:
      'Deze datum kan je in het KvK uittreksel vinden. Dit kan bijvoorbeeld de datum van de oprichtingsakte zijn. Als je later een statutenwijziging hebt gehad, vul je hier de datum in van deze statutenwijziging.',
    shares_rights_date:
      "Lusten en lasten van de aandelen zijn kort gezegd de economische voordelen (zoals dividend) en risico's (zoals waardevermindering). Normaal gesproken gaan deze lusten en lasten over op de datum van de aandelenoverdracht. Maar deze lusten en lasten mogen ook vóór of na de ondertekening van de akte worden overgedragen. Je kunt dit regelen met een koopovereenkomst, zonder tussenkomst van de notaris.",
    share_price_details:
      'Probeer hier zo duidelijk mogelijk aan te geven hoe de koopprijs van de aandelen tot stand is gekomen. Als jouw accountant of fiscalist hierbij geholpen heeft, geef dit dan ook aan.',
    value_of_shares:
      'Dit kun je in het aandeelhoudersregister of de statuten van de BV vinden',
    balance_sheet:
      'Voor elk boekjaar dient de BV een jaarrekening op te maken. De balans is onderdeel van de jaarrekening. Indien de BV kort geleden is opgericht, is het mogelijk dat er nog geen balans is opgemaakt. Kies hier dan “Nee”.'
  }
};
