export default {
  almost_ready: 'Almost finished: Choose your notary',
  text1:
    'Verspreid over Nederland zijn onafhankelijke notarissen aangesloten bij Ligo. <br/> Zit er bij jou geen Ligo notaris in de buurt? Geen probleem. Je kunt naar een notaris bij jou in de buurt om je te identificeren en de volmacht te ondertekenen. Deze notaris zal meestal tussen de €25 en €50 rekenen voor deze identificatie. Een Ligo notaris zal de notariële akte daarna passeren. Nadat de betaling is afgerond, zal een Ligo notaris contact met je opnemen om de stappen te doorlopen.',
  text2:
    'Is there no Ligo notary in your area? No problem. You can go to a notary in your area to identify yourself and sign the power of attorney. This notary will usually charge between €25 and €50 for the identification. A Ligo notary will then execute the notarial deed. After the payment is completed, a Ligo notary will contact you to go through the steps.',
  notary_question:
    'Which notary would you like to visit for identification and to sign your power of attorney (legalization)?',
  aclaration:
    'If you visit a Ligo notary, no extra costs are involved. Would you prefer to visit a notary in your area? Keep in mind that it costs between €25 and €50 for the legalization.',
  choose: 'Kies de Ligo notaris waarbij je de akte wilt laten passeren:',
  label1: 'At a Ligo notary',
  label2: 'At another notary in my area',
  label3: 'Ik weet al bij welke notaris in de buurt ik langs wil',
  disclaimer:
    'Find below which notary offers 100% online incorporation. You won’t have to visit the notary in person in that case. Please note: this is not an option for foreign incorporators. In addition, the notary can still ask you to visit the notary’s office in specific circumstances.',
  subtitle:
    'You can choose a notary who will do the service 100% online, or visit the notary.',
  info:
    'Please note: for 100% online incorporations, a civil-law notary may require you to visit the office in a very limited number of cases. Are you setting up your BV with foreign shareholders? In that case, the notary may indicate that 100% online incorporation is not possible. Contact our support team to know more.',
  online: 'Remote online notarization',
  online_pros: [
    'Fast',
    'No travel costs',
    'Easy via video call'
  ],
  in_person: 'In person',
  in_person_pros: ['Personal meeting at office']
};
