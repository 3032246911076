export default {
  titleA: 'Verlaag je DGA-salaris van ',
  titleB: '€51.000',
  titleC: ' en regel je DGA loonadministratie',
  subtitleA: 'Krijg',
  subtitleB: '€50 korting op je DGA salarisadministratie!',
  subtitleC: '',
  more_info: 'More Info',
  aclaration1: 'DGA’s moeten maandelijks hun salaris administreren',
  aclaration2: 'Dit regelen we voor je:',
  dga_for: 'DGA salarisadministratie voor',
  discount: 'korting op jouw DGA salarisadministratie ',
  saving_money: 'besparen tijd en geld',
  our: 'Onze',
  happy: ' tevreden',
  customers: 'klanten',

  list1: 'We bekijken met je wat jouw DGA-salaris moet zijn',
  list2:
    'Elke maand verzorgen we de salarisadministratie en sturen dit naar de Belastingdienst',
  list3: 'Elke maand ontvang je jouw loonaangifte en loonstrook',
  saves_tooltip: 'Selecteer nu en krijg ',
  saves_tooltip_highlighted: '€50 korting op je DGA salaris administratie!',
  selection_header:
    'Welke aandeelhouders willen salarisadministratie met korting? Selecteer hier.',
  dga_explanation:
    'Als directeur-grootaandeelhouder (DGA) betaal je jezelf €51.000 p/j als salaris, tenzij je verlaging aanvraagt. Je stelt maandelijks loonaangiftes op en regelt je loonadministratie. Veel ondernemers besteden deze zaken uit. Hoe helpen we je hierbij?',
  why_dga: 'Waarom is het DGA-salaris zo belangrijk?',
  important1: 'Voorkom claims van de Belastingdienst',
  important2: 'Houd je belastingdruk zo laag mogelijk',
  important3: 'Regels gelden al in eerste maand na oprichting',
  happy_customer: 'Onze tevreden klanten besparen tijd en geld'
};
