export default {
  title: 'Preview Deed',
  note: 'Note :',
  note_text: ' This is just a representation of the deed',
  list: 'List of Documents',
  d1: 'Draft Deed of Incorporation',
  d2: 'Explanation of the contents of the Draft Deed',
  d3: "Shareholders' register",
  d4: 'Personal Datacard for the Dutch Chamber of Commerce',
  btn_download: 'Download',
  btn_subtitle: 'You will be able to download the documents after payment.',
  next_step_title: 'Next Step',
  next_step_description:
    'After payment, you will be asked to upload the required documents. Once all documents are uploaded, the notary will contact you for signing the incorporation.',
  previous: 'Previous',
  next: 'Next',
  name: 'Preview documents'
};
