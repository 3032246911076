






















import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'ServiceCard',
  props: {
    icon: {
      type: String,
      required: true
    },
    name: {
      type: String,
      required: true
    },
    link: {
      type: String,
      required: true
    },
    internalLink: {
      type: Boolean,
      default: true
    },
    type: {
      type: String
    }
  }
});
