export default {
  titleA: 'Focus on setting up ',
  titleC: ', and leave DGA salary administration to us',
  more_info: 'More Info',
  aclaration1: 'DGA’s need to do salary administration every month.',
  aclaration2: 'This is how we arrange it for you:',
  dga_for: 'DGA for',
  discount: 'discount on your incorporation by adding DGA',
  saving_money: 'are saving time and money',
  our: 'Our',
  happy: ' happy',
  customers: ' customers',
  saves_tooltip: 'Start now and get',
  saves_tooltip_highlighted: '€50 discount on your DGA salary administration!',
  list1: 'We will help you determine a minimum DGA salary',
  list2:
    'We save you time by doing your monthly payroll paperwork and submissions to the tax authorities',
  list3: 'We send your payslip and payroll declaration summary every month.',
  selection_header:
    'Select a checkbox to get salary administration for the shareholder',
  dga_explanation:
    'Under Dutch law, once your BV or personal holding company is incorporated, you will become the director-major shareholder (DGA) and will be subject to pay a DGA salary of €51.000 . With that, you are required to take care of monthly payroll tax return and monthly pay slips.\
  \n\nNot to worry though, we have got you covered. For one flat fee, we will take care of the next 12 months of DGA salary administration for you. \
  \n\nHere’s what we do for you over the next 12 months:',
  why_dga: 'Why the DGA Salary is so important?',
  important1: 'Avoid legal claims from the tax authorities',
  important2: 'Minimize your tax burden',
  important3: 'Be able to rightfully pay shareholders a salary',
  happy_customer: 'Our happy customers are saving time and money'
};
