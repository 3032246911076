





























import { defineComponent, ref } from '@vue/composition-api';
import DrawerToolbar from '../components/layout/DrawerToolbar.vue';
import { useLocaleTools } from '../components/layout/locale-hooks';
import BvFooter from '../components/layout/BvFooter.vue';
import Toolbar from '../components/layout/Toolbar.vue';

export default defineComponent({
  name: 'MockLayout',
  components: {
    Toolbar,
    BvFooter,
    DrawerToolbar
  },

  setup(_, { root }) {
    const drawerToolbar = ref(false);
    const { i18nSwitch, changeLanguage } = useLocaleTools(root);
    const loading = ref(true);
    root.$store.dispatch('pageInformation/loadPage', 'calculator').then(() => {
      loading.value = false;
    });
    const enableSwitch = ref(true);
    return {
      drawerToolbar,
      i18nSwitch,
      changeLanguage,
      enableSwitch,
      loading
    };
  }
});
