import { NotaryProduct } from '@ligo/bv-flow/store';
import { RootContext } from '@ligo/shared/utils';

export const goBack = (
  uid: string,
  root: RootContext,
  notaryProduct: NotaryProduct
) => {
  if (uid) {
    if (notaryProduct.temporalStep == 0) {
      if (notaryProduct.calculationUuid)
        void root.$router.push({
          name: 'Calculator',
          params: {
            uuid: notaryProduct.calculationUuid
          }
        });
      else void root.$router.push({ name: 'home' });
    } else {
      notaryProduct.prevStep();
    }
  } else {
    void root.$router.push({ name: 'home' });
  }
};

export const goBackAndSync = (
  uid: string,
  root: RootContext,
  notaryProduct: NotaryProduct
) => {
  goBack(uid, root, notaryProduct);
  notaryProduct.syncSteps();
};

export const notaryProductPath = (slug: string, uuid: string) => {
  return '/questionnaire/' + slug + '/' + uuid;
};
