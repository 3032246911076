





import { defineComponent, onMounted } from '@vue/composition-api';
import { initAnalytics } from '@ligo/shared/analytics';
import { initIntercom, Intercom } from '@ligo/shared/utils';
import { UserService } from '@ligo/bv-flow/services';
import { useEnvironment } from '../../../netlify_functions';
import { useAwaitDOMNode } from '@ligo/shared/utils';

const INTERCOM_CLASS = '.intercom-launcher';

export default defineComponent({
  name: 'App',
  setup(_, { root }) {
    UserService.mountInterceptor();
    onMounted(() => {
      useAwaitDOMNode([INTERCOM_CLASS]).then(() => {
        initIntercom(useEnvironment().INTERCOM_KEY);
        Intercom.identifyUser(root.$store.state.authentication.accessToken);
        Intercom.update();
      });
    });
  },

  beforeCreate() {
    initAnalytics(useEnvironment().SEGMENT_KEY);
  }
});
