export default {
  login: {
    success: 'We are glad to have you back',
    error: 'There has been some trouble with your credentials'
  },
  register: {
    success: 'Welcome to Ligo',
    error: 'There has been a problem with your data'
  },
  confirmation: {
    success: 'Your account has been confirmed!!',
    error: 'There has been some trouble with your credentials'
  },
  general: {
    error: 'Action failed',
    success: 'Action was successful'
  },
  holding_step: {
    safe: 'You have reduced your legal risk.',
    risk:
      'Want to know more about the benefits of a holding? Our legal advisors are available on chat 7 days a week to advise you.'
  },
  checkout: {
    success:
      "Everything is ready for your new business! Don't worry. You can still change the details after payment."
  }
};
