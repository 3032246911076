

























































import { defineComponent, PropType } from '@vue/composition-api';
import { useAuthTools } from './auth-hooks';
import BaseI18nSwitch from '../base/BaseI18nSwitch.vue';

export default defineComponent({
  name: 'DrawerToolbar',
  components: {
    BaseI18nSwitch
  },
  props: {
    value: Boolean,
    enableSwitch: Boolean,
    i18nSwitch: Object as PropType<{
      en: { active: boolean; language: string };
      nl: { active: boolean; language: string };
    }>
  },
  setup(_, { root }) {
    const {
      loggedIn,
      username,
      profileImage,
      logout,
      attach_company_name,
      goToDashboard,
      goTo
    } = useAuthTools(root);

    return {
      loggedIn,
      username,
      profileImage,
      logout,
      attach_company_name,
      goToDashboard,
      goTo
    };
  }
});
