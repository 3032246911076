









































import { defineComponent, ref, PropType } from '@vue/composition-api';
import { useChart } from './pie-chart.hooks';
import InstructionBanner from '../../base-components/instruction-banner.vue';
import { analyticSelectValueOfShares } from '../../../../analytics';
import { NotaryProduct, productLocale } from '@ligo/bv-flow/store';
import NewContactCard from '../../../base/NewContactCard.vue';
import { analyticGTMSelectValueOfShares } from '../../../../hooks/useBvFlowGTM.hooks';
import BvCloseDialog from '../../../base/BvCloseDialog.vue';

export default defineComponent({
  name: 'PieChart',
  components: { InstructionBanner, NewContactCard, BvCloseDialog },
  props: {
    notary_product: {
      type: Object as PropType<NotaryProduct>,
      required: true
    }
  },
  setup(props, { emit, root }) {
    const form = ref(null as any);
    const { transferOfSharesForm, isReady, t, computedTitle } = useChart(
      props.notary_product
    );

    analyticGTMSelectValueOfShares(
      props.notary_product.locale,
      props.notary_product.temporalStep,
      props.notary_product.slug
    );

    const next = async () => {
      const valid = await form.value.validate();
      if (valid) {
        await transferOfSharesForm.value.save();
        analyticSelectValueOfShares(
          props.notary_product.locale,
          root.$store.state.authentication.accessToken
        );
        emit('endedStep');
      }
    };
    return {
      form,
      transferOfSharesForm,
      computedTitle,
      productLocale,
      next,
      t,
      isReady
    };
  }
});
