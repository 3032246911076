import { EmitFunction, RootContext } from './types';

export const textToLowerCase = (text: string) => {
  return text.toLowerCase();
};

export const forcedLanguage = (
  root: RootContext,
  locale?: string,
  url = '/',
  emit?: EmitFunction,
  locales = ['nl', 'en-us', 'en']
) => {
  if (locales.includes(locale)) {
    root.$i18n.locale = locale;
    localStorage.setItem('locale', locale);
    if (emit) emit('changeState', true, root.$i18n.locale, url);
  } else {
    if (emit) emit('changeState', false, root.$i18n.locale, url);
  }
};
