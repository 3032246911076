import billing_information from './models/billing_information';
import billing_info from './steps/billing_info';
import preview_order from './steps/preview_order';

const incorporate_holding = {
  billing: billing_information,
  billing_info: billing_info,
  preview_order: preview_order
};

export { incorporate_holding };
