








































import { computed, defineComponent } from '@vue/composition-api';
import Rating from './Rating.vue';
import { getStoryblokReviews } from '@ligo/shared/storyblok/services';

const REVIEW_AMOUNT = '100.000+';
const RATING_BASE = 5;
const RATING_CONST = {
  google: 4.5,
  kyioh: 4.8
};

const LIKE_IMG = '/questionnaire/images/like.svg';
const SATISFIED_IMG = '/questionnaire/images/satisfied.svg';
const GOOGLE_IMG = '/questionnaire/images/google.svg';
const SIZE_IMG = '32px';
export default defineComponent({
  name: 'RatingsCard',
  components: { Rating },
  props: {
    bordered: {
      type: Boolean,
      default: false
    },
    showOnlyKyiho: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const { reviews } = getStoryblokReviews();

    function getRatingInfo(params: 'google' | 'kyioh') {
      return {
        text: (reviews.value && reviews.value[params]?.text) || '272 reviews',
        rating:
          (reviews.value &&
            (parseFloat(reviews.value[params]?.score) * RATING_BASE) /
              parseFloat(reviews.value[params]?.base)) ||
          RATING_CONST[params]
      };
    }

    return {
      kyiohRating: computed(() => {
        return getRatingInfo('kyioh');
      }),
      googleRating: computed(() => {
        return getRatingInfo('google');
      }),
      REVIEW_AMOUNT,
      LIKE_IMG,
      SATISFIED_IMG,
      GOOGLE_IMG,
      SIZE_IMG
    };
  }
});
