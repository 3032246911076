import { ApiService } from './../../../../../../shared/utils/src/lib/services/api.service';
import { RESOURCES } from '@ligo/bv-flow/store';
import {
  BasicFieldType,
  FieldSet,
  Resource,
  ResourceConfig
} from '@ligo/shared/mvc';
import {
  CalculatorDcl,
  OriginCalculatorType,
  TransitionCondition
} from '../calculator/CalculatorField';
import {
  insertWithDot,
  nully,
  strictTrue,
  Dictionary,
  isHash,
  isEmptyHash
} from '@ligo/shared/utils';
import { LegalCheckup } from './LegalCheckup';

export type CalculatorFieldSet<T> = {
  [P in keyof T]?: CalculatorDcl<T[P]>;
};

export function assert(value, conditionType, match?) {
  switch (conditionType) {
    case 'not_answered':
      return nully(value);
    case 'positive':
      return value;
    case 'negative':
      return value === false;
    case 'equals':
      return value == match;
    case 'includes':
      return value.includes(match);
  }
}

function cleanValues(hash: Dictionary<any>, dirtyKeys: string[]) {
  Object.keys(hash).forEach((key) => {
    if (nully(hash[key]) || dirtyKeys.includes(key) || isEmptyHash(hash[key]))
      delete hash[key];
    else if (isHash(hash[key])) cleanValues(hash[key], dirtyKeys);
  });
}

export class LegalCheckupResource extends Resource<LegalCheckup> {
  uuid: string;
  slug: string;
  fields: CalculatorFieldSet<any>;
  status: string;
  origin: OriginCalculatorType;
  answered: string[];
  currentQuestions: string[];
  url = RESOURCES.CALCULATION;

  constructor(resourceConfig: ResourceConfig<LegalCheckup>, uuid?: string) {
    super(resourceConfig, 'calculator', '', uuid);
    this.values = {};
    this.fields = resourceConfig.fieldsDescriptions as CalculatorFieldSet<any>;
    this.answered = [];
    this.url = resourceConfig.url;
    this.slug = resourceConfig.slug;
    this.currentQuestions = [];
  }

  async performLoad(): Promise<void> {
    const response = await ApiService.get<LegalCheckup>(
      `${this.url}/${this.uuid}`
    );
    this.updateFromJSON(response.data.form_data);
    this.status = response.data.status;
    this.loadAnswers();
  }

  linkToContinue() {
    return `questionnaire/legal-scan/${this.slug}/${this.uuid}`;
  }

  getFields() {
    return this.fields as FieldSet<any>;
  }

  async performSave(): Promise<void> {
    if (this.uuid) {
      const response = await ApiService.patch<{ form_data: any }, LegalCheckup>(
        `${this.url}/${this.uuid}`,
        { form_data: this.values }
      );
      this.updateFromJSON(response.data.form_data);
    } else {
      await this.create();
    }
  }

  async create() {
    const response = await ApiService.post<any, LegalCheckup>(this.url, {
      legal_checkup_type_slug: this.slug,
      form_data: this.values
    });
    this.uuid = response.data.uuid;
    this.loadAnswers();
    return response;
  }

  getValues() {
    return this.values;
  }

  getLocale() {
    return `${this.locale}`;
  }

  updateFromJSON(data: any) {
    if (data) {
      const keys = Object.keys(data);
      keys.forEach((key) => {
        if (!nully(data[key])) {
          const valueKey = key.split('.').pop();
          if (!nully(this.fields[valueKey])) {
            const field = this.fields[valueKey] as BasicFieldType;
            const value =
              field.type == 'number' ? data[key].toString() : data[key];
            insertWithDot(this.values, key, value);
          }
        }
      });
    } else {
      this.values = {};
    }
  }

  nextQuestions(key: string): string[] {
    return this.nextFieldKeys(key);
  }

  // If the condition is true for at least one shareholder, it will be considered true
  determineCondition(condition: TransitionCondition): boolean {
    const values = Object.values(
      this.getFieldValues(condition.fieldKey)
    ) as any[];
    return values.reduce((result, value) => {
      return result || assert(value, condition.transitionType, condition.value);
    }, false);
  }

  async onAnswer(key: string, data) {
    if (this.answered.includes(key)) {
      const index = this.answered.indexOf(key);
      this.cleanData(this.answered.slice(index + 1));
      this.status = 'open';
      this.currentQuestions = [];
      this.loadAnswers();
    } else {
      this.answered.push(key);
    }
    this.updateFromJSON(data);
    const nextKeys = this.nextQuestions(key);
    if (nextKeys) {
      this.performSave();
      this.updateCurrentQuestions(key, nextKeys);
    } else {
      this.removeQuestion(key);
      await this.performSave();
      if (this.currentQuestions.length == 0) await this.complete();
    }
  }

  updateCurrentQuestions(oldKey: string, newKeys: string[]) {
    const index = this.currentQuestions?.indexOf(oldKey);
    const toAdd = newKeys.filter((key) => !this.currentQuestions.includes(key));
    if (index >= 0) {
      this.currentQuestions.splice(index, 1, ...toAdd);
    } else {
      this.currentQuestions.push(...toAdd);
    }
  }

  removeQuestion(key: string) {
    const index = this.currentQuestions?.indexOf(key);
    if (index >= 0) this.currentQuestions.splice(index, 1);
  }

  async complete() {
    this.currentQuestions = [];
    const response = await ApiService.post<any, LegalCheckup>(
      `${this.url}/${this.uuid}/complete`
    );
    this.status = response.data.status;
    this.updateFromJSON(response.data.form_data);
  }

  cleanData(dirtyKeys: string[]) {
    cleanValues(this.values, dirtyKeys);
    Object.keys(this.values).forEach((key) => {
      if (isEmptyHash(this.values[key])) delete this.values[key];
    });
  }

  determineRootKey() {
    return this.getFieldKeys().find((key) => {
      const root = this.fields[key].root;
      return strictTrue(root);
    });
  }

  /**
   * Given the transitions of the a question, the next one
   * is determined by the first transition that either has no conditions
   * or all of it's conditions are fulfilled
   */
  nextFieldKeys(fieldKey: string): string[] | undefined {
    const transitions = this.fields[fieldKey].transitions;
    const nextQuestions = transitions?.filter((transition) => {
      return (
        nully(transition.conditions) ||
        (!this.answered?.includes(transition.fieldKey) &&
          !this.currentQuestions?.includes(transition.fieldKey) &&
          transition.conditions.reduce((result, condition) => {
            return result && this.determineCondition(condition);
          }, true))
      );
    });
    return nextQuestions?.map((question) => question.fieldKey);
  }

  isAnswered(fieldKey: string) {
    const values = Object.values(this.getFieldValues(fieldKey));
    return (
      values.length > 0 &&
      Object.values(values).reduce((result, value) => {
        return result && !nully(value);
      }, true)
    );
  }

  getFieldValues(key: string) {
    return { key: this.values[key] };
  }

  trackField(parent: string, nodeKeys: string[]) {
    nodeKeys?.forEach((nodeKey) => {
      if (this.isAnswered(nodeKey)) {
        this.answered.push(nodeKey);
        this.trackField(nodeKey, this.nextFieldKeys(nodeKey));
      } else {
        this.updateCurrentQuestions(parent, [nodeKey]);
      }
    });
  }

  loadAnswers() {
    this.answered = [];
    const root = this.determineRootKey();
    if (this.isAnswered(root)) {
      this.trackField(root, [root]);
    } else {
      this.currentQuestions = [root];
    }
  }

  getShareholdersCount() {
    return 0;
  }
}
