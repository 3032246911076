import {
  bankruptcyFields,
  invoiceDetails,
  RESOURCES,
  PaymentType,
  NotaryProductResource
} from '@ligo/bv-flow/store';
import { ref } from '@vue/composition-api';
import { ProductForm, NotaryProduct, productLocale } from '@ligo/bv-flow/store';
import { ApiService, RootContext } from '@ligo/shared/utils';
import Vue from 'vue';
import { goBackAndSync, notaryProductPath } from './tools/handler-tools';

export const postBillingInformation = async (
  model: NotaryProductResource<PaymentType>
) => {
  const fields = model.fieldData();
  const request = RESOURCES.BILLING;
  await ApiService.patch(request, fields);
};

export const useData = (
  notary_product: NotaryProduct,
  root: RootContext,
  uid: string,
  slug: string
) => {
  const formRef = ref(
    new ProductForm('payment', invoiceDetails, notary_product, {
      sendStep: false
    })
  );
  const paymentModel = notary_product.resources['payment'];
  const form = ref(null as any);
  const title = {
    dark: productLocale(notary_product, 'billing_info.title')
  };
  const subtitle = productLocale(notary_product, 'billing_info.subtitle');
  const bankruptcy_opts = [
    { value: true, label: Vue['i18n'].t('yes') },
    { value: false, label: Vue['i18n'].t('no') }
  ];
  const settled_opts = [
    { value: true, label: Vue['i18n'].t('yes') },
    { value: false, label: Vue['i18n'].t('no') }
  ];
  const updateVals = (root: any) => {
    ApiService.get<PaymentType>(RESOURCES.BILLING)
      .then((response) => {
        if (response.data) {
          const keys = Object.keys(paymentModel.fields) as Array<
            keyof PaymentType
          >;
          keys.forEach((key) => {
            formRef.value.fields[key.toString()].value = response.data[key];
          });
          root.$nextTick(() => {
            form.value.resetValidation();
          });
        }
      })
      .catch((error) => {
        console.log('Billing info problem', error);
      });
  };

  const bankRef = ref(
    new ProductForm('main', bankruptcyFields, notary_product, {
      sendStep: false
    })
  );
  const go_back = () => {
    goBackAndSync(uid, root, notary_product);
    root.$router.push(notaryProductPath(slug, uid));
  };
  return {
    formRef,
    title,
    subtitle,
    bankRef,
    updateVals,
    form,
    bankruptcy_opts,
    settled_opts,
    go_back
  };
};
