export default {
  name: 'ESSENTIALS',
  title:
    'We advise 90% of our clients to incorporate a personal holding together with the BV',
  description:
    'Almost every entrepreneur has one: a personal holding to save money. You set up the holding and work-B.V. right from the start. What if you do this later? Then the costs for the required transfer of shares can be high. With a personal holding you enjoy the following benefits:',
  holding_for: 'Holding for ',
  save: 'SAVE',
  checklist1: 'Save money due to many tax benefits',
  checklist2: 'Protect valuable assets from liabilities or bankruptcy',
  checklist3: 'Provide yourself with a mortgage loan by means of your holding',
  checklist4:
    'Avoid high costs by setting up your holding company now and not later',
  saves_tooltip: 'Start now and get',
  saves_tooltip_highlighted: '€30 discount in your personal holding structure!'
};
