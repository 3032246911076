















import { defineComponent, ref, PropType } from '@vue/composition-api';
import { Document } from '@ligo/bv-flow/store';
const HEIGHT = 60;
export default defineComponent({
  name: 'DocumentViewer',
  components: {},
  props: {
    document: {
      type: Object as PropType<Document>,
      required: true
    }
  },
  setup() {
    const show = ref(true);
    return { show, HEIGHT };
  }
});
