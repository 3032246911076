

































































































































import { computed, defineComponent, PropType } from '@vue/composition-api';
import { SerializeMembership } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'BaseMembershipCard',
  components: {},
  props: {
    membership: {
      type: Object as PropType<SerializeMembership>,
      required: true
    },
    mostPopular: {
      type: Boolean,
      default: false
    },
    loading: {
      type: Boolean
    },
    locale: {
      type: String
    },
    simple: {
      type: Boolean,
      default: false
    }
  },
  setup(props, { emit, root }) {
    const data = computed(() => {
      const lang = root.$i18n.locale == 'nl' ? 'nl' : 'en';
      return props.membership.details[lang];
    });
    const discount = computed(() => {
      return Math.floor(
        100 -
          (props.membership.totalPrice * 100) /
            data.value.package_strikethrough_price
      );
    });
    const line_items = [
      ...(data.value.features ?? []),
      ...(data.value.contracts ?? []),
      ...(data.value.support ?? [])
    ];
    return { props, emit, data, discount, line_items };
  }
});
