import {
  Membership,
  NotaryProduct,
  ProductForm,
  RESOURCES,
  UpsellPackageTypeProduct
} from '@ligo/bv-flow/store';
import { ApiService, Dictionary, accessWithDot } from '@ligo/shared/utils';
import { ref } from '@vue/composition-api';
import { IVueI18n } from 'vue-i18n';
import {
  analyticGTMPreviewPartnerOffer,
  analyticGTMSelectPartnerOffer
} from '../../../../hooks/useBvFlowGTM.hooks';

interface ExtraServicePackage extends UpsellPackageTypeProduct {
  information: {
    en: Dictionary;
    nl: Dictionary;
  };
}

export const EXPECTED_PRODUCT_TYPE = 'ExtraServiceType';
export const EXPECTED_PRODUCT_CATEGORY = 'lead';

export const handleMembership = (notaryProduct: NotaryProduct) => {
  const loadMembership = () => {
    return ApiService.get<Array<Membership>>(
      RESOURCES.MEMBERSHIPS + notaryProduct.slug
    ).then((r) => {
      return r.data && r.data[0];
    });
  };

  return {
    loadMembership
  };
};

export const useExtraService = (
  notaryProduct: NotaryProduct,
  i18n: IVueI18n,
  emit: (event: string) => void
) => {
  const loading = ref(true);
  const extraServices = ref<ExtraServicePackage[]>([]);

  const selectedPackages = ref<number[]>(
    notaryProduct.resources.main?.values?.upsell_package_type_product_ids || []
  );
  const { loadMembership } = handleMembership(notaryProduct);
  loadMembership()
    .then((packages) => {
      extraServices.value =
        (
          packages &&
          packages.upsell_package_type_products.filter(
            (item) =>
              item.product_type_type === EXPECTED_PRODUCT_TYPE &&
              item.product_type.category === EXPECTED_PRODUCT_CATEGORY
          )
        ).map((x) => {
          const information = {
            en: JSON.parse(x.product_type.en) || {},
            nl: JSON.parse(x.product_type.nl) || {}
          };

          return {
            ...x,
            information
          };
        }) || [];
      analyticGTMPreviewPartnerOffer(extraServices.value);
    })
    .finally(() => {
      loading.value = false;
    });

  const getLocale = () => (i18n.locale == 'en-us' ? 'en' : 'nl');

  function t(data: Dictionary, key: string) {
    return accessWithDot(data[getLocale()], key);
  }

  async function onSave() {
    loading.value = true;

    const form = new ProductForm(
      'main',
      [['upsell_package_type_product_ids']],
      notaryProduct
    );

    form.fields.upsell_package_type_product_ids.value = selectedPackages.value;

    await form.save();

    loading.value = false;

    emit('endedStep');
  }

  function onSelectPackage(packageId: number) {
    const idx = selectedPackages.value.indexOf(packageId);
    if (idx == -1) {
      selectedPackages.value.push(packageId);
    } else selectedPackages.value.splice(idx, 1);

    analyticGTMSelectPartnerOffer(selectedPackages.value, extraServices.value);
  }

  return {
    loading,
    extraServices,
    selectedPackages,
    onSelectPackage,
    onSave,
    t
  };
};
