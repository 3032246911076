export default {
  name: 'Gegevens stichting',
  title: 'Vul de algemene gegevens in van de stichting',
  highlight: '',
  description:
    'Controleer of je de gegevens compleet en juist hebt ingevuld. Zo kunnen wij jou zo snel en goed mogelijk van dienst zijn.',
  opt1: 'Dienst geselecteerd',
  opt2: 'Algemene gegevens',
  opt3: 'Doel stichting',
  opt4: 'Bestuur',
  opt5: 'Overige vragen'
};
