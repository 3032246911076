















import { defineComponent } from '@vue/composition-api';
export default defineComponent({
  name: 'NextButton',
  components: {},
  props: {
    loading: {
      type: Boolean
    },
    text: {
      type: String,
      default: 'next'
    },
    useI18n: {
      type: Boolean,
      default: true
    },
    disable: {
      type: Boolean
    },
    classes: {
      type: String,
      default: 'font-button border-radius-4 text-white q-py-xs q-px-lg'
    }
  }
});
