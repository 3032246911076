




















import { defineComponent, PropType } from '@vue/composition-api';
import BaseMembershipCard from '../../../components/base/BaseMembershipCard.vue';
import { SerializeMembership } from '@ligo/bv-flow/store';
export default defineComponent({
  name: 'Memberships',
  components: { BaseMembershipCard },
  props: {
    memberships: {
      type: (Array as unknown) as PropType<Array<SerializeMembership>>,
      default: () => []
    },
    loading: {
      type: Boolean
    },
    locale: {
      type: String
    },
    notaryProductLocale: {
      type: String
    },
    simple: {
      type: Boolean,
      default: false
    },
    mostPopular: {
      type: Number,
      default: 2
    }
  }
});
