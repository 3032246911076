import { NotaryProductSlug } from '@ligo/bv-flow/store';
import { CUSTOM_STEPS_FOR_SERVICE_DETAILS } from './analytic.service';
import { Analytic, NotarialEvent, NotarialStep } from './index';
import Vue from 'vue';

export function analyticSelectCheckout(product: string, token: any) {
  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_CHECKOUT,
    NotarialStep.SELECT_CHECKOUT,
    token
  );
}

export function analyticSelectNotary(product: string, token: any, notary: any) {
  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_NOTARY,
    NotarialStep.SELECT_NOTARY,
    token,
    {
      notaryName: notary ? notary.firstname : '',
      notaryCity: notary ? notary.city : ''
    }
  );
}

export function analyticSelectName(product: string, token: any) {
  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_NAME,
    NotarialStep.SELECT_NAME,
    token
  );
}

export function analyticSelectDga(
  product: string,
  token: any,
  shareholders: any[],
  dgaSalary: number
) {
  const checkedDgaShareholders = shareholders.filter(
    (sh) => sh.form.fields.dga_plan.value
  );
  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_DGA,
    NotarialStep.SELECT_DGA,
    token,
    {
      totalPrice: checkedDgaShareholders.length * dgaSalary,
      dgaUpsell: checkedDgaShareholders.length > 0,
      quantity: checkedDgaShareholders.length
    }
  );
}

export function analyticSelectShareholders(
  product: string,
  token: any,
  shareholdersList = []
) {
  const actives = shareholdersList.filter((x) => x.active).length;

  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_SHAREHOLDERS,
    NotarialStep.SELECT_SHAREHOLDERS,
    token,
    { shareholders: actives || 1 }
  );
}

export function analyticSelectServiceDetails(
  product: string,
  token: any,
  step: number,
  slug: NotaryProductSlug
) {
  Analytic.registerEvent(
    product,
    CUSTOM_STEPS_FOR_SERVICE_DETAILS[slug][step],
    NotarialStep.SELECT_SERVICE_DETAILS,
    token
  );
}

export function analyticGenericSection(
  product: string,
  token: any,
  locale: string,
  step = -1, // default -1 its not notarial_service_select
  slug = null // default null its not notarial_service_select
) {
  if (locale == 'shareholder_information') {
    analyticSelectShareholders(product, token);
  } else analyticSelectServiceDetails(product, token, step, slug);
}

export function analyticSelectHolding(
  product: string,
  token: any,
  shareholders: any[],
  holdingCost: number
) {
  const amountHoldingShareholders = shareholders.filter(
    (sh) => sh.form.fields.holding.value
  ).length;

  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_HOLDING,
    NotarialStep.SELECT_HOLDING,
    token,
    {
      totalPrice: amountHoldingShareholders * holdingCost,
      holdingUpsell: amountHoldingShareholders > 0,
      quantity: amountHoldingShareholders
    }
  );
}

export function analyticSelectValueOfShares(product: string, token: any) {
  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_VALUE_SHARES,
    NotarialStep.SELECT_VALUE_SHARES,
    token
  );
}

export function analyticSelectDeed(product: string, token: any) {
  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_DEED,
    NotarialStep.SELECT_DEED,
    token
  );
}

export function analyticSelectService(product: string, token: any) {
  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_SERVICE,
    NotarialStep.SELECT_SERVICE,
    token
  );
}

export function analyticMembershipUpsell(product: string, selected: boolean) {
  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_MEMBERSHIP_UPSELL,
    NotarialStep.SELECT_MEMBERSHIP,
    Vue['Store'].state.authentication.accessToken,
    {
      contractUpsell: selected
    }
  );
}

export function analyticSelectPackage(
  product: string,
  token: any,
  memberships: any[],
  selectedId: number
) {
  const selectedMembership = memberships.find((memb) => memb.id == selectedId);

  Analytic.registerEvent(
    product,
    NotarialEvent.SELECT_PACKAGE,
    NotarialStep.SELECT_PACKAGE,
    token,
    {
      tier: selectedMembership?.name,
      totalPrice: selectedMembership?.totalPrice
    }
  );

  (window as any).analytics.track('add_to_cart', {
    ecommerce: {
      currencyCode: 'EUR',
      add: {
        products: [
          {
            id: `NP-${(selectedMembership?.id as number).toString()}`,
            name: selectedMembership?.name,
            brand: 'Ligo',
            category: 'Notarial',
            quantity: 1,
            price: selectedMembership?.totalPrice
          }
        ]
      }
    }
  });
}
