import { analyticMembershipUpsell } from '../../../../analytics';
import {
  Benefit,
  ContractsDialog,
  ContractsLibraryDialog,
  PackageTypesInfo,
  ProductForm,
  UpsellPackageTypeProduct,
  VideoDialog,
  UpsellDialogType,
  Membership,
  RESOURCES,
  SerializeMembership
} from '@ligo/bv-flow/store';

import { computed, ref } from '@vue/composition-api';
import { Dictionary } from 'vue-router/types/router';
import { ContractUpsellPageInformation } from '@ligo/bv-flow/hooks';
import { analyticGTMPreviewMembershipUpsell } from '../../../../hooks/useBvFlowGTM.hooks';
import { APIResultWrapper, ApiService, nully } from '@ligo/shared/utils';

const SELECTED_BY_DEFAULT = 'starter_yearly';
const EXPECTED_PRODUCT_TYPE = 'MembershipType';
const BENEFITS_KEY = 'benefits';
const PACKAGE_TYPE_INFO_KEY = 'package_type_products';
const VIDEO_DIALOG_KEY = 'video_dialog';
const CONTRACT_DIALOG_KEY = 'contracts_dialog';
const CONTRACT_LIBRARY_DIALOG_KEY = 'contracts_library_dialog';

const serializeMembership = (result: Membership): SerializeMembership => ({
  id: result.id,
  name: result.name,
  originalPrice: result.strikethrough_price,
  currentPrice: result.notary_product_price,
  totalPrice: result.price,
  details: result.translation_json,
  upsell_package_type_products: result.upsell_package_type_products
});

export const useUpsellPackageData = () => {
  const loading = ref(true);

  const dialogs = ref<
    {
      [key in UpsellDialogType]: boolean;
    }
  >({
    contract: false,
    library: false,
    video: false
  });

  const { load, t, get } = ContractUpsellPageInformation;

  load().then(() => {
    loading.value = false;
  });

  const benefits = computed(() => get<Benefit[]>(BENEFITS_KEY, []));

  const packageTypesInfo = computed(() =>
    get<Dictionary<PackageTypesInfo>>(PACKAGE_TYPE_INFO_KEY, {})
  );

  const videoDialogData = computed(() =>
    get<VideoDialog>(VIDEO_DIALOG_KEY, {} as VideoDialog)
  );

  const contractsDialogData = computed(() =>
    get<ContractsDialog>(CONTRACT_DIALOG_KEY, {} as ContractsDialog)
  );

  const contractsLibraryDialog = computed(() =>
    get<ContractsLibraryDialog>(
      CONTRACT_LIBRARY_DIALOG_KEY,
      {} as ContractsLibraryDialog
    )
  );

  function getImage(index) {
    return `/questionnaire/images/upsell/${index}.svg`;
  }

  function getIcon(index) {
    return `/questionnaire/images/upsell/${index}_check.svg`;
  }

  function onAction(key: string) {
    dialogs.value[key] = true;
  }

  return {
    loading,
    dialogs,
    benefits,
    packageTypesInfo,
    videoDialogData,
    contractsDialogData,
    contractsLibraryDialog,
    getIcon,
    t,
    getImage,
    onAction
  };
};

const NonePackage: UpsellPackageTypeProduct = {
  id: 0,
  package_price: 0,
  product_type: {
    id: 0,
    slug: 'none',
    category: '',
    en: '',
    image: '',
    nl: ''
  },
  product_type_id: 0,
  product_type_type: EXPECTED_PRODUCT_TYPE
};

export function useGetUpsellPackageList(
  slug: string,
  initialValue: number[],
  sendAnalitics = true,
  setDefault = true
) {
  const selectedPackagePlan = ref();

  const extraServices = [];

  const membershipData = APIResultWrapper(
    ApiService.get<Array<Membership>>(RESOURCES.MEMBERSHIPS + slug).then(
      (r) => {
        const userPackage = r.data && r.data[0];
        const packagePlans =
          (userPackage &&
            userPackage.upsell_package_type_products
              .filter(
                (item) => item.product_type_type === EXPECTED_PRODUCT_TYPE
              )
              .concat([NonePackage])) ||
          [];

        const packagePlanIds = packagePlans.map((upsell) => upsell.id);
        if (initialValue.length == 0) {
          packagePlans.forEach((value) => {
            if (
              setDefault &&
              value.product_type.slug === SELECTED_BY_DEFAULT &&
              nully(selectedPackagePlan.value)
            ) {
              selectedPackagePlan.value = value.id;
            }
          });
        } else {
          const [upsell, ...extra] = initialValue;
          if (packagePlanIds.includes(upsell)) {
            selectedPackagePlan.value = upsell;
            if (extra.length > 0) {
              extraServices.push(...extra);
            }
          }
        }

        if (sendAnalitics) analyticGTMPreviewMembershipUpsell(packagePlans);
        return r;
      }
    ),
    serializeMembership
  );

  const userPackage = computed(
    () => membershipData.value && membershipData.value[0]
  );

  const packagePlans = computed(
    () =>
      (userPackage.value &&
        userPackage.value.upsell_package_type_products
          .filter((item) => item.product_type_type === EXPECTED_PRODUCT_TYPE)
          .concat([NonePackage])) ||
      []
  );

  return {
    selectedPackagePlan,
    userPackage,
    packagePlans,
    extraServices
  };
}

export function useUpsellPackageSave(loading, notary_product, emit) {
  const initialValue =
    (notary_product.resources.main?.values?.upsell_package_type_product_ids
      ?.length &&
      notary_product.resources.main.values.upsell_package_type_product_ids) ||
    [];

  const {
    packagePlans,
    selectedPackagePlan,
    userPackage,
    extraServices
  } = useGetUpsellPackageList(notary_product.slug, initialValue);

  async function onSave() {
    loading.value = true;

    const form = new ProductForm(
      'main',
      [['upsell_package_type_id', 'upsell_package_type_product_ids']],
      notary_product
    );

    if (userPackage.value) {
      form.fields.upsell_package_type_id.value = userPackage.value.id;
      form.fields.upsell_package_type_product_ids.value = selectedPackagePlan.value
        ? [selectedPackagePlan.value, ...extraServices]
        : [...extraServices];

      analyticMembershipUpsell(
        notary_product.locale,
        !!selectedPackagePlan.value
      );
    }
    await form.save();
    loading.value = false;
    emit('endedStep');
  }

  return { onSave, packagePlans, selectedPackagePlan };
}
