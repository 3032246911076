import { ApiService } from '@ligo/shared/utils';
import { RESOURCES } from '@ligo/bv-flow/store';
import { NotaryProduct } from '@ligo/bv-flow/store';
import { ref } from '@vue/composition-api';
import { analyticGTMEnterSelectDga } from '../../../../hooks/useBvFlowGTM.hooks';

export const getDGASalary = (
  notary_product: NotaryProduct,
  discount: number
) => {
  const dgaSalary = ref(150);
  const monthlyDGA = ref(12.5);
  const oldMonthlyDGA = ref(17.85);
  const oldDGASalary = ref(214.28);

  ApiService.get(
    RESOURCES.NOTARY_PRODUCT_EXTRA(notary_product.slug, 'dga_salary')
  ).then((response) => {
    dgaSalary.value = parseFloat(response.data['price']);
    monthlyDGA.value = dgaSalary.value / 12;
    oldMonthlyDGA.value =
      Math.floor((monthlyDGA.value / (1 - discount)) * 100) / 100;
    oldDGASalary.value =
      Math.floor((dgaSalary.value / (1 - discount)) * 100) / 100;

    analyticGTMEnterSelectDga(
      notary_product.locale,
      notary_product.temporalStep,
      notary_product.slug,
      dgaSalary.value
    );
  });

  return { oldMonthlyDGA, monthlyDGA, dgaSalary, oldDGASalary };
};
