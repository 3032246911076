import { RootContext, titleize } from '@ligo/shared/utils';
import { computed } from '@vue/composition-api';
import { RESOURCES, StateInterface } from '@ligo/bv-flow/store';
import { Store } from 'vuex';
import { Intercom } from '@ligo/shared/utils';

const DEFAULT_IMAGE = 'img:/questionnaire/images/avatar.png';

export const useAuthTools = (root: RootContext) => {
  const Store = root.$store as Store<StateInterface>;
  const accessToken = computed(() => {
    return Store.state.authentication.accessToken;
  });
  const username = computed(() => {
    if (accessToken) {
      const name = root.$q.screen.gt.sm
        ? `${accessToken.value?.firstname} ${accessToken.value?.lastname}`
        : accessToken.value?.firstname;
      return titleize(name);
    }
    return '';
  });
  const loggedIn = computed(() => {
    return accessToken.value ? true : false;
  });
  const profileImage = computed(() => {
    return DEFAULT_IMAGE;
  });
  const logout = () => {
    void Store.dispatch('authentication/logout');
    Intercom.shutdown();
  };
  const attach_company_name = (path: string) => {
    return path + window.location.search;
  };
  const goToDashboard = async () => {
    window.open(RESOURCES.CUSTOMER_DASHBOARD, '_blank');
  };
  const goTo = (pathName: string) => {
    const redirect_to = root.$router.currentRoute.path;
    const query = redirect_to ? { redirect_url: redirect_to } : {};
    root.$router.push({
      name: pathName,
      query: query
    });
  };

  return {
    username,
    loggedIn,
    profileImage,
    logout,
    attach_company_name,
    goToDashboard,
    goTo
  };
};
