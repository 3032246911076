export default {
  name: 'Shareholders',
  title: 'Information about the shareholders of',
  description:
    'In compliance with Dutch corporate Law, the management board of the B.V. needs to keep basic information about the BV shareholders. This information will be stored in a shareholders register that you can find later on in your dashboard.',
  opt1: 'Basic details',
  opt2: 'Personal details',
  opt3: 'Identification (ID)',
  opt4: 'Other questions',
  shareholder: 'Shareholder',
  complete_shareholder:
    'You should complete all shareholders information before continue',
  cant_incorporate:
    'In this case, it is unfortunately not possible as an shareholder to set up a BV with Ligo.',
  other_services: 'Check other services',
};
