export default {
  criminal_record_label: 'I declare that all shareholder(s) and director(s) have no criminal record, have not been associated with criminal activities in the media,  and have not been involved in a bankruptcy, or have been under administration or guardianship in the past 4 years. ',
  terms_and_conditions_label: 'I accept the terms and conditions of use ',
  criminal_record_alert:
    'You need to check the Criminal Records option before you can continue ',
  terms_and_conditions_alert:
    'In order to proceed, you first need to accept the General Terms and Conditions.',
  order_overview: 'Order Overview',
  total: 'Total',
  subtotal: 'Subtotal',
  satisfied: 'TEVREDEN KLANTEN',
  ratings: 'Beoordelingen',
  our_specialists: 'Onze consultants zijn er voor jou en helpen je graag.',
  questions: 'Heb je vragen?',
  call_us: 'Bel ons op',
  chat: 'Chat direct met een',
  support_agent: 'Jurist',
  secure: 'Payments are secure and encrypted',
  stablishment: 'Oprichting',
  notaryproduct: 'Notaris Product',
  contract: 'Contract',
  membership: 'Lidmaatschap',
  notaryproductextra: 'Essentials',
  discount: 'Korting',
  additional: 'Essentiële diensten',
  name: 'Checkout',
  order_sumary: 'Order summary',
  accept: 'Yes, I would like to receive 3 free quotes',
  reject: "No, I'm not interested"
};
