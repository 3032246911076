export default {
  no_account: 'Je account',
  linkedin: 'Ga verder met LinkedIn',
  google: 'Ga verder met google',
  go_to_dashboard: 'Ga naar je dashboard',
  login: 'Inloggen',
  logout: 'Uitloggen',
  signup: 'Registreren',
  to_signup: 'Heb je geen account? Registreer ',
  here: 'hier',
  reset: {
    start: 'Je wachtwoord vergeten?',
    here: 'hier',
    end: 'om u een e-mail te sturen om het te resetten.',
    title: 'Reset your',
    password: 'password',
    send: 'Ontvang een reset-link',
    nevermind: 'Laat maar!',
    back: 'Breng me terug naar het inlogscherm',
    email: 'Voer het e-mailadres in dat u gebruikt om in te loggen',
    error:
      'Er is een fout opgetreden bij het opnieuw instellen van uw wachtwoord. Controleer of u een Ligo-account heeft'
  },
  check_mail: {
    check: 'Controleer je',
    email: 'email'
  },
  to_signin: 'Heb je al een Ligo account?',
  next: 'Verder',
  mail_error: 'We kunnen deze e-mail niet vinden',
  social_login: 'Of log in via social media:',
  social_register: 'Of registreer via social media:',
  login_header: {
    title: 'Log in om verder te gaan',
    subtitle:
      'Bestaande klanten kunnen hier inloggen. Heb je nog geen account? Hier kun je een account aanmaken.'
  },
  register_header: {
    title: 'Maak een account aan om verder te gaan',
    subtitle:
      'Door een account te maken, wordt alles veilig voor je klaar gezet in je dashboard. Heb je al een account? Hier kun je inloggen.'
  },
  confirmation_header: {
    title: 'Bevestigingsmail opnieuw versturen',
    message_success: 'Bevestigingsmail verzonden',
    message_error: 'We konden geen gebruiker vinden met dit e-mailadres.',
    subtitle:
      'Bevestig je e-mailadres voordat je verder gaat. Dit doe je door op de link the klikken in de email die je hebt ontvangen. Dit is noodzakelijk om de toegang tot je account te behouden'
  },
  errorCodeMsg: {
    different_provider: 'Je e-mail is al bij ons bekend. Je kan inloggen met'
  },
  errorCodeLink: {
    different_provider: {
      google_oauth2: 'Google',
      linkedin: 'LinkedIn',
      no_provider: 'Uw leverancier'
    }
  },
  terms_of_service: {
    prefix: 'Ik ga akkoord met de',
    terms: 'Algemene voorwaarden',
    suffix: 'van Ligo BV'
  },
  keep_posted: 'Houd me op de hoogte van juridische tips en nieuwe features',
  confirmation_email_sent: 'Confirmation Email Sent'
};
