















import { RESOURCES } from '@ligo/bv-flow/store';
import { nully } from '@ligo/shared/utils';
import { defineComponent, ref } from '@vue/composition-api';
import CloseDialog from '../base/BvCloseDialog.vue';

const LOGO_SRC = '/questionnaire/icons/ligo.svg';

export default defineComponent({
  name: 'Logo',
  components: { CloseDialog },
  props: {
    screen: {
      type: Number,
      default: 4
    }
  },
  setup(_, { root }) {
    const onGoHome = () => {
      const logoPath = root.$route.meta.logoPath || RESOURCES.LIGO;
      if (!nully(logoPath.name)) root.$router.push(logoPath);
      else window.location.assign(logoPath);
    };
    const showMsg = ref(false);
    const onShowDialog = () => {
      if (root.$route.name == 'home') {
        window.location.replace(RESOURCES.LIGO);
      } else if (
        ['login', 'register', 'ResetPassword'].includes(root.$route.name) &&
        !root.$route.query['redirect_url']
      ) {
        onGoHome();
      } else if (root.$route.name != 'home') {
        showMsg.value = true;
      }
    };
    return { onGoHome, showMsg, onShowDialog, LOGO_SRC };
  }
});
