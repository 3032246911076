





















































































































import { computed, defineComponent, PropType, ref } from '@vue/composition-api';
import { ContractsLibraryDialog } from '@ligo/bv-flow/store';
import { Screen } from 'quasar';
const DOCUMENT_ICON = '/questionnaire/images/upsell/document.png';
const IMAGE_WIDTH = 28;
export default defineComponent({
  name: 'UpsellContractsLibraryDialog',
  props: {
    value: {
      type: Boolean,
      required: true
    },
    data: {
      type: Object as PropType<ContractsLibraryDialog>,
      required: true
    }
  },
  setup(props) {
    const cardWidth = computed(() => {
      if (Screen.gt.sm) return '80vw';
      if (Screen.sm) return '90vw';
      return '100vw';
    });

    const cardHeight = computed(() => {
      if (Screen.gt.sm) return '65vh';
      if (Screen.sm) return '90vh';
      return '100vh';
    });

    const selectedCategory = ref('all');

    const filteredContract = computed(() => {
      return props.data.contracts.filter(
        (contract) =>
          selectedCategory.value.toLowerCase() === 'all' ||
          selectedCategory.value.toLowerCase() ===
            contract.category.toLowerCase()
      );
    });

    const categoriesCount = props.data.contracts.reduce((acc, current) => {
      const nameLower = current.category.toLowerCase();
      return {
        ...acc,
        [nameLower]: acc[nameLower] !== undefined ? acc[nameLower] + 1 : 1
      };
    }, {});

    const filteredCategories = computed(() => {
      return Object.keys(props.data.categories)
        .filter((currentKey) => {
          return categoriesCount[currentKey] > 0;
        })
        .reduce((prev, currentKey) => {
          return {
            ...prev,
            [currentKey.toLowerCase()]: props.data.categories[currentKey]
          };
        }, {});
    });

    return {
      cardWidth,
      DOCUMENT_ICON,
      IMAGE_WIDTH,
      filteredContract,
      selectedCategory,
      cardHeight,
      filteredCategories
    };
  }
});
