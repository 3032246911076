import { BasicForm } from '@ligo/shared/mvc';
import { NotaryProduct, ProductForm } from '@ligo/bv-flow/store';
import { computed, ref, Ref } from '@vue/composition-api';
import { productLocale } from '@ligo/shared/mvc';

export const setSubstep = (
  notary_product: NotaryProduct,
  resource: string,
  increment: number
) => {
  notary_product.resources[resource].substep = increment;
  notary_product.substep = increment;
};

export const useData = (
  notary_product: NotaryProduct,
  sections: string[][][],
  locale: string,
  resource: string
) => {
  const titleLight = computed(() => {
    const field = notary_product.resources.main.fields.company_name;
    const value = notary_product.resources.main.values.company_name;
    return formatTitle(field, value);
  });

  const formatTitle = (field, value) => {
    let result = value;
    if (result) {
      if (field.filter) result = field.filter(result);
      if (field.suffix) result = result + field.suffix;
      return result;
    }
    return productLocale(notary_product, `${locale}.highlight`);
  };

  const title = ref({
    dark: productLocale(notary_product, `${locale}.title`),
    light: titleLight.value,
    useLightText: !!notary_product.resources.main.values.company_name
  });

  const subtitle = productLocale(notary_product, `${locale}.description`);
  const sideOptions = [];
  for (let i = 1; i <= sections.length + 1; i++) {
    sideOptions.push(productLocale(notary_product, `${locale}.opt${i}`));
  }

  const forms: Array<Ref<BasicForm>> = [];
  sections.forEach((section, i) => {
    forms.push(
      ref(
        new ProductForm(resource, section, notary_product, {
          sendStep: i == sections.length - 1
        })
      )
    );
  });
  const formsRef = ref(forms);
  const index = ref(notary_product.resources[resource].substep);
  return { sideOptions, title, subtitle, index, formsRef, formatTitle };
};
