















































import { defineComponent } from '@vue/composition-api';
const INFO_ICON = 'img:/questionnaire/icons/new_info.svg';
const AVATAR_WIDTH = '44px';

export default defineComponent({
  name: 'CloseDialog',
  props: {
    title: {
      type: String,
      default: 'go_home'
    },
    value: {
      type: Boolean
    },
    labelCancel: {
      type: String,
      default: 'no'
    },
    hideCancel: {
      type: Boolean,
      default: false
    },
    labelConfirm: {
      type: String,
      default: 'yes'
    }
  },
  setup(props, { emit }) {
    const exit = () => {
      emit('confirm');
      emit('input', false);
    };
    return { exit, INFO_ICON, AVATAR_WIDTH };
  }
});
