







































































import { defineComponent, PropType } from '@vue/composition-api';

import {
  TokenInterface,
  cleanProductType,
  saveProductType
} from '@ligo/bv-flow/services';
import { Intercom } from '@ligo/shared/utils';
import { analyticSelectService, Analytic } from '../analytics';
import { BvServiceCard, BvServiceLocale } from '@ligo/bv-flow/store';
import NewContactCard from '../components/base/NewContactCard.vue';
const PHONE_NUMBER = '020-3031043';
export default defineComponent({
  name: 'BvIndex',
  components: { NewContactCard },
  props: {
    access_token: {
      type: String
    },
    client: {
      type: String
    },
    expiry: {
      type: String
    },
    uid: {
      type: String
    },
    new_product: {
      type: String
    },
    servicesCard: {
      type: Array as PropType<Array<BvServiceCard>>
    },
    redirect_url: {
      type: String
    }
  },
  setup(props, { root, emit }) {
    Analytic.registerPage('BvHome');
    cleanProductType();
    emit('clear');
    if (props.access_token && props.client && props.expiry && props.uid) {
      const token: TokenInterface = {
        access_token: props.access_token,
        client: props.client,
        expiry: props.expiry,
        uid: props.uid,
        firstname: '',
        lastname: '',
        userPhone: '',
        userId: ''
      };
      root.$store
        .dispatch('authentication/auth_redirection', token)
        .then((fullToken: TokenInterface) => {
          Analytic.registerUser(fullToken);
          Intercom.identifyUser(fullToken);
          if (props.redirect_url) {
            root.$router.push(props.redirect_url);
          }
        });
    }

    function goToRoute(internalLink: boolean, link: string) {
      if (internalLink) {
        root.$router.push({ name: link });
      } else {
        window.location.assign(link);
      }
    }

    const onClick = (service: BvServiceLocale) => {
      goToRoute(true, service.link);
      saveProductType(service.type);
      analyticSelectService(
        service.type,
        root.$store.state.authentication.accessToken
      );
    };
    return { onClick, PHONE_NUMBER };
  }
});
