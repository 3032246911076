






























import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BvFooter',
  props: {
    contentClass: {
      type: String,
      default: 'row justify-between items-center max-display'
    }
  }
});
