








































import { defineComponent, ref, watch } from '@vue/composition-api';

import Toolbar from '../components/layout/Toolbar.vue';
import { nully, responsiveCheck } from '@ligo/shared/utils';
import DrawerToolbar from '../components/layout/DrawerToolbar.vue';

import { useLocaleTools } from '../components/layout/locale-hooks';
import BvFooter from '../components/layout/BvFooter.vue';
import StepsProgressBar from '../components/StepsProgressBar.vue';
import { NotaryProduct } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'ClientLayout',
  components: {
    Toolbar,
    DrawerToolbar,
    BvFooter,
    StepsProgressBar
  },
  setup(_, { root }) {
    const dialog = ref(false);
    const drawerToolbar = ref(false);

    const { screen } = responsiveCheck();
    const progressBarStep = ref(
      !nully(root.$route.meta.progressBarStep)
        ? root.$route.meta.progressBarStep
        : 2
    );

    watch(
      () => root.$route.meta.progressBarStep,
      (newVal) => {
        progressBarStep.value = !nully(newVal) ? newVal : 2;
      }
    );
    const { i18nSwitch, changeLanguage, enableSwitch } = useLocaleTools(root);

    const notaryProduct = ref<NotaryProduct>(null);
    const reload = ref<() => void>(null);

    function onNotaryProductLoad(
      notaryProductObj: NotaryProduct,
      reloadFunc: () => void
    ) {
      notaryProduct.value = notaryProductObj;
      reload.value = reloadFunc;
    }

    function goToStep(step: number) {
      notaryProduct.value.goToStep(step);
      reload.value();
    }
    return {
      dialog,
      screen,
      drawerToolbar,
      notaryProduct,
      i18nSwitch,
      enableSwitch,
      progressBarStep,
      changeLanguage,
      onNotaryProductLoad,
      goToStep
    };
  }
});
