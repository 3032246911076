export default {
  title: 'Billing information',
  subtitle: 'Fill in the billing information below',
  bankruptcy_question:
    'Has one of the directors or shareholders for the past 4 years been directly or indirectly involved in a bankruptcy?',
  bankruptcy_settled: 'Has this banksruptcy been settled?',
  remove_shareholder:
    'This shareholder/founder/director cannot be part of the incorporation',
  go_back_to_shareholders: 'Go back to the shareholders'
};
