






















import { defineComponent } from '@vue/composition-api';

export default defineComponent({
  name: 'BvAlertBanner',
  props: {
    text: {
      type: String
    },
    iconSrc: {
      type: String,
      default: 'warning'
    },
    iconSize: {
      type: Number,
      default: 20
    },
    iconSep: {
      type: Number,
      default: 12
    },
    iconColor: {
      type: String,
      default: 'ligo-orange'
    },
    cardClass: {
      type: String,
      default: 'bg-surface-warning border-border-green q-mb-md'
    },
    contentClass: {
      type: String,
      default: 'text-subdued font-body q-mb-none'
    },
    useI18n: {
      type: Boolean,
      default: true
    }
  }
});
