




































import { defineComponent, PropType } from '@vue/composition-api';
import CalculatorGenericField from './CalculatorGenericField.vue';
import { CalculatorField, getI18n, getI18nUnreact } from '@ligo/bv-flow/store';

export default defineComponent({
  name: 'CalculatorFieldWrapper',
  components: { CalculatorGenericField },
  props: {
    fields: {
      type: Array as PropType<CalculatorField[]>,
      required: true
    },
    noLabel: {
      type: Boolean,
      default: false
    },
    disable: Boolean,
    shareholdersCount: Number
  },
  setup(props, { root, emit }) {
    function emitValue(key, value) {
      emit('on-answer', key, value);
    }
    function getShareHolderKeys() {
      return Array.from(
        { length: props.shareholdersCount },
        (_, i) => `p${i + 1}`
      );
    }

    function getShareholderLabel(field) {
      const shareholderPrefix = field.key.split('.')[0];
      const shareholderLabel = getI18nUnreact(field, 'shareholder_label');
      const label = !!shareholderLabel ? shareholderLabel : '';
      const tag = `shareholder_tags.${shareholderPrefix}`;
      return `${label} ${root.$calct(tag)}`;
    }

    return {
      emitValue,
      getShareHolderKeys,
      getShareholderLabel
    };
  }
});
