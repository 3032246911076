export default {
  title: 'Your concept deed will wait for you in your dashboard!',
  note: 'Note :',
  note_text: ' This is just a representation of the deed',
  list: 'List of Documents',
  d1: 'Draft Deed of Incorporation',
  d2: 'Explanatory notes to the Deed of Incorporation',
  d3: "Shareholders' register",
  d4: 'Personalized Datacard for the Chamber of Commerce',
  btn_download: 'Download',
  btn_subtitle: 'You can download your documents after payment',
  next_step_title: 'Next Step',
  next_step_description:
    'After completing this questionnaire, you will be asked to upload the required documents. Please make sure that you upload all the documents. Only then the notary will contact you in order to execute the deed.',
  previous: 'Previous',
  next: 'Next step',
  name: 'Preview documents'
};
