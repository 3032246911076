










import { defineComponent, PropType } from '@vue/composition-api';
import { PaymentQuery } from '@ligo/shared/models';
import { RESOURCES } from '@ligo/bv-flow/store';
import { Notify } from 'quasar';

export default defineComponent({
  name: 'Payment',
  components: {},
  props: {
    uuid: {
      type: String,
      required: true
    },
    query: Object as PropType<PaymentQuery>
  },
  setup(_, { root }) {
    function onPaymentSuccess(redirectUrl) {
      window.location = redirectUrl;
    }
    function onPaymentError(error) {
      console.error(error);
      Notify.create({
        icon: 'warning',
        type: 'negative',
        message: `${root.$t('payment_error')}: ${error}`
      });
    }
    return { RESOURCES, onPaymentSuccess, onPaymentError };
  }
});
