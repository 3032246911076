export const customers = [
  {
    src: '/questionnaire/images/customer.svg',
    quote:
      'Bij Ligo regel je alles snel en makkelijk, zodat jij je kunt bezig houden met de dingen die je het liefst doet: ondernemen.',
    name: 'Paul Eggink',
    post: 'Founder Temper'
  },
  {
    src: '/questionnaire/images/customer.svg',
    quote:
      'Bij Ligo regel je alles snel en makkelijk, zodat jij je kunt bezig houden met de dingen die je het liefst doet: ondernemen.',
    name: 'Example 2',
    post: 'Founder Temper'
  }
];
